import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
import * as zxcvbn from "./zxcvbn.js"
class PayorCreate extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      loading: false,
      status: 0,
      name: "",
      name_status: 0,
      name_error: "",
      email: "",
      email_status: 0,
      email_error: "",
      messages: [],
      confirmation: "" ,
      confirmation_status: 0,
      confirmation_error: "",
      received_code: "",
      token: "",
      password: "",
      password_status: 0,
      password_message: "",
      password_confirmation: "",
      password_confirmation_status: 0,
      password_confirmation_message: "",
      password_score: 0,
      password_evaluation: null
		};

    this.on_type_name = this.on_type_name.bind(this);
    this.on_blur_name = this.on_blur_name.bind(this);
    this.on_type_email = this.on_type_email.bind(this);
    this.on_blur_email = this.on_blur_email.bind(this);
    this.create_account = this.create_account.bind(this);
    this.on_type_confirmation = this.on_type_confirmation.bind(this);
    this.on_blur_confirmation = this.on_blur_confirmation.bind(this);
    this.confirm = this.confirm.bind(this);
    this.on_key_down_general = this.on_key_down_general.bind(this);
    this.on_type_password = this.on_type_password.bind(this);
    this.on_blur_password = this.on_blur_password.bind(this);
    this.on_blur_password_confirmation = this.on_blur_password_confirmation.bind(this);
    this.set_password = this.set_password.bind(this);

    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    this.MIN_NAME_LENGTH = 6;
    this.MAX_PASSWORD_LENGTH = 128;
    this.CONFIRMATION_LENGTH = 6;

  }

  on_type_name(e){
    this.setState({
      name: e.target.value,
      name_status: 1
    });
  }

  on_blur_name(){
    if (this.state.name.trim().length < this.MIN_NAME_LENGTH){
      this.setState({
        name_status: 3,
        name_error: "Trimmed name must be at least "+this.MIN_NAME_LENGTH+" characters"
      });
      return false;
    } else if (this.state.name.length === 0){
      this.setState({
        name_status: 0,
        name_error: ""
      });
      return false;
    } else {
      this.setState({
        name_status: 2,
        name_error: ""
      });
      return true;
    }
  }

  on_type_email(e){
    if (!e.target.value.includes(' ')){
      this.setState({
        email: e.target.value.toLowerCase(),
        email_status: 1
      });
      //this.on_blur_email();
    }
  }

  on_blur_email(execute){
    if (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.email)){
      fetch("/api/checkpayoremail?email="+this.state.email, {
        method: 'GET',
        credentials: 'include'
      }).then( (response) => {
        if (response.ok){
          return response.json();
        }
        throw new Error('Request fail');
      }).then(json => {
        if (json.available){
          this.setState({
            email_status: 2
          });
          if (execute === "execute"){
            this.create_account();
          }
        } else {
          this.setState({
            email_status: 3,
            email_error: "That email is not available to use"
          });
        }
      });
    } else {
      this.setState({
        email_status: 3,
        email_error: "Please type a valid email."
      });
    }
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  create_account(){
    /*this.add_message("Could not create account.",'danger');
    return true;*/
    if (this.state.name_status != 2 || (this.state.email_status != 2 && false)){
      return false;
    }
    this.setState({
      loading: true
    });
    fetch("/api/payors/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        payor: {
          name: this.state.name,
          email: this.state.email
        }
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.setState({
          loading: false,
          status: 1,
          received_code: json.code
        });
        this.add_message("A 6-digit confirmation code has been sent.",'info');
      } else {
        this.setState({
          loading: false
        });
        this.add_message("Error: "+json.error,'danger');
      }
    });
  }

  confirm(v){
    this.setState({
      loading: true
    });
    fetch("/api/payors/confirm", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        code: this.state.received_code,
        token: v
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.setState({
          confirmation_status: 2,
          loading: false,
          token: json.token
        });
        this.add_message("Account confirmed.",'success');
        setTimeout(() => {
          this.setState({
            status: 2
          });
        }, 1000);
      } else {
        this.setState({
          confirmation_status: 3,
          loading: false
        });
        this.add_message("Error: "+json.error,'danger');
      }
    });
  }

  on_type_confirmation(e){
    if (/^[0-9]{0,6}$/.test(e.target.value)){
      this.setState({
        confirmation: e.target.value
      });
    }
    if (e.target.value.length === this.CONFIRMATION_LENGTH){
      console.log("6 CHARS"); // /api/payors/confirm
      this.confirm(e.target.value);
    }
  }

  on_blur_confirmation(){
    console.log("Blur confirmation");
  }

  on_key_down_general(e){
    if (e.keyCode === 13){
      if (this.state.status === 0){
        if (this.state.email_status === 2){
          this.create_account();
        } else {
          this.on_blur_email("execute");
        }
      }
    }
  }

  on_type_password(e){
    const typed = e.target.value;
    if (typed.length < this.MAX_PASSWORD_LENGTH){
      const pw_eval = zxcvbn(typed);
      console.log(pw_eval);
      this.setState({
        password: typed,
        password_status: 1,
        password_message: "",
        password_score: typed.length < this.MIN_PASSWORD_LENGTH ? -1 : pw_eval.score,
        password_evaluation: pw_eval
      });
    }
  }

  on_blur_password(e){
    if (this.state.password_score === -1 || this.state.password.length > this.MAX_PASSWORD_LENGTH || this.state.password.length < this.MIN_PASSWORD_LENGTH){
      this.setState({
        password_status: 3,
        password_message: "Password must be between "+String(this.MIN_PASSWORD_LENGTH)+" and "+String(this.MAX_PASSWORD_LENGTH)+" characters."
      });
    } else {
      this.setState({
        password_status: 2,
        password_message: ""
      });
      this.on_blur_password_confirmation(null);
    }
  }

  on_blur_password_confirmation(e){
    if (this.state.password !== this.state.password_confirmation){
      this.setState({
        password_confirmation_status: 3,
        password_confirmation_message: "Confirmation doesn't match new password."
      });
    } else {
      this.setState({
        password_confirmation_status: 2,
        password_confirmation_message: ""
      });
    }
  }

  set_password(){
    if (this.state.password_status != 2 || this.state.password_confirmation_status != 2){
      return false;
    }
    this.setState({
      loading: true
    });
    // /api/payors/passwordchange
    fetch("/api/payors/passwordchange", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        token: this.state.token,
        password: this.state.password
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.setState({
          status: 3,
          loading: false
        });
        this.add_message("Password successfully set.",'success');
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        this.setState({
          loading: false
        });
        this.add_message("Error: "+json.error,'danger');
      }
    });
  }

  render () {
    return (
      <div style={{height: "400px"}}>

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        {[
          <div>
            <div style={{padding: '10px', fontSize: '14px', textAlign: 'center'}}>
              Hi there! You can quickly create a Payor account here.
            </div>
            <div className="myinput-wrapper-centre">
              <MyInput onChange={this.on_type_name} value={this.state.name} onFocus={() => this.setState({name_status: 1})} onBlur={this.on_blur_name} status={this.state.name_status} note={this.state.name_status === 3 ? this.state.name_error : null} onKeyDown={this.on_key_down_general} label="Your name" />
              <br />
              <MyInput onChange={this.on_type_email} value={this.state.email} onFocus={() => this.setState({email_status: 1})} onBlur={this.on_blur_email} status={this.state.email_status} note={this.state.email_status === 3 ? this.state.email_error : null} onKeyDown={this.on_key_down_general} label="Your email" />
            </div>
            <br />
            <div style={{textAlign: 'center'}}>
              <button className="std-button" onClick={this.create_account}>
                {this.state.loading ? "Creating..." : "Create"}
              </button>
            </div>
          </div>,

          <div>
            <div style={{padding: '10px', fontSize: '14px', textAlign: 'center'}}>
              Check your email for a 6-digit confirmation code.
            </div>
            <div className="myinput-wrapper-centre">
              <MyInput onChange={this.on_type_confirmation} value={this.state.confirmation} onFocus={() => this.setState({confirmation_status: 1})} onBlur={this.on_blur_confirmation} status={this.state.confirmation_status} note={this.state.confirmation_status === 3 ? this.state.confirmation_error : null} label="Confirmation code" onKeyDown={this.on_key_down_general} style={{textAlign: 'center', letterSpacing: '4px', fontWeight: 'bold'}} />
            </div>
            {this.state.loading ? 
              <div style={{fontSize: '13px'}}>
                Confirming...
              </div> : null}
          </div>,

          <div>
            <div style={{padding: '10px', fontSize: '14px', textAlign: 'center'}}>
              Welcome aboard, {this.state.name.split(' ')[0]}. Please create a password.
            </div>
            <div className="myinput-wrapper-centre">
              <MyInput onChange={this.on_type_password} value={this.state.password} onFocus={() => this.setState({confirmation_status: 1})} onBlur={this.on_blur_password} status={this.state.password_status} note={this.state.password_status === 3 ? this.state.password_message : null} label="Password" onKeyDown={this.on_key_down_general} type="password" />
            </div>
            <br />
            <div style={{fontSize: '14px', color: 'grey'}}>Password strength: {this.state.password_status > 0 ? <span style={{color: ["red","#e6a330","#e6a330","#5158e0","#32bfb8","#32bfb8"][this.state.password_score + 1], fontWeight: "bold"}}>{["Too short","Very Weak","Weak","Moderate","Strong","Very Strong"][this.state.password_score + 1]}</span> : null}</div>
            <br />
            <div className="myinput-wrapper-centre">
              <MyInput onChange={(e) => this.setState({password_confirmation: e.target.value})} value={this.state.password_confirmation} onFocus={() => this.setState({password_confirmation_status: 1})} onBlur={this.on_blur_password_confirmation} status={this.state.password_confirmation_status} note={this.state.password_confirmation_status === 3 ? this.state.password_confirmation_message : null} label="Password confirmation" onKeyDown={this.on_key_down_general} type="password" />
            </div>
            <br />
            <button className="std-button" onClick={this.set_password}>
              Confirm
            </button>
          </div>,

          <div>
            <div style={{padding: '10px', fontSize: '14px', textAlign: 'center'}}>
              Password successfully set, {this.state.name.split(' ')[0]}.<br /><br />
              {/*[null,undefined].includes(this.props.on_complete) ? 
                <button className="std-button" onClick={() => this.props.on_complete()}>
                  Complete
                </button> : null*/}
            </div>
          </div>

        ][this.state.status]}
      </div>
    );
  }
}

export default PayorCreate
