import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
import Login from "./Login.js"
import * as zxcvbn from "./zxcvbn.js"

class SetPassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      code: "",
      token: "",
      status: 0,
      new_password: "",
      new_password_status: 0,
      new_password_message: "",
      new_password_confirmation: "",
      new_password_confirmation_status: 0,
      new_password_confirmation_message: "",
      new_password_loading: false,
      password_score: -1,
      messages: [],
      loading: false,
      show_login: false
    };

    this.on_type_new_password = this.on_type_new_password.bind(this);
    this.on_blur_new_password = this.on_blur_new_password.bind(this);
    this.on_blur_new_password_confirmation = this.on_blur_new_password_confirmation.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.submit_user = this.submit_user.bind(this);

    this.MAX_PASSWORD_LENGTH = 200;
    this.MIN_PASSWORD_LENGTH = 6;
    this.MAX_PASSWORD_LENGTH = 128;
    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    
  }

  componentDidMount(){
    const params = (new URLSearchParams(window.location.search));
    this.setState({
      code: params.get('code'),
      token: params.get('token'),
      status: Number(this.props.status)
    });
  }

  on_type_new_password(e){
    const typed = e.target.value;
    if (typed.length < this.MAX_PASSWORD_LENGTH){
      const pw_eval = zxcvbn(typed);
      console.log(pw_eval);
      this.setState({
        new_password: typed,
        new_password_status: 1,
        new_password_message: "",
        password_score: typed.length < this.MIN_PASSWORD_LENGTH ? -1 : pw_eval.score,
        password_evaluation: pw_eval
      });
    }
  }

  on_blur_new_password(e){
    if (this.state.password_score === -1 || this.state.new_password.length > this.MAX_PASSWORD_LENGTH || this.state.new_password.length < this.MIN_PASSWORD_LENGTH){
      this.setState({
        new_password_status: 3,
        new_password_message: "Password must be between "+String(this.MIN_PASSWORD_LENGTH)+" and "+String(this.MAX_PASSWORD_LENGTH)+" characters."
      });
    } else {
      this.setState({
        new_password_status: 2,
        new_password_message: ""
      });
      this.on_blur_new_password_confirmation(null);
    }
  }

  on_blur_new_password_confirmation(e){
    if (this.state.new_password !== this.state.new_password_confirmation){
      this.setState({
        new_password_confirmation_status: 3,
        new_password_confirmation_message: "Confirmation doesn't match new password."
      });
    } else {
      this.setState({
        new_password_confirmation_status: 2,
        new_password_confirmation_message: ""
      });
    }
  }

  submit_user(){
    this.setState({
      loading: true
    });
    fetch("/confirm", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({password: this.state.new_password, code: this.state.code, token: this.state.token})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Error in updating User. Status code "+String(response.status_code), 'danger');
      this.setState({
        loading: false
      });
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.add_message("Successfully updated User.",'success');
        this.setState({
          loading: false,
          show_login: true
        });
      } else {
        this.add_message("Error in updating User." + (json.error === undefined ? "" : " Error: " + json.error),'danger');
        this.setState({
          loading: false
        });  
      }
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  render () {
    return (
      <div className="form-section">
        {this.state.messages.map(
            (message, index) =>
            <div className={"flash flash-"+message.type} key={index}>
              <table><tbody>
                <tr>
                  <td style={{fontSize: '18px'}}>
                    <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                  </td>
                  <td>
                    {message.content}
                  </td>
                </tr>
              </tbody></table>
            </div>
          )}
        {this.state.show_login ? <Login auth_token={this.props.auth_token} continue_url={null} /> : 
        <div>
          <h2>
            {this.state.status === 1 ? "Set" : "Reset"} password for this User
          </h2>
          <div style={{display: 'table', margin: 'auto'}}>
            <MyInput onChange={this.on_type_new_password} value={this.state.new_password} onFocus={() => this.setState({new_password_status: 1})} onBlur={this.on_blur_new_password} status={this.state.new_password_status} note={this.state.new_password_status === 3 ? this.state.new_password_message : null} label="New password" type="password" />
            <br />
            <div style={{fontSize: '14px', color: 'grey'}}>Password strength: {this.state.new_password_status > 0 ? <span style={{color: ["red","#e6a330","#e6a330","#5158e0","#32bfb8","#32bfb8"][this.state.password_score + 1], fontWeight: "bold"}}>{["Too short","Very Weak","Weak","Moderate","Strong","Very Strong"][this.state.password_score + 1]}</span> : null}</div>
            <br />
            <MyInput onChange={(e) => this.setState({new_password_confirmation: e.target.value})} onKeyDown={(e) => e.key === 'Enter' ? this.on_blur_new_password_confirmation() : null} value={this.state.new_password_confirmation} onFocus={() => this.setState({new_password_confirmation_status: 1})} onBlur={this.on_blur_new_password_confirmation} status={this.state.new_password_confirmation_status} note={this.state.new_password_confirmation_status === 3 ? this.state.new_password_confirmation_message : null} label="Confirm new password" type="password" />
          </div>
          <br />
          {this.state.new_password_status === 2 && this.state.new_password_confirmation_status === 2 ? 
            <div style={{textAlign: 'center'}}>
              <button className="std-button" onClick={this.submit_user}>
                {this.state.loading ? "Updating..." : "Update"}
              </button>
            </div> : null}
        </div>}
      </div>);
  }
}

export default SetPassword
