import React from "react"
import PropTypes from "prop-types"
class HomePage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    };

    this.IMAGE_ARRAY = [
      'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/Screenshot+2024-11-25+at+5.31.20%E2%80%AFPM.png',
      'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/Screenshot+2024-11-25+at+5.26.50%E2%80%AFPM.png',
      'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/Screenshot+2024-11-25+at+5.26.02%E2%80%AFPM.png'
    ];
    this.USE_CASES = ['utilities','B2B payments','small businesses','hospitals','manufacturers','tradies','delivery drivers'];

  }

  render () {
    return (
      <div style={{color: '#fff', textAlign: 'center'}}>
        <br /><br /><br />
        <h1>
          PaySolve
        </h1>
        <p style={{fontSize: '20px', fontFamily: 'Roboto Mono', width: '700px', margin: 'auto'}}>
          A secure, easy-to-use, and affordable Australian invoice payments system.
        </p><br />
        <table style={{margin: "auto"}}><tbody>
          <tr>
            {this.IMAGE_ARRAY.map(
              (imgsrc, index) => 
                <td key={index} style={{padding: '10px'}}>
                  <div style={{width: '340px', height: '190px', overflow: 'hidden', borderRadius: '6px'}}>
                    <img src={imgsrc} style={{width: '340px'}} />
                  </div>
                </td>
            )}
          </tr>
        </tbody></table><br />
        <div style={{color: '#fff', fontSize: '18px', width: '550px', margin: 'auto'}}>
          See how PaySolve's innovative first-of-its-kind system can solve your payments, saving you money and time.
        </div><br />
        <div onClick={() => location.href = "/company/new"} className="splash-button">
          <i className="fa-solid fa-arrow-right"></i> Show me
        </div>
      </div>
    );
  }
}

export default HomePage
