import React from "react"
import PropTypes from "prop-types"
import MbOverlay from "./MbOverlay.js"
class MbShowSeries extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      series: null,
      loaded: false,
      messages: [],
      show_payor_status: 0,
      payor_code: null,
      series_payorlink_id: null,
      series_payorlink_id: null,
      show_remove_payorlink: false,
      show_add_payorlink: false
		};

    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.click_to_copy = this.click_to_copy.bind(this);
    this.toggle_url_passkey_inclusion = this.toggle_url_passkey_inclusion.bind(this);
    this.display_abn = this.display_abn.bind(this);
    //this.save_series_payor = this.save_series_payor.bind(this);
    this.save_series_payor = this.save_series_payor.bind(this);
    this.remove_payorlink = this.remove_payorlink.bind(this);
    this.retrieve_invoices = this.retrieve_invoices.bind(this);

    this.INVOICE_STATUS_TO_WORD = {'0':'INITIATE','1':'UNPAID','2':'PART PAID','3':'SUSPEND','4':'PAID','5':'PAID','6':'PAID'};
    this.INVOICE_STATUS_TO_TITLE = {'0':'Invoice Initiated','1':'Invoice Unpaid','2':'Invoice Partly Paid','3':'Invoice Suspended by User','4':'Invoice Paid','5':'Invoice Paid in Excess','6':'Invoice Paid by Other Source'};
    this.INVOICE_STATUS_TO_DESCRIPTION = {'0':'Invoice has been initiated but has not been opened yet to payment.',
      '1':'Invoice is unpaid and is awaiting payment.',
      '2':'Invoice has been partly paid, and is awaiting payment of the balance.',
      '3':'Invoice has been suspended by you and is not visible except to you.',
      '4':'Invoice has been paid.',
      '5':'Invoice has been paid in excess of what was owed.',
      '6':'Invoice has been paid by another source, not through PaySolve.'};
    this.INVOICE_STATUS_TO_COLOUR = {'0':'#040dba','1':'#040dba','2':'#e6a330','3':'#e6a330','4':'#32bfb8','5':'#32bfb8','6':'#32bfb8'};
    this.INVOICE_STATUS_TO_ADMIN_ACTION = {'1':'OPEN','2':'OPEN','3':'SUSPEND','6':'SET_AS_COMPLETE'};

    this.SERIES_STATUS_TO_WORD = {'0':'AWAITING','1':'OPEN','2':'OPEN','3':'SUSPENDED'};
    this.SERIES_STATUS_TO_COLOUR = {'0':'#040dba','1':'#040dba','2':'#040dba','3':'#e6a330'};

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

    this.RETRIEVE_LIMIT = 5;

  }

  componentDidMount(){
    fetch("/api/ser/"+this.props.code+([null,undefined].includes(this.props.passkey) ? "" : "?pk="+this.props.passkey), {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Error in obtaining Invoice data.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      if ([null,undefined].includes(json.series)){
        this.add_message("Encountered error obtaining information.",'danger');
        this.setState({
          loaded: true
        });
      } else {
        console.log("Successfully loaded component");
        console.log(json);
        this.setState({
          series: json.series,
          loaded: true,
          payor_code: json.payor_code,
          series_payorlink_id: json.series_payorlink_id
        });
      }
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  click_to_copy(e, text){
    navigator.clipboard.writeText(text);
    console.log(e);
    e.target.innerHTML = "COPIED!"
    setTimeout(() => {
      e.target.innerHTML = "COPY";
    }, 1000);
  }

  toggle_url_passkey_inclusion(){
    let series = this.state.series;
    if (series.short_url.includes('?pk')){
      series.short_url = series.short_url.split('?pk')[0];
    } else {
      series.short_url += ('?pk='+this.props.passkey);
    }
    this.setState({
      series: series
    });
  }

  display_abn(x){
    return x.split('').map((a,i) => [1,4,7].includes(i) ? a+' ' : a).join('');
  }

  /*save_series_payor(){
    fetch("/payorlink?objname=SER&series_id="+this.state.series.id, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.add_message("Saved Invoice to your Payor account.",'success');
      this.setState({
        series_payorlink_id: json.payorlink_id,
        show_add_payorlink: false
      });
    });
  }*/

  save_series_payor(){
    fetch("/payorlink?objname=SER&series_id="+this.state.series_id, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }/*,
      body: JSON.stringify({})*/
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.add_message("Saved Series to your Payor account.",'success');
      this.setState({
        series_payorlink_id: json.payorlink_id,
        show_add_payorlink: false
      });
    });
  }

  remove_payorlink(object_name, payorlink_id){
    fetch("/payorlink?objname="+object_name+"&payorlink_id="+payorlink_id, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }/*,
      body: JSON.stringify({})*/
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Erro in deleting Payor link.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      this.add_message("Successfully removed link to your Payor account.",'success');
      /*if (object_name === "INV"){
        this.setState({
          series_payorlink_id: null,
          show_remove_payorlink: false
        });
      } else */
      if (object_name === "SER"){
        this.setState({
          series_payorlink_id: null,
          series_payorlink_id: null,
          show_remove_payorlink: false
        });
      }
    });
  }

  retrieve_invoices(limit, offset){
    fetch("/api/ser/"+this.state.series.code+"/invoices?limit="+String(limit)+"&offset="+String(offset), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }/*,
      body: JSON.stringify({})*/
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Erro in deleting Payor link.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      let series = this.state.series;
      series.invoices = series.invoices.concat(json.invoices);
      this.setState({
        series: series
      });
    });
  }

  render () {
    console.log(this.state);
    return (this.state.series === null ? 
      <div className="mb-form-section">
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}
        <h2 style={{color: 'grey'}}>
          Series
        </h2>
        <p style={{fontSize: '14px'}}>
          Loading...
        </p>
      </div> : 
      <div className="mb-form-section">
        
        {this.state.show_add_payorlink ? 
          <MbOverlay on_cancel={() => this.setState({show_add_payorlink: false})} title="Link to Payor Account">
            <div>
              <p style={{color: 'grey'}}>
                Do you want to link this Series?
              </p>
              <table><tbody>
                <tr>
                  <td>
                    <button onClick={() => this.save_series_payor()} className="std-button">
                      Save Series
                    </button>
                  </td>
                  <td>
                    <button onClick={() => this.setState({show_add_payorlink: false})} className="std-button">
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody></table>
            </div>
          </MbOverlay> : null}

        {this.state.show_remove_payorlink ? 
          <MbOverlay on_cancel={() => this.setState({show_remove_payorlink: false})} title="Remove Link">
            <div>
              <p style={{color: 'grey'}}>
                Are you sure you want to remove the link between this Series and your Payor account?
              </p>
              <table><tbody>
                <tr>
                  <td>
                    <button onClick={() => this.remove_payorlink("SER", this.state.series_payorlink_id)} className="std-button">
                      Remove
                    </button>
                  </td>
                  <td>
                    <button onClick={() => this.setState({show_remove_payorlink: false})} className="std-button">
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody></table>
            </div>
          </MbOverlay> : null}

        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <div style={{width: '100px'}}>
              <div style={{backgroundColor: this.SERIES_STATUS_TO_COLOUR[this.state.series.status]}} className="status-box">
                {this.SERIES_STATUS_TO_WORD[this.state.series.status]}
              </div>
              </div>
            </td>
            <td>
              <div style={{fontSize: '16px', color: 'grey', textAlign: 'right', margin: '5px', fontFamily: 'Roboto Mono'}}>
                {this.state.series.code}
              </div>
            </td>
          </tr>
        </tbody></table>
        
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <h2>
                <span style={{color: 'grey'}}>Series:</span> {this.state.series.title}
              </h2>
            </td>
            <td style={{textAlign: 'right'}}>
              <h2 style={{color: 'grey'}}>
                ${this.amount_to_formatted_string(this.state.series.total_due + this.state.series.total_paid)}
              </h2>
            </td>
          </tr>
        </tbody></table>

        <table style={{width: '100%'}}><tbody>
          <tr>
            <td style={{width: '100%', verticalAlign: 'top'}}>
        <table className="mb-invoice-table"><tbody>
          <tr>
            <td>
              Organisation
            </td>
            <td>
              {this.state.series.company_name}
            </td>
          </tr>
          {this.state.series.company_abn === null ? null : 
          <tr>
            <td>
              ABN
            </td>
            <td>
              {this.display_abn(this.state.series.company_abn)}
            </td>
          </tr>}
          <tr>
              <td>
                Website
              </td>
              <td>
                <a href={this.state.series.company_website} style={{color: "grey", textDecoration: "underline"}} target="_blank">{this.state.series.company_website}</a>
              </td>
            </tr>
          <tr>
            <td>
              Outstanding
            </td>
            <td>
              <b>${this.amount_to_formatted_string(this.state.series.total_due)}</b>
            </td>
          </tr>
          <tr>
            <td>
              Total Paid
            </td>
            <td>
              ${this.amount_to_formatted_string(this.state.series.total_paid)}
            </td>
          </tr>
          {/*<tr>
            <td>
              Date created
            </td>
            <td>
              {this.state.series.created_at.slice(5,16)}
            </td>
          </tr>*/}
          <tr>
            <td>
              Short URL
            </td>
            <td>
              <span style={{fontFamily: 'Roboto Mono'}}>invc.me/{this.state.series.short_url}</span>
              <br /><span onClick={(e) => this.click_to_copy(e, 'invc.me/'+this.state.series.short_url)} className="copy-span">COPY</span>
              {this.props.passkey === null ? null : <span onClick={this.toggle_url_passkey_inclusion} className="copy-span">W/{this.state.series.short_url.includes('?pk') ? 'O' : null} PASSKEY</span>}
            </td>
          </tr>
          <tr>
            <td>
              Description
            </td>
            <td>
              {this.state.series.description}
            </td>
          </tr>
          <tr>
            <td>
              Num Invoices
            </td>
            <td>
              {this.state.series.invoice_count}
            </td>
          </tr>
        </tbody></table>
            </td>
            {/*<td style={{verticalAlign: 'top', textAlign: 'center'}}>
              <div style={{margin: 'auto', textAlign: 'center'}} dangerouslySetInnerHTML={{__html: this.state.series.short_url.includes('?pk') ? this.props.qrcode_pk : this.props.qrcode}}>

              </div>
              <br />
              {this.state.series_payorlink_id === null ? 
                <button onClick={() => this.state.payor_code === null ? this.setState({show_payor_status: 1}) :  this.setState({show_add_payorlink: true})} className="std-button">
                  <i className="fa-solid fa-cloud-arrow-down"></i> Save Series
                </button> : 
                <button className="std-button" onClick={() => this.setState({show_remove_payorlink: true})}>
                  <i className="fa-solid fa-check"></i> Saved
                </button>}
              <br />
            </td>*/}
          </tr>
        </tbody></table>

        <h3>
          Invoices
        </h3>

        <table id="invoice-results"><tbody>
              {this.state.series.invoices.length === 0 ? null : 
              <tr>
                <th>
                  Status
                </th>
                <th>
                  Title
                </th>
                {/*<th>
                  Due at
                </th>*/}
                <th>
                  Amount
                </th>
              </tr>}
              {this.state.series.invoices.map(
                (result, index) =>
                <tr key={index} onClick={() => {window.location.href = "/invoice/"+result.code;}}>
                  <td>
                    {/*<div style={{backgroundColor: result.amount_paid === 0 ? '#040dba' : (result.amount_paid === result.amount ? '#32bfb8' : '#e6a330'), color: result.amount_paid === 0 ? '#fff' : '#fff', padding: '3px', fontSize: '13px', borderRadius: '4px', width: '80px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer'}}>
                      {result.amount_paid === 0 ? "UNPAID" : (result.amount === result.amount_paid ? "PAID" : "PART PAID")}
                    </div>*/}
                    <div style={{backgroundColor: this.INVOICE_STATUS_TO_COLOUR[result.status], color: '#fff', padding: '3px', fontSize: '13px', borderRadius: '4px', width: '80px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', margin: 'auto', fontFamily: 'Roboto Mono'}}>
                      {this.INVOICE_STATUS_TO_WORD[result.status]}
                    </div>
                  </td>
                  <td>
                    <b>{result.title}</b>
                  </td>
                  {/*result.due_at === null ? 
                    <td>
                    </td> : 
                    <td style={{color: (new Date(result.due_at)).getTime() - Date.now() < 0 && result.status < 4 ? 'red' : 'black'}}>
                      {result.due_at.slice(0,10)}
                    </td>*/}
                  <td style={{textAlign: 'right'}}>
                    ${this.amount_to_formatted_string(result.amount)}
                  </td>
                </tr>
              )}
            </tbody></table>
            {this.state.series.invoice_count > this.state.series.invoices.length ? 
              <div>
                <button className="mb-button" onClick={() => this.retrieve_invoices(this.RETRIEVE_LIMIT, this.state.series.invoices.length)}>
                  More
                </button>
              </div> : null}
            {this.state.series.invoices.length === 0 ? 
              <div style={{color: 'grey', fontSize: '0.9em'}}>
                There are no Invoices currently attached to this Series.
              </div> : null}

      </div>
    );
  }
}

export default MbShowSeries
