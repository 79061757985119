import React from "react"
import PropTypes from "prop-types"
import Toggle from "./Toggle.js"
import Overlay from "./Overlay.js"
import MyInput from "./MyInput.js"

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement, // doughtnut
} from 'chart.js';
import { Line } from 'react-chartjs-2';

class AdminInvoice extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      messages: [],
      invoice: null,
      selected_transfer_index: -1,
      init_loaded: false,
      payment_history: {
        show: false,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: false,
              text: 'Payments',
            }
          },
          scales: {
            yAxis1: {
              title: {
                display: true,
                text: '$ payment'
              },
              suggestedMin: 0,
              suggestedMax: 10
            }
          }
        },
        data: {
          labels: [],
          datasets: [
            {
              label: 'Payment',
              data: [],
              borderColor: '#32bfb8',
              backgroundColor: '#efefef',
              yAxisID: 'yAxis1'
            }
          ]
        }
      },
      show_set_as_paid: false,
      loading: false,
      visibility_changed: false,
      show_delete: false,
      typed_delete_code: "",
      typed_delete_code_status: 0,
      typed_delete_code_message: "",
      typed_tag: "",
      show_status_screen: false,
      selected_status: 0,
      show_change_series: false,
      typed_series_code: "",
      typed_series_code_status: 0,
      typed_series_code_message: "",
      selected_series: null
    };

    this.retrieve_details = this.retrieve_details.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.time_values_to_chart_data = this.time_values_to_chart_data.bind(this);
    this.unix_to_formatted_date = this.unix_to_formatted_date.bind(this);
    this.unix_to_formatted_time = this.unix_to_formatted_time.bind(this);
    this.toggle_url_passkey_inclusion = this.toggle_url_passkey_inclusion.bind(this);
    this.toggle_visibility = this.toggle_visibility.bind(this);
    this.click_to_copy = this.click_to_copy.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.admin_update = this.admin_update.bind(this);
    this.confirm_delete = this.confirm_delete.bind(this);
    this.on_type_tag = this.on_type_tag.bind(this);
    this.on_key_down_tag = this.on_key_down_tag.bind(this);
    this.update_tags = this.update_tags.bind(this);
    this.remove_tag = this.remove_tag.bind(this);
    this.get_series = this.get_series.bind(this);
    this.assign_invoice = this.assign_invoice.bind(this);
    this.remove_invoice = this.remove_invoice.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    /*this.INVOICE_STATUS_TO_WORD = {'1':'INITIATE','2':'UNPAID','3':'PART PAID','4':'PAID','5':'SUSPEND'};
    this.INVOICE_STATUS_TO_TITLE = {'1':'Invoice Initiated','2':'Invoice Unpaid','3':'Invoice Partly Paid','4':'Invoice Paid','5':'Invoice Suspended by User'};
    this.INVOICE_STATUS_TO_DESCRIPTION = {'1':'Invoice has been initiated but has not been opened yet to payment.',
      '2':'Invoice is unpaid and is awaiting payment.',
      '3':'Invoice has been partly paid.',
      '4':'Invoice has been paid.',
      '5':'Invoice has been suspended by user'};
    this.INVOICE_STATUS_TO_COLOUR = {'1':'#040dba','2':'#040dba','3':'#e6a330','4':'#32bfb8','5':'#e6a330'};
    this.INVOICE_STATUS_TO_ADMIN_ACTION = {'2':'OPEN','5':'SUSPEND','4':'SET_AS_COMPLETE'};*/
    this.INVOICE_STATUS_TO_WORD = {'0':'INITIATE','1':'UNPAID','2':'PART PAID','3':'SUSPEND','4':'PAID','5':'PAID','6':'PAID'};
    this.INVOICE_STATUS_TO_TITLE = {'0':'Invoice Initiated','1':'Invoice Unpaid','2':'Invoice Partly Paid','3':'Invoice Suspended by User','4':'Invoice Paid','5':'Invoice Paid in Excess','6':'Invoice Paid by Other Source'};
    this.INVOICE_STATUS_TO_DESCRIPTION = {'0':'Invoice has been initiated but has not been opened yet to payment.',
      '1':'Invoice is unpaid and is awaiting payment.',
      '2':'Invoice has been partly paid, and is awaiting payment of the balance.',
      '3':'Invoice has been suspended by you and is not visible except to you.',
      '4':'Invoice has been paid.',
      '5':'Invoice has been paid in excess of what was owed.',
      '6':'Invoice has been paid by another source, not through PaySolve.'};
    this.INVOICE_STATUS_TO_COLOUR = {'0':'#040dba','1':'#040dba','2':'#e6a330','3':'#e6a330','4':'#32bfb8','5':'#32bfb8','6':'#32bfb8'};
    this.INVOICE_STATUS_TO_ADMIN_ACTION = {'1':'OPEN','2':'OPEN','3':'SUSPEND','6':'SET_AS_COMPLETE'};

// UNINITIATED, INITIATED, PARTPAID, SUSPENDED, PAID, EXCESS_PAID, PAID_EXTERNALLY, DELETED = [0, 1, 2, 3, 4, 5, 6, -1]

    /*this.STATUS_PROGRESSION_MAP = {
      '0':[1,3],
      '1':[1,3],
      '2':[2,3],
      '3':[]
    }*/

  }

  componentDidMount(){
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend,
      ArcElement
    );
    this.retrieve_details();
  }

  time_values_to_chart_data(values, earliest_unix, latest_unix){
    console.log('values');
    console.log(values);
    //let prelabels = transfers.map((t,i) => t.transferred_at / 86400000);
    let prelabels = Array(Math.round((latest_unix - earliest_unix) / 86400)).fill(0).map((x,i) => i);
    let unixlabels = prelabels.map((l,i) => l*86400 + earliest_unix);
    let labels = unixlabels.map((l,i) => (new Date(l * 1000)).toDateString().slice(4,10));//prelabels.map((l,i) => (new Date((l*86400 + earliest_unix) * 1000)).toDateString().slice(4,10));
    console.log(earliest_unix);
    console.log(prelabels);
    console.log(unixlabels);
    console.log(labels);
    let time2val = {};
    let i;
    for (i = 0 ; i < values.length ; i++){
      if (time2val[String(values[i].time - values[i].time % 86400)] === undefined){
        time2val[String(values[i].time - values[i].time % 86400)] = values[i].value;
      } else {
        time2val[String(values[i].time - values[i].time % 86400)] += values[i].value;
      }
    }
    let data = unixlabels.map((t,i) => time2val[String(t)] === undefined ? 0 : time2val[String(t)]);
    let data_cumul = Array(data.length).fill(0);
    for (i = 0 ; i < data_cumul.length ; i++){
      data_cumul[i] = (i === 0 ? 0 : data_cumul[i-1]) + data[i];
    }
    console.log('data');
    console.log(data_cumul);
    return {
      labels: labels,
      data: data,
      data_cumul: data_cumul
    };
  }

  retrieve_details(){
    // /api/invoice/:code
    fetch("/api/invoice/"+this.props.invoice_code+"/admin", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      
      throw new Error('Request fail');
    }).then(json => {
      console.log(json.invoice);
      this.setState({
        invoice: json.invoice,
        selected_status: json.invoice === undefined ? this.state.selected_status : json.invoice.status,
        init_loaded: true
      });
      let pc_data = this.time_values_to_chart_data(json.invoice.transfers.map((t,i) => ({time:t.transferred_at,value:t.amount / 100})), json.invoice.date_accrued_at, Math.round(Date.now() / 1000));
      let pc_state = this.state.payment_history;
      pc_state.show = true;
      pc_state.data.labels = pc_data.labels;
      pc_state.data.datasets[0].data = pc_data.data;
      this.setState({
        payment_history: pc_state
      });
    });
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  unix_to_formatted_date(unix){
    return (new Date(unix * 1000)).toDateString();
  }

  unix_to_formatted_time(unix){
    return (new Date(unix * 1000)).toTimeString().slice(0,17);
  }

  toggle_url_passkey_inclusion(){
    let invoice = this.state.invoice;
    if (invoice.short_url.includes('?pk')){
      invoice.short_url = invoice.short_url.split('?pk')[0];
    } else {
      invoice.short_url += ('?pk='+this.state.invoice.passkey);
    }
    this.setState({
      invoice: invoice
    });
  }

  toggle_visibility(v){
    console.log("Toggle visibility");
    this.setState({
      visibility_changed: true
    });
  }

  click_to_copy(e, text){
    navigator.clipboard.writeText(text);
    console.log(e);
    e.target.innerHTML = "COPIED!"
    setTimeout(() => {
      e.target.innerHTML = "COPY";
    }, 1000);
  }

  admin_update(action){
    this.setState({
      loading: true
    });
    // /api/invoice/:code/admin
    fetch("/api/invoice/"+this.props.invoice_code+"/admin", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        'action_name': action
      })
    }).then( (response) => {
      this.setState({
        loading: false
      });
      if (response.ok){
        return response.json();
      }
      this.add_message("An error was encountered", 'danger');
      this.setState({
        show_set_as_paid: false
      });
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        if (action === 'SET_AS_PAID'){
          this.add_message("Successfully set as paid", 'success');
        } else if (action === 'DELETE'){
          console.log("Successfully deleted.");
          location.href = "/redirect2?redirect_to=%2Finvoices%2Findex&flash_type=success&flash_message=Invoice%20successfully%20deleted.";
        } else if (action === 'VISIBLE'){
          this.add_message("Invoice set as visible", 'success');
        } else if (action === 'INVISIBLE'){
          this.add_message("Invoice set as invisible", 'success');
        } else if (action === 'OPEN'){
          this.add_message("Invoice is visible and can collect payments",'success');
        } else if (action === 'SUSPEND'){
          this.add_message("Invoice has been suspended. It is only visible to you, and cannot collect payments.",'success');
        }
      } else {
        this.add_message("An error was encountered", 'danger');
      }
      this.setState({
        show_set_as_paid: false,
        show_status_screen: false,
        invoice: json.invoice
      });
    });
  }

  remove_invoice(){
    // /ser/:code/assign_invoice
    fetch("/api/ser/"+this.state.invoice.series_code+"/assign_invoice", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        invoice_code: this.state.invoice.code
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed invoice assignment.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        show_change_series: false
      });
      this.add_message("Invoice successfully unassigned to Series",'success');
      this.retrieve_details();
    });
  }

  /*update_series(){
    // /api/invoice/:code/edit
    fetch("/api/invoice/"+this.props.invoice_code+"/edit", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        'invoice': {
          series_id: this.state.selected_series.id
        }
      })
    }).then( (response) => {
      this.setState({
        loading: false
      });
      if (response.ok){
        return response.json();
      }
      this.add_message("An error was encountered", 'danger');
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        show_change_series: false
      });
      this.add_message("Successfully set Series",'success');
      this.retrieve_details();
    });
  }*/

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  confirm_delete(){
    console.log(this.state.typed_delete_code);
    console.log(this.state.invoice.short_url);
    if (this.state.typed_delete_code != this.state.invoice.short_url){
      this.setState({
        typed_delete_code_status: 3,
        typed_delete_code_message: "Incorrect code"
      });
      return false;
    }
    this.admin_update('DELETE');
  }

  on_type_tag(e){
    this.setState({
      typed_tag: e.target.value
    });
  }

  on_key_down_tag(e){
    if (e.key === 'Enter' && !this.state.invoice.tags.includes(this.state.typed_tag)){
      this.update_tags([this.state.typed_tag],[]);
    }
  }

  update_tags(add_terms, remove_terms){
    fetch("/api/invoice/"+this.state.invoice.code+"/tag", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        add: add_terms,
        remove: remove_terms
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      let invoice = this.state.invoice;
      invoice.tags.filter((e) => !remove_terms.includes(e));
      invoice.tags = this.state.invoice.tags.concat(add_terms);
      this.setState({
        invoice: invoice,
        typed_tag: ""
      });
    });
  }

  remove_tag(index){
    this.update_tags([], [this.state.invoice.tags[index]]);
    let invoice = this.state.invoice;
    invoice.tags.splice(index, 1);
  }

  get_series(){
    fetch("/api/ser/"+this.state.typed_series_code+"/admin", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.setState({
        typed_series_code_status: 3,
        typed_series_code_message: "Could not match to a Series"
      })
      //throw new Error('Request fail');
    }).then(json => {
      if (json.series != null){
        this.setState({
          selected_series: json.series,
          typed_series_code_status: 2,
          typed_series_code_message: "Successfully matched to a Series"
        });
      }
    });
  }

  assign_invoice(){
    // /ser/:code/assign_invoice
    fetch("/api/ser/"+this.state.selected_series.code+"/assign_invoice", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        invoice_code: this.state.invoice.code
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed invoice assignment.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        show_change_series: false
      });
      this.add_message("Invoice successfully assigned to Series",'success');
    });
  }

  render () {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Payments',
        }
      },
      scales: {
        yAxis1: {
          title: {
            display: true,
            text: '$ payment to Invoice'
          }
        }
      }
    };
    
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Payment',
          data: [102, 34, 22, 58, 103, 75, 72],
          borderColor: '#32bfb8',
          backgroundColor: '#efefef',
          yAxisID: 'yAxis1'
        }
      ]
    };

    return ( this.state.init_loaded ? 
      <div className="form-section">

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        {this.state.show_set_as_paid ? 
          <Overlay on_cancel={() => this.setState({show_set_as_paid: false})} title="Set this invoice as paid">
            <div>
              <p style={{fontSize: '14px', color: 'grey'}}>
                Doing this will mean the invoicee will no longer have to pay. This is usually done when the amount being owed has been paid by another method.
              </p>
              <p style={{fontSize: '14px', color: 'grey'}}>
                This will not affect amounts that have already been paid.
              </p>
              <table style={{width: '100%', fontSize: '16px', marginBottom: '10px', fontWeight: 'bold'}}><tbody>
                <tr>
                  <td>
                    SET AS PAID
                  </td>
                  <td style={{textAlign: 'right'}}>
                    ${this.amount_to_formatted_string(this.state.invoice.amount)}
                  </td>
                </tr>
              </tbody></table>
              <button className="std-button" onClick={() => this.admin_update('SET_AS_PAID')}>
                {this.state.loading ? 'Setting' : 'Set'} as paid
              </button>
            </div>
          </Overlay> : null}
        
        {this.state.show_change_series ? 
          <Overlay on_cancel={() => this.setState({show_change_series: false})} title="Invoice Series">
            <div>
              <h4>
                Current Series
              </h4>
              {this.state.invoice.series_id === null ? 
                <div style={{color: 'grey', fontSize: '14px'}}>
                  Assigned to no Series
                </div> : 
                <div>
                  <div style={{fontFamily: 'Roboto Mono'}}>
                    {this.state.invoice.series_code}
                  </div>
                  <div>
                    {this.state.invoice.series_title}
                  </div>
                  <button className="button-blue" onClick={this.remove_invoice}>
                    Remove
                  </button>
                </div>}<br />
              <h4>
                Set Series
              </h4>
              <button className="button-blue" onClick={() => {window.open('/series/search','_blank')}}>
                Search <i className="fa-solid fa-arrow-up-right-from-square"></i>
              </button>
              <br /><br />

              {this.state.selected_series === null ? null : <div>
                <div style={{color: 'grey', fontFamily: 'Roboto Mono'}}>
                  {this.state.selected_series.code}
                </div>
                <div>
                  <b>{this.state.selected_series.title}</b>
                </div>
                <button className="button-blue" onClick={this.assign_invoice}>
                  Set
                </button>
              </div>}
              
              <br />
              <MyInput onChange={(e) => this.setState({typed_series_code: e.target.value})} value={this.state.typed_series_code} status={this.state.typed_series_code_status} note={this.state.typed_series_code_message} label={"Series code"} />
              <button className="button-blue" onClick={this.get_series}>
                Add
              </button>
            </div>
          </Overlay> : null}

        {this.state.show_delete ? 
          <Overlay on_cancel={() => this.setState({show_delete: false})} title="Delete Invoice">
            <div>
              <p>
                Are you sure you want to delete this invoice <b>{this.state.invoice.title}</b>?
              </p>
              <p>
                To delete, enter the invoice code <b>{this.state.invoice.short_url}</b>:
              </p>
              <div className="myinput-wrapper-centre">
                <MyInput onChange={(e) => this.setState({typed_delete_code: e.target.value, typed_delete_code_status: 1, typed_delete_code_message: ""})} value={this.state.typed_delete_code} status={this.state.typed_delete_code_status} note={this.state.typed_delete_code_message} />
              </div>
              <table><tbody>
                <tr>
                  <td>
                    <button className="std-button" style={{backgroundColor: 'red'}} onClick={this.confirm_delete}>
                      Delete
                    </button>
                  </td>
                  <td>
                    <button className="std-button" onClick={() => this.setState({show_delete: false})}>
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody></table>
            </div>
          </Overlay> : null}
        
        {this.state.show_status_screen ? 
          <Overlay on_cancel={() => this.setState({show_status_screen: false})} title="Change Invoice Status">
            <div>
              {/*<table style={{width: '90%'}}><tbody>
                {[(this.state.invoice.transfers.length > 0 ? 2 : 1), 3].map(
                  (status, index) =>
                  <tr key={index}>
                    <td style={{width: '40px', padding: '10px', verticalAlign: 'top'}}>
                      <div className={this.state.selected_status === status ? "standard-radio-checked" : "standard-radio"} onClick={() => this.setState({selected_status: status})}>
                        <div />
                      </div>
                    </td>
                    <td style={{padding: '10px'}}>
                      <div style={{fontWeight: 'bold', fontSize: '14px'}}>
                        {this.INVOICE_STATUS_TO_TITLE[String(status)]}
                      </div>
                      <div style={{fontSize: '13px'}}>
                        {this.INVOICE_STATUS_TO_DESCRIPTION[String(status)]}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody></table>*/}

              {this.state.invoice.amount_paid + this.state.invoice.amount_paid_by_other_source > 0 && this.state.invoice.amount_paid + this.state.invoice.amount_paid_by_other_source < this.state.invoice.amount ? 
                <div className={[2].includes(this.state.selected_status) ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({selected_status: 2})}>
                  <table><tbody>
                    <tr>
                      <td>
                        <div>
                          <div />
                        </div>
                      </td>
                      <td>
                        <h3>
                          Part Paid
                        </h3>
                      </td>
                    </tr>
                  </tbody></table>
                </div> : null}

              {this.state.invoice.amount_paid + this.state.invoice.amount_paid_by_other_source === 0 ? 
                <div className={[0,1].includes(this.state.selected_status) ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({selected_status: 1})}>
                  <table><tbody>
                    <tr>
                      <td>
                        <div>
                          <div />
                        </div>
                      </td>
                      <td>
                        <h3>
                          Unpaid
                        </h3>
                      </td>
                    </tr>
                  </tbody></table>
                </div> : null}
              
              <div className={this.state.selected_status === 3 ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({selected_status: 3})}>
                <table><tbody>
                  <tr>
                    <td>
                      <div>
                        <div />
                      </div>
                    </td>
                    <td>
                      <h3>
                        Suspended
                      </h3>
                    </td>
                  </tr>
                </tbody></table>
              </div>

              <button className="std-button" onClick={() => this.admin_update(this.INVOICE_STATUS_TO_ADMIN_ACTION[this.state.selected_status])}>
                {this.state.loading ? "Saving..." : "Save"}
              </button>
            </div>
          </Overlay> : null}

        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <h2>
                <span style={{color:'grey'}}>Invoice </span>{this.state.invoice.title}
              </h2>
            </td>
            <td style={{textAlign: 'right'}}>
              <h2 style={{color: 'grey'}}>
                ${this.amount_to_formatted_string(this.state.invoice.amount)}
              </h2>
            </td>
          </tr>
        </tbody></table>
        {/*this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td>

                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )*/}

        <table className="admin-top-table"><tbody>
          <tr>
            <td>
              <div className="grey-cap-title">
                STATUS
              </div>
              {/*<div style={{backgroundColor: this.state.invoice.amount_paid === 0 ? '#040dba' : (this.state.invoice.amount_paid === this.state.invoice.amount ? '#32bfb8' : '#e6a330'), color: this.state.invoice.amount_paid === 0 ? '#fff' : '#fff', padding: '3px', fontSize: '13px', borderRadius: '4px', width: '80px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', margin: 'auto'}}>
                {this.state.invoice.amount_paid === 0 ? "UNPAID" : (this.state.invoice.amount === this.state.invoice.amount_paid ? "PAID" : "PART PAID")}
              </div>*/}
              <div style={{backgroundColor: this.INVOICE_STATUS_TO_COLOUR[this.state.invoice.status]/*, color: this.state.invoice.amount_paid === 0 ? '#fff' : '#fff', padding: '3px', fontSize: '13px', borderRadius: '4px', width: '80px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', margin: 'auto', fontFamily: 'Roboto Mono'*/}} className="status-box">
                {this.INVOICE_STATUS_TO_WORD[this.state.invoice.status]}
              </div>
            </td>
            <td>
              <div className="grey-cap-title">
                AMOUNT PAID
              </div>
              <div style={{fontSize: '18px', fontWeight: 'bold', textAlign: 'center'}}>
                ${this.amount_to_formatted_string(this.state.invoice.amount_paid)}
              </div>
            </td>
            <td>
              <div className="grey-cap-title">
                EXTERNALLY PAID
              </div>
              <div style={{fontSize: '18px', fontWeight: 'bold', textAlign: 'center'}}>
                ${this.amount_to_formatted_string(this.state.invoice.amount_paid_by_other_source)}
              </div>
            </td>
            <td>
              <div className="grey-cap-title">
                ACCRUED AT
              </div>
              <div style={{fontSize: '18px', fontWeight: 'bold', textAlign: 'center'}}>
                {this.unix_to_formatted_date(this.state.invoice.date_accrued_at).slice(4,20)}
              </div>
            </td>
          </tr>
        </tbody></table>

        <table style={{marginTop: '20px'}}><tbody>
          <tr>
            <td style={{width: '190px', verticalAlign: 'top'}}>
                <span style={{fontFamily: 'Roboto Mono', color: 'grey', fontSize: '15px'}}>Inv # {this.state.invoice.code}</span>
              <table><tbody>
                {[1,4].map(
                  (status, index) =>
                  <tr className={this.state.invoice.status >= status ? "admin-progression-complete" : "admin-progression-incomplete"} key={index}>
                    <td>
                      {index != 0 ? <span style={{fontWeight: 'normal'}}><i className="fa-solid fa-arrow-down"></i><br /></span> : null}{this.state.invoice.status >= status ? <i className="fa-regular fa-circle-check"></i> : <i className="fa-regular fa-circle"></i>}
                    </td>
                    <td>
                      {['Issued','Paid','Disbursed'][index]}
                    </td>
                  </tr>)}
              </tbody></table>
              <div style={{marginTop: '10px', textAlign: 'center'}}>
                <table><tbody>
                  <tr>
                    <td>
                      <button className="button-blue" onClick={() => location.href = '/invoice/'+this.props.invoice_code+(this.state.invoice.privacy_status > 0 ? '?pk='+this.state.invoice.passkey : '')}>
                        See invoice
                      </button>
                    </td>
                    {this.state.invoice.status < 2 ? 
                      <td>
                        <button className="button-blue" onClick={() => location.href = '/invoice/'+this.props.invoice_code+'/edit'}>
                          Edit Invoice
                        </button>
                      </td> : null}
                  </tr>
                </tbody></table>
              </div>
            </td>
            <td style={{verticalAlign: 'top'}}>
              <div style={{width: '400px', height: '120px'}}>
                {this.state.payment_history.show && false ? 
                  <Line options={this.state.payment_history.options} data={this.state.payment_history.data} /> : null}
                <h3 style={{textAlign: 'center'}}>
                  {Math.round(100.0*(this.state.invoice.amount_paid + this.state.invoice.amount_paid_by_other_source) / this.state.invoice.amount)}% paid
                </h3>
                <div style={{width: '240px', margin: 'auto', backgroundColor: '#efefef', height: '12px', borderRadius: '4px', overflow: 'hidden'}}>
                  <div style={{width: String(240.0*(this.state.invoice.amount_paid + this.state.invoice.amount_paid_by_other_source) / this.state.invoice.amount)+'px', height: '12px', backgroundColor: '#32bfb8'}} />
                </div>
                <h4 style={{textAlign: 'center', color: 'grey'}}>
                  ${this.amount_to_formatted_string(this.state.invoice.amount_paid + this.state.invoice.amount_paid_by_other_source)} of ${this.amount_to_formatted_string(this.state.invoice.amount)} paid
                </h4>
              </div>
            </td>
          </tr>
        </tbody></table>

        <table style={{marginTop: '10px'}}><tbody>
          <tr>
            <td style={{width: '200px', verticalAlign: 'top', textAlign: 'center'}}>
              <div className="grey-cap-title">
                SHORT URL
              </div>
              <div style={{fontWeight: 'bold', fontSize: '18px', fontFamily: 'Roboto Mono'}}>
                invc.me/{this.state.invoice.short_url}
              </div>
              <div>
                <span onClick={(e) => this.click_to_copy(e, 'invc.me/'+this.state.invoice.short_url)} className="copy-span">COPY</span>
                {this.state.invoice.privacy_status === 0 ? null : <span onClick={this.toggle_url_passkey_inclusion} className="copy-span">W/{this.state.invoice.short_url.includes('?pk') ? 'O' : null} PASSKEY</span>}
              </div>
            </td>
            <td style={{width: '200px', verticalAlign: 'top', textAlign: 'center'}}>
              <div className="grey-cap-title">
                STATUS
              </div>
              {[1,2,3,4,5,6].includes(this.state.invoice.status) ? 
                <div>
                  <span style={{fontSize: '15px',fontWeight:'bold'}}>{this.INVOICE_STATUS_TO_TITLE[this.state.invoice.status]}</span><br />
                  {this.state.invoice.status === 4 ? null : <span onClick={(e) => this.setState({show_status_screen: true})} className="copy-span">CHANGE</span>}
                </div> : null}
            </td>
            <td style={{width: '200px', verticalAlign: 'top', textAlign: 'center'}}>
              <div className="grey-cap-title">
                SERIES
              </div>
              <span style={{fontSize: '15px',fontWeight:'bold'}}>{this.state.invoice.series_id === null ? <span style={{color: 'grey'}}>None</span> : this.state.invoice.series_title}</span><br />
              <span onClick={(e) => this.setState({show_change_series: true})} className="copy-span">CHANGE</span>
            </td>
            {/*<td style={{verticalAlign: 'top'}}>
              <div className="grey-cap-title">
                PRIVACY STATUS
              </div>
              <table><tbody>
                <tr>
                  <td style={{padding: '5px'}}>
                    <Toggle on_toggle={(v) => this.toggle_visibility(v)} init_value={true} />
                  </td>
                  <td style={{padding: '5px', fontSize: '14px'}}>
                    Visible
                  </td>
                </tr>
              </tbody></table>
              {this.state.visibility_changed ? 
                <button className="button-blue">
                  Save
                </button> : null}
              </td>*/}
          </tr>
        </tbody></table>

        <h3>
          Tags
        </h3>
        <div style={{backgroundColor: "#efefef", borderRadius: '6px', margin: '5px', minHeight: '40px', padding: '5px'}}>
          {this.state.invoice.tags.map(
            (tag, index) =>
            <div key={index} style={{padding: '6px', paddingLeft: '12px', paddingRight: '12px', fontSize: '13px', margin: '5px', backgroundColor: '#040dba', color: '#fff', display: 'inline-block', borderRadius: '14px', fontWeight: 'bold', fontFamily: 'Roboto Mono'}}>
              {tag} <i style={{marginLeft: '4px', cursor: 'pointer'}} className="fa-solid fa-circle-xmark" onClick={() => this.remove_tag(index)}></i>
            </div>
          )}
          <div style={{padding: '0px', paddingLeft: '12px', paddingRight: '12px', fontSize: '13px', margin: '0px', display: 'inline-block', borderRadius: '14px'}}>
            <input type="text" style={{backgroundColor: '#efefef', border: 'none', fontFamily: 'Roboto Mono'}} id="tag-input" value={this.state.typed_tag} onChange={this.on_type_tag} onKeyDown={this.on_key_down_tag} placeholder="Type tag here..." />
          </div>
        </div>

        <h3>
          Payments
        </h3>
        <table style={{fontSize: '13px', color: 'grey'}} className="admin-payments-table"><tbody>
          <tr>
            <td>
              Paid at
            </td>
            <td>
              Payor email
            </td>
            <td>
              Status
            </td>
            <td>
              Amount
            </td>
          </tr>
        </tbody></table>
        {this.state.invoice.transfers.map(
          (transfer, index) =>
          <div style={{margin: '2px', padding: '3px', backgroundColor: '#efefef', borderRadius: '4px', cursor: 'pointer'}} key={index} onClick={() => this.setState({selected_transfer_index: this.state.selected_transfer_index === index ? -1 : index})}>
            <table className="admin-payments-table" style={{fontSize: '13px', fontFamily: 'Roboto Mono'}}><tbody>
              <tr>
                <td>
                  {this.unix_to_formatted_date(transfer.transferred_at).slice(4,20)}
                </td>
                <td>
                  {transfer.payor_email === null ? "Anonymous" : transfer.payor_email}
                </td>
                <td>
                  {transfer.bank_status}
                </td>
                <td style={{textAlign: 'right'}}>
                  {this.amount_to_formatted_string(transfer.amount)}
                </td>
              </tr>
            </tbody></table>
            {this.state.selected_transfer_index === index ? 
            <div>
              <table className="admin-payments-table" style={{fontSize: '13px', fontFamily: 'Roboto Mono'}}><tbody>
                <tr>
                  <td>
                    {this.unix_to_formatted_time(transfer.transferred_at)}
                  </td>
                  <td>
                    
                  </td>
                  <td>
                    
                  </td>
                  <td style={{textAlign: 'right'}}>
                    AUD
                  </td>
                </tr>
              </tbody></table>
            </div> : null}
          </div>
        )}

        {this.state.invoice.paid_by_other_source ? 
          <div style={{margin: '2px', padding: '3px', backgroundColor: '#efefef', borderRadius: '4px', cursor: 'pointer'}}>
            <table className="admin-payments-table" style={{fontSize: '13px', fontFamily: 'Roboto Mono'}}><tbody>
              <tr>
                <td>
                  {this.unix_to_formatted_date(this.state.invoice.paid_at).slice(4,20)}
                </td>
                <td style={{color: 'grey'}}>
                  PAID BY OTHER SOURCE
                </td>
                <td>
                  Paid
                </td>
                <td style={{textAlign: 'right'}}>
                  {this.amount_to_formatted_string(this.state.invoice.amount - this.state.invoice.transfers.reduce((t,v) => t.amount + v, 0))}
                </td>
              </tr>
            </tbody></table>
          </div> : null}

        {this.state.invoice.transfers.length === 0 && !this.state.invoice.paid_by_other_source ?
        <div style={{textAlign: 'center', fontSize: '14px', color: 'grey', margin: '20px'}}>
          There are no payments
        </div> : null}

        {this.state.invoice.status >= 4 ? null : 
          <table style={{marginTop: '20px'}}><tbody>
            <tr>
              <td>
                <button className="std-button" onClick={() => this.setState({show_set_as_paid: true})}>
                  Set as paid
                </button>
              </td>
              <td>
                <button className="std-button" onClick={() => this.setState({show_delete: true})} style={{backgroundColor: 'red'}}>
                  Delete
                </button>
              </td>
            </tr>
          </tbody></table>}

      </div> : 
      <div className="form-section">
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}
        <h2 style={{color: 'grey'}}>
          Invoice Admin
        </h2>
        <p style={{fontSize: '14px'}}>
          Loading...
        </p>
      </div>
    );
  }
}

export default AdminInvoice
