import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
class LoginPayor extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      messages: [],
      loading: false,
      typed_email: "",
      typed_password: "",
      email_status: 0,
      password_status: 0,
      show_mfa: false,
      typed_mfa: "",
      mfa_status: 0,
      show_email_mfa: false,
      typed_email_mfa: "",
      email_mfa_status: 0
    };

    this.on_type_email = this.on_type_email.bind(this);
    this.on_type_password = this.on_type_password.bind(this);
    this.on_key_down_field = this.on_key_down_field.bind(this);
    this.basic_login = this.basic_login.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);

    this.MESSAGE_TYPE_TO_COLOUR = {'danger':'red','success':'green','info':'blue'};
    this.MESSAGE_TYPE_TO_ICON = {'danger':'circle-exclamation','warning':'circle-exclamation','info':'circle-info','success':'check'}
    this.MFA_LENGTH_LIMIT = 6;
    this.MAX_PASSWORD_LENGTH = 128;
    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    
  }

  on_type_email(e){
    const typed = e.target.value;
    if ("qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM0123456789_.@+-".includes(typed.slice(-1)) && !this.state.show_mfa && !this.state.show_email_mfa){
      this.setState({
        typed_email: typed
      });
    }
  }

  basic_login(){
    if (this.state.typed_email.length === 0 || this.state.typed_password.length === 0){
      this.setState({
        //general_messages: [{type:'danger',content:"You need to enter an email and password"}],
        loading: false,
        email_status: this.state.typed_email.length === 0 ? 3 : 2,
        password_status: this.state.typed_password.length === 0 ? 3 : 2
      });
      return null;
    }
    this.setState({
      loading: true
    });
    fetch("/sessions/payor/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        session: {
          email: this.state.typed_email.toLowerCase(),
          password: this.state.typed_password,
          last_timezone: String(new Date()).slice(28,33)
        }
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log("Result");
      console.log(json);
      if (json.success){
        this.add_message("Successfully logged in.",'success');
        this.setState({
          //general_messages: [{type:'success',content:json.message}],
          loading: false,
          email_status: 2,
          password_status: 2
        });
        setTimeout(() => {
          //window.location.href = this.props.continue_url === null ? '/' : this.props.continue_url;
          //location.href.split('/').slice(0,3).join('/')
          document.getElementById('a-root').click();
        }, 1000);
      } else {
        this.add_message("Error: "+json.message,'danger');
        this.setState({
          //general_messages: [{type:'danger',content:json.message}],
          loading: false,
          email_status: 3,
          password_status: 3
        });
      }
    });
  }

  on_type_password(e){
    console.log("on_type_password");
    const typed = e.target.value;
    if (typed.length <= this.MAX_PASSWORD_LENGTH && !this.state.show_mfa && !this.state.show_email_mfa){
      this.setState({
        typed_password: typed
      });
    }
  }

  on_key_down_field(e){
    if (e.keyCode === 13){
      this.basic_login();
    }
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  render () {
    console.log(this.state);
    return (
      <div style={{/*margin: 'auto', backgroundColor: '#fff', padding: '30px', width: '290px', boxShadow: '0px 0px 14px 0px #c9c9c9'*/display: 'table', margin: 'auto'}}>
        {/*this.state.general_messages.map(
          (message, index) =>
          <div style={{margin: '10px', padding: '10px', backgroundColor: this.MESSAGE_TYPE_TO_COLOUR[message.type], color: '#fff'}} key={index}>
            {message.content}
          </div>
        )*/}
        {this.state.messages.map(
          (message, index) => 
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <div style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '10px'}}>
          Payor Login
        </div>

        <div>
          {/*<input onChange={this.on_type_email} value={this.state.typed_email} onKeyDown={this.on_key_down_field} placeholder="Email" />*/}
          <MyInput onChange={this.on_type_email} value={this.state.typed_email} status={this.state.email_status} onKeyDown={this.on_key_down_field} label="Email" />
        </div>
        <div>
          {/*<input type="password" onChange={this.on_type_password} value={this.state.typed_password} onKeyDown={this.on_key_down_field} placeholder="Password" />*/}
          <MyInput type="password" onChange={this.on_type_password} value={this.state.typed_password} status={this.state.password_status} onKeyDown={this.on_key_down_field} label="Password" />
        </div>

        {this.state.show_mfa || this.state.show_email_mfa ? null : 
          <div>
            <button className="std-button" onClick={this.basic_login}>
              {this.state.loading ? "Logging in" : "Log in"}
            </button>
          </div>}
        <br />
        <a href="/payors/recovery" style={{color: 'grey', fontSize: '13px'}}>Forgot password</a>
        <a href={window.location.href.includes("/login") ? "/" : window.location.href} id="a-root"></a>
      </div>
    );
  }
}

export default LoginPayor
