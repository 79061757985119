import React from "react"
import PropTypes from "prop-types"
import CalendarDate from "./CalendarDate.js"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement, // doughtnut
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

class PayorHome extends React.Component {
  constructor(props){
		super(props);
    this.UPCOMING_INVOICES_LIMIT = 10;
    this.state = {
      init_loading: true,
      invoices: [],
      messages: [],
      upcoming_invoices: [],
      upcoming_invoices_limit: this.UPCOMING_INVOICES_LIMIT,
      can_expand_upcoming_invoices: false,

      issued_invoice_graph_data: [7,5,3,9,2,3,3],
      paid_invoice_graph_data: [0,6,2,8,3,4,5],
      labels: Array.from({length: 7}, (_,j) => j).map((v) => String(new Date(Number(new Date()) - v * 86400000)).slice(4,10)).reverse(),
      chart_options: {
        scales: {
          y: {
            type: 'linear',
            position: 'left',
            title: {
              display: true,
              text: 'Amount ($)',
            },
            ticks: {
              callback: (value) => `$${value}`, // Formatting y-axis as money
            },
          }/*,
          x: {
            title: {
              display: true,
              text: 'Months',
            },
          }*/
        }
      }
		};

    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.obtain_relevant_invoices = this.obtain_relevant_invoices.bind(this);
    this.filter_upcoming_invoices = this.filter_upcoming_invoices.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.update_displayed_time = this.update_displayed_time.bind(this);
    this.go_to_invoice = this.go_to_invoice.bind(this);
    this.unique_invoice_values = this.unique_invoice_values.bind(this);
    this.sort_invoices_by_date_key = this.sort_invoices_by_date_key.bind(this);
    this.parse_graph_data = this.parse_graph_data.bind(this);

    this.INVOICE_WINDOW_DAYS = 7; // 90 days = 86400000ms * 90
    this.UPCOMING_START_LIMIT = 5;

    this.GMT_DIFF = String(new Date()).split('GMT')[1].split(' ')[0];

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

    this.INVOICE_STATUS_TO_WORD = {'0':'INITIATE','1':'UNPAID','2':'PART PAID','3':'SUSPEND','4':'PAID','5':'PAID','6':'PAID'};
    this.INVOICE_STATUS_TO_TITLE = {'0':'Invoice Initiated','1':'Invoice Unpaid','2':'Invoice Partly Paid','3':'Invoice Suspended by User','4':'Invoice Paid','5':'Invoice Paid in Excess','6':'Invoice Paid by Other Source'};
    this.INVOICE_STATUS_TO_DESCRIPTION = {'0':'Invoice has been initiated but has not been opened yet to payment.',
      '1':'Invoice is unpaid and is awaiting payment.',
      '2':'Invoice has been partly paid, and is awaiting payment of the balance.',
      '3':'Invoice has been suspended by you and is not visible except to you.',
      '4':'Invoice has been paid.',
      '5':'Invoice has been paid in excess of what was owed.',
      '6':'Invoice has been paid by another source, not through PaySolve.'};
    this.INVOICE_STATUS_TO_COLOUR = {'0':'#040dba','1':'#040dba','2':'#e6a330','3':'#e6a330','4':'#32bfb8','5':'#32bfb8','6':'#32bfb8'};
    this.INVOICE_STATUS_TO_ADMIN_ACTION = {'1':'OPEN','2':'OPEN','3':'SUSPEND','6':'SET_AS_COMPLETE'};

  }

  componentDidMount(){
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      BarElement,
      Title,
      Tooltip,
      Legend,
      ArcElement
    );
    //this.obtain_relevant_invoices(1, 0);
    [
      {status: [1,2]},
      {paid_at: {
        min: (new Date(Date.now() - this.INVOICE_WINDOW_DAYS * 86400000)).toISOString(),
        max: (new Date(Date.now())).toISOString()
      }}
    ].forEach((params) => this.obtain_relevant_invoices(1000,0,params));
    setTimeout(() => {
      this.update_displayed_time();
    }, 1000*(61 - (new Date()).getSeconds()));
  }

  // update state, recursively call function, need to get rid of it upon window closure
  update_displayed_time(){
    this.setState({});
    if (document.getElementById('test-tgt-pyr-home') != null){
      setTimeout(() => {
        this.update_displayed_time();
      }, 60000);
    }
  }

  unique_invoice_values(invoices){
    const seen = new Set();
    return invoices.filter((invoice) => {
      const dup = seen.has(invoice.code);
      seen.add(invoice.code);
      return !dup;
    })
  }

  sort_invoices_by_date_key(invoices, key){
    return invoices.sort((a, b) => {
      if ((new Date(a[key])) < (new Date(b[key]))){
        return -1;
      }
      if ((new Date(a[key])) > (new Date(b[key]))){
        return 1;
      }
      return 0;
    });
  }

  obtain_relevant_invoices(limit, offset, params){
    fetch("/api/payors/searchinvoices", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        invoice: params,//{
          /*created_at: {
            min: (new Date(Date.now() - this.INVOICE_WINDOW_DAYS * 86400000)).toISOString(),
            max: (new Date(Date.now())).toISOString()
          },*/
          /*due_at: {
            min: (new Date(Date.now() - this.INVOICE_WINDOW_DAYS * 86400000)).toISOString(),
            max: (new Date(Date.now() + 100 * 365 * 86400000)).toISOString() // 100 years into future
          }*//*,
          status: [4]*/
        //},
        limit: limit,
        offset: offset,
        req_count: true
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log("Received for ");
      console.log(params);
      console.log(json.invoices);
      let invoices = this.state.invoices.concat(json.invoices);
      this.setState({
        invoices: this.sort_invoices_by_date_key(this.unique_invoice_values(invoices),'created_at'),
        init_loading: false
      }, () => {
        if (json.count > json.invoices.length){
          // deal with this
          this.obtain_relevant_invoices(limit, offset + limit, params);
        } else {
          this.filter_upcoming_invoices(this.UPCOMING_START_LIMIT);
          this.parse_graph_data();
        }
      });
    });
  }

  parse_graph_data(){
    console.log("Parsing graph data");
    const start_unix = Number(new Date(new Date().toISOString().split('T')[0]+'T00:00:00'+this.GMT_DIFF));
    const unix_limits = Array.from({length: 7}, (_,j) => j).map((v) => (start_unix - v * 86400000));
    let issued = Array.from({length: this.INVOICE_WINDOW_DAYS}, (_,j) => 0);
    let paid = Array.from({length: this.INVOICE_WINDOW_DAYS}, (_,j) => 0);
    let i;
    this.state.invoices.forEach(
      (invoice, index) => {
        for (i = 0 ; i < issued.length ; i++){
          if (Number(new Date(invoice.created_at)) > unix_limits[i]){
            issued[i] += invoice.amount;
            break;
          }
        }
        for (i = 0 ; i < paid.length ; i++){
          if (Number(new Date(invoice.paid_at)) > unix_limits[i]){
            paid[i] += invoice.amount;
            break;
          }
        }
      }
    );
    this.setState({
      issued_invoice_graph_data: {
        label: 'Issued Invoice value',
        data: issued.reverse().map((i) => i / 100.0),
        backgroundColor: 'rgba(54,162,235,0.2)',//'#6569b8','rgba(54,162,235,0.2)'
        borderColor: 'rgba(54,162,235)',//'#040dba','rgba(54,162,235)'
        borderWidth: 1
      },
      paid_invoice_graph_data: {
        label: 'Paid Invoice value',
        data: paid.reverse().map((i) => i / 100.0),
        backgroundColor: 'rgba(75,192,192,0.2)',//'#7ebfbc',
        borderColor: 'rgba(75,192,192)',//'#32bfb8',
        borderWidth: 1
      }
    });
  }

  filter_upcoming_invoices(limit){
    /*let invoices = this.state.invoices.filter((i,j) => i.status <= 2 || true);
    if (invoices.length > limit){
      this.setState({
        upcoming_invoices: invoices.slice(0,limit).map((i) ({...{due_at_int: Integer(new Date(i.due_at))},...i})),
        can_expand_upcoming_invoices: true,
        upcoming_invoices_limit: limit
      });
    } else {
      this.setState({
        upcoming_invoices: invoices,
        can_expand_upcoming_invoices: false,
        upcoming_invoices_limit: limit
      });
    }*/
    let invoices = this.state.invoices.filter((i,j) => i.status <= 2);
    this.setState({
      upcoming_invoices: this.sort_invoices_by_date_key(invoices, 'due_at').slice(0,limit), // can just call .reverse() on sort_invoices_by_date_key if not right order
      can_expand_upcoming_invoices: invoices.length > limit,
      upcoming_invoices_limit: limit
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  go_to_invoice(invoice){
    if (invoice.passkey === null){
      location.href = "/invoice/"+invoice.code;
    } else {
      location.href = "/invoice/"+invoice.code+"?pk="+invoice.passkey;
    }
  }

  render () {
    console.log(this.state);
    return (
      <div>

        <div id="test-tgt-pyr-home"></div>

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td>

                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'start', alignItems: 'start'}}>
          <div style={{width: '100%'}}>
            <div style={{maxWidth: '800px', textAlign: 'center'}}>
              <h2>
                Good {Number(String(new Date()).slice(16,18)) < 12 ? "morning" : (Number(String(new Date()).slice(16,18)) < 18 ? "afternoon" : "evening")}, Parris
              </h2>
              <h2 style={{color: "grey"}}>
                {Number(String(new Date()).slice(16,18)) > 12 ? Number(String(new Date()).slice(16,18)) - 12 : Number(String(new Date()).slice(16,18))}:{String(new Date()).slice(19,21)} {Number(String(new Date()).slice(16,18)) >= 12 ? "PM" : "AM"}
              </h2>
              <h2 style={{color: "grey"}}>
                {String(new Date()).slice(0,15)}
              </h2>
              <h3 style={{color: "grey"}}>
                {String((new Date())).split('(')[1].split(')')[0]} ({String(new Date()).split(' ')[5]})
              </h3>

              
            </div>

            <div style={{maxWidth: '760px', textAlign: 'center', paddingRight: '40px'}}>
              <div className="form-section" style={{textAlign: 'center', width: '100%', margin: '10px'}}>
                <div style={{color: 'grey', fontSize: '18px', fontWeight: 'bold'}}>
                  Past 7 days
                </div>
                <div style={{width: '380px', margin: 'auto'}}>
                  {this.state.issued_invoice_graph_data === null ? null :
                    <Bar options={this.state.chart_options} data={{datasets: ['issued_invoice_graph_data','paid_invoice_graph_data'].map((n) => this.state[n]).filter((v) => ![null,undefined].includes(v)), labels: this.state.labels}} />}
                </div>
              </div>
            </div>


          </div>

          <div>

          {/*<div className="form-section" style={{width: '460px', margin: '10px'}}>
            <div style={{width: '380px', margin: 'auto'}}>
              <Bar data={{datasets: ['issued_invoice_graph_data','paid_invoice_graph_data'].map((n) => this.state[n]).filter((v) => ![null,undefined].includes(v)), labels: this.state.labels}} />
            </div>
          </div>*/}

          <div className="form-section" style={{height: '670px', width: '460px', margin: '10px'}}>
            <h2>
              Upcoming bills
            </h2>
            {this.state.init_loading ? 
              <div>Loading...</div> : 
              <div>
                {this.state.upcoming_invoices.length === 0 ? 
                  <p style={{color: 'grey', textAlign: 'center'}}>
                    You have no upcoming bills.
                  </p> : null}
                {this.state.upcoming_invoices.map(
                  (invoice, index) =>
                  <div key={index} className="payor-invoice-card" onClick={() => this.go_to_invoice(invoice)}>
                    <table style={{width: '100%'}}><tbody>
                      <tr>
                        <td style={{width: '92px'}}>
                          {/*<div style={{backgroundColor: this.INVOICE_STATUS_TO_COLOUR[String(invoice.status)], color: '#fff', padding: '3px', fontSize: '13px', borderRadius: '4px', width: '80px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', margin: 'auto', fontFamily: 'Roboto Mono'}}>
                            {this.INVOICE_STATUS_TO_WORD[String(invoice.status)]}
                          </div>*/}
                          <div style={{padding: '10px'}}>
                            <CalendarDate value={invoice.due_at} />
                          </div>
                        </td>
                        <td style={{verticalAlign: 'top'}}>
                          <table style={{width: '100%'}}><tbody>
                            <tr>
                              <td>
                                {invoice.title}
                              </td>
                              <td style={{textAlign: 'right', color: 'grey'}}>
                                ${this.amount_to_formatted_string(invoice.amount)}
                              </td>
                            </tr>
                          </tbody></table>

                          <div style={{fontSize: '14px', color: 'grey', fontFamily: 'Roboto Mono'}}>
                            {invoice.company_name}
                          </div>
                          <table style={{color: 'grey'}}><tbody>
                            <tr style={{fontFamily: "Roboto Mono", fontSize: '12px'}}>
                              <td style={{color: invoice.status === 1 && invoice.has_due_date && Number(new Date(invoice.due_at)) < Number(new Date()) ? "red" : "black", paddingRight: '20px'}}>
                                {invoice.has_due_date ? "Due at "+(String(new Date(invoice.due_at)).slice(4,15)) : "No due date"}
                              </td>
                              <td>
                                {"Accrued at " + String(new Date(invoice.date_accrued_at)).slice(4,15)}
                              </td>
                            </tr>
                          </tbody></table>

                        </td>
                      </tr>
                    </tbody></table>
                    
                  </div>
                )}
              </div>}
          </div>

          </div>

          <div className="form-section" style={{width: '240px', height: '240px', margin: '10px'}}>
            <h2>
              At a glance
            </h2>
            <p style={{textAlign: 'center', color: 'grey', textWeight: 'bold', marginBottom: '-8px'}}>
              Total due
            </p>
            <h2 style={{textAlign: 'center'}}>
              ${this.amount_to_formatted_string(this.state.invoices.filter((i) => i.status === 1).reduce((a,c) => c.amount + a, 0))}
            </h2>
            <p style={{textAlign: 'center', color: 'grey', textWeight: 'bold', marginBottom: '-8px'}}>
              Due in next 7 days
            </p>
            <h2 style={{textAlign: 'center'}}>
              ${this.amount_to_formatted_string(this.state.invoices.filter((i) => i.status === 1 && i.has_due_date && (new Date(i.due_at)) - (new Date()) < 604800000).reduce((a,c) => c.amount + a, 0))}
            </h2>
            <p style={{textAlign: 'center', color: 'grey', textWeight: 'bold', marginBottom: '-8px'}}>
              Paid last 7 days
            </p>
            <h2 style={{textAlign: 'center'}}>
              ${this.amount_to_formatted_string(this.state.invoices.filter((i) => i.paid_at !== null && (new Date()) - (new Date(i.paid_at)) < 604800000).reduce((a,c) => c.amount + a, 0))}
            </h2>
          </div>
        </div>
      </div>
    );
  }
}

export default PayorHome
