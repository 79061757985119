import React from "react"
import PropTypes from "prop-types"
import Checkbox from "./Checkbox.js"
class PayorAccountComms extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      data: null,
      loaded: false,
      changed: false,
      comms_pref_dues: null,
      comms_preferences: null,
      messages: [],
      saving: false
		};
    
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.change_comms_pref_dues = this.change_comms_pref_dues.bind(this);
    this.save_changes = this.save_changes.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  componentDidMount(){
    if (![null,undefined].includes(this.props.data)){
      this.setState({
        data: this.props.data,
        loaded: true,
        comms_pref_dues: this.props.data.comms_pref_dues,
        comms_preferences: this.props.data.comms_preferences
      });
    } else {
      this.setState({
        loaded: false
      });
    }
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  save_changes(){
    this.setState({
      saving: true
    });
    fetch("/api/payor", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({payor: {comms_preferences: this.state.comms_preferences, comms_pref_dues: this.state.comms_pref_dues}})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.setState({
        saving: false
      });
      this.add_message("Failed to save changes.",'danger');
    }).then(json => {
      this.setState({
        saving: false
      });
      this.add_message("Successfully updated.",'success');
      this.props.on_successful_update('info');
    });
  }

  change_comms_pref_dues(v){
    const due_prefs_phrase = "DUE";
    let comms_preferences = [null,undefined].includes(this.state.comms_preferences) ? "" : this.state.comms_preferences;
    if (v){
      if (comms_preferences.includes(due_prefs_phrase)){
        comms_preferences.push(due_prefs_phrase);
        comms_preferences.join('-');
      }
      this.setState({
        changed: true,
        comms_preferences: comms_preferences,
        comms_pref_dues: true
      });
    } else {
      comms_preferences = comms_preferences.split(due_prefs_phrase).join('');
      this.setState({
        changed: true,
        comms_preferences: comms_preferences,
        comms_pref_dues: false
      });
    }
  }

  componentDidUpdate(prev_props){
    if (prev_props.data !== this.props.data){
      this.componentDidMount();
    }
  }

  render () {
    return (this.state.loaded ? 
      <div>
        
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <table className="acct-settings-table"><tbody>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.change_comms_pref_dues(v)} init_value={this.state.comms_pref_dues} />
            </td>
            <td>
              <div>
                Receive alerts on upcoming due Invoices.
              </div>
            </td>
          </tr>
        </tbody></table>
        <br />
        {this.state.changed ? 
          <button className="std-button" onClick={this.save_changes}>
            {this.state.saving ? "Saving..." : "Save"}
          </button> : null}
      </div>
    : <div></div>);
  }
}

export default PayorAccountComms
