import React from "react"
import PropTypes from "prop-types"
import Login from "./Login.js"
import LoginPayor from "./LoginPayor.js"
class LoginChoice extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      login_type: (this.props.login_type === null ? 0 : Number(this.props.login_type))
    };
    
    /*this.MESSAGE_TYPE_TO_COLOUR = {'danger':'red','success':'green','info':'blue'};
    this.MESSAGE_TYPE_TO_ICON = {'danger':'circle-exclamation','warning':'circle-exclamation','info':'circle-info','success':'check'}*/

    this.COMPONENT_WIDTH = 360;
    this.NUM_OPTIONS = 2

  }

  render () {
    console.log('login state');
    console.log(this.state);
    return (
      <div style={{width: this.COMPONENT_WIDTH+'px'}}>
        <div style={{marginBottom: '15px'}}>
          <table className="payment-header"><tbody>
            <tr>
              <td onClick={() => this.setState({login_type: 0})} style={{color: this.state.login_type === 0 ? '#040dba' : 'grey'}}>
                Login as Payor
              </td>
              <td onClick={() => this.setState({login_type: 1})} style={{color: this.state.login_type === 1 ? '#040dba' : 'grey'}}>
                Login as Merchant
              </td>
            </tr>
          </tbody></table>
          <div className="payment-slider" style={{borderTop: 'solid', borderColor: '#040dba', width: (this.COMPONENT_WIDTH / this.NUM_OPTIONS)+'px', borderWidth: '3px', marginLeft: this.state.login_type * (this.COMPONENT_WIDTH / this.NUM_OPTIONS) + 'px'}} />
        </div>

        {[
          <LoginPayor auth_token={this.props.auth_token} continue_url={this.props.continue_url} />,
          <Login auth_token={this.props.auth_token} continue_url={this.props.continue_url} />
        ][this.state.login_type]}
      </div>
    );
  }
}

export default LoginChoice
