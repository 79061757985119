import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"
import MyInput from "./MyInput.js"
import TextBox from "./TextBox.js"
class ApikeyManager extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      apikeys: [],
      init_loaded: false,
      typed_name: "",
      typed_name_status: 0,
      typed_name_message: "",
      show_new_apikey: false,
      loading: false,
      show_new_apikey_result: false,
      new_apikey: null,
      messages: [],
      show_delete: false,
      delete_typed: "",
      delete_typed_status: 0,
      selected_index: -1
    };

    this.on_type_name = this.on_type_name.bind(this);
    this.on_blur_name = this.on_blur_name.bind(this);
    this.create_apikey = this.create_apikey.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.i_have_copied_it = this.i_have_copied_it.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  componentDidMount(){
    // /api/apikeys
    fetch("/api/apikeys", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        apikeys: json.apikeys,
        init_loaded: true
      });
    });
  }

  i_have_copied_it(){
    fetch("/api/apikeys", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        apikeys: json.apikeys,
        init_loaded: true,
        show_new_apikey_result: false
      });
    });
  }

  on_type_name(e){
    const typed = e.target.value;
    if (/^[A-Za-z0-9_-]{0,32}$/.test(typed)){
      this.setState({
        typed_name: e.target.value
      });
    }
  }

  on_blur_name(){
    if (/^[A-Za-z0-9_-]{5,32}$/.test(this.state.typed_name)){
      if (this.state.apikeys.filter((k) => k.name === this.state.typed_name).length === 0){
        this.setState({
          typed_name_status: 2,
          typed_name_message: ""
        });
      } else {
        this.setState({
          typed_name_status: 3,
          typed_name_message: "Cannot be the name of another key."
        })
      }
    } else {
      this.setState({
        typed_name_status: 3,
        typed_name_message: "Invalid character pattern."
      });
    }
  }

  create_apikey(){
    //Judge Knapp
    this.setState({
      loading: true
    });
    fetch("/api/apikeys", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        apikey: {
          name: this.state.typed_name
        }
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.setState({
          show_new_apikey_result: true,
          new_apikey: json.apikey,
          show_new_apikey: false,
          loading: false
        });
      }
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  on_delete_apikey(code){
    // /api/apikey/:code
    fetch("/api/apikey/"+code, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }/*,
      body: JSON.stringify({
        apikey: {
          name: this.state.typed_name
        }
      })*/
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Error in deleting API key.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        let apikeys = this.state.apikeys;
        this.setState({
          apikeys: apikeys.filter((k) => k.client_code != code),
          show_delete: false
        });
        this.add_message("Successfully deleted API key "+code,'success');
      } else {
        this.add_message("Failed to delete API key",'danger');
      }
    });
  }

  render () {
    return (
      <div className="form-section">

        {this.state.show_new_apikey ? 
          <Overlay on_cancel={() => this.setState({show_new_apikey: false})} title="Create new API key">
            <div>
              <div style={{fontSize: '14px', color: 'grey', marginBottom: '10px'}}>
                Type your API key name. It must be 5-30 characters, and either A-Z (either case), 0-9, underscore or dash.
              </div>
              <MyInput onChange={this.on_type_name} value={this.state.typed_name} status={this.state.typed_name_status} onBlur={this.on_blur_name} note={this.state.typed_name_message} style={{fontFamily: 'Roboto Mono'}} label="API Key Name" />
              <br />
              {this.state.typed_name_status === 2 ? 
                <button onClick={() => this.state.loading ? null : this.create_apikey()} className="std-button">
                  {this.state.loading ? "Creating..." : "Create"}
                </button> : null}
            </div>
          </Overlay> : null}
        
        {this.state.show_delete ? 
          <Overlay on_cancel={() => this.setState({show_delete: false})} title="Delete API key">
            <div>
              <div>
                Are you sure you want to delete this key? To do so, type the key name {this.state.apikeys[this.state.selected_index].name} in the box below.
              </div>
              <br />
              <MyInput onChange={(e) => this.setState({delete_typed: e.target.value})} value={this.state.delete_typed} status={this.state.delete_typed_status} onBlur={() => this.setState({delete_typed_status: this.state.delete_typed === this.state.apikeys[this.state.selected_index].name ? 2 : 3})} style={{fontFamily: 'Roboto Mono'}} label="API Key Name" />
              <br />
              {this.state.delete_typed_status === 2 ? 
                <button className="std-button" style={{backgroundColor: 'red'}} onClick={() => this.on_delete_apikey(this.state.apikeys[this.state.selected_index].client_code)}>
                  Confirm Delete
                </button> : null}
              <button onClick={() => this.setState({show_delete: false})} className="std-button">
                Cancel
              </button>
            </div>
          </Overlay> : null}
        
        {this.state.show_new_apikey_result ? 
          <Overlay on_cancel={() => this.setState({show_new_apikey: false})} title="New API Key" width="550px">
            <div>
              <div className={"flash flash-warning"}>
                <table><tbody>
                  <tr>
                    <td style={{fontSize: '18px'}}>
                      <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON['warning']}></i>
                    </td>
                    <td>
                      Copy this key immediately in a secure location. For security reasons, we cannot show you this key again.
                    </td>
                  </tr>
                </tbody></table>
              </div>
              <TextBox title="Name" content={this.state.new_apikey.name} style={{minHeight: '40px'}} />
              <TextBox title="Key" content={this.state.new_apikey.key} />
              <br />
              <button onClick={this.i_have_copied_it} className="std-button">
                I have copied it
              </button>
            </div>
          </Overlay> : null}
        
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <h2>
          API Keys
        </h2>
        {this.state.apikeys.length > 0 ? 
          this.state.apikeys.map(
            (apikey, index) => 
              <div key={index} style={{border: 'solid', borderRadius: '6px', borderWidth: '1px', borderColor: '#c9c9c9', margin: '4px', padding: '20px', cursor: 'pointer'}}>
                <div style={{fontSize: '20px', fontWeight: 'bold'}}>
                  {apikey.name}
                </div>
                <table className="apikey-table"><tbody>
                  <tr>
                    <td>
                      Client code
                    </td>
                    <td>
                      {apikey.client_code}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Name
                    </td>
                    <td>
                      {apikey.name}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Active?
                    </td>
                    <td>
                      {apikey.active ? "Active" : "Not active"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Key starts with
                    </td>
                    <td>
                      {apikey.starts_with}...
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Created at
                    </td>
                    <td>
                      {(new Date(apikey.created_at)).toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Expires at
                    </td>
                    <td>
                      {(new Date(apikey.expires_at)).toLocaleString()}
                    </td>
                  </tr>
                </tbody></table>
                <button onClick={() => this.setState({selected_index: index, show_delete: true, delete_typed: ""})} className="std-button" style={{backgroundColor: 'red'}}>
                  Delete
                </button>
              </div>
          ) : 
          <div style={{fontSize: '14px', padding: '10px', color: 'grey'}}>
            There are currently no API keys.
          </div>}
        <button className="std-button" onClick={() => this.setState({show_new_apikey: true})}>
          Create Key
        </button>
      </div>
    );
  }
}

export default ApikeyManager
