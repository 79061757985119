import React from "react"
import PropTypes from "prop-types"
import MbOverlay from "./MbOverlay.js"
import MbInput from "./MbInput.js"
class MbOpenBankAccount extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      show_page: false,
      tags: [],
      tag2banks: {},
      banks: {
        'cba':{
          name: 'Commonwealth Bank of Australia',
          shortname: 'Commonwealth Bank',
          tags: ['commonwealth','bank','australia','commbank','cba'],
          url: 'https://www.my.commbank.com.au/netbank/Logon/Logon.aspx',
          icon: 'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/bank_logos/cba.jpg',
          app: 'cba://'
        },
        'westpac':{
          name: 'Westpac Banking Corporation',
          shortname: 'Westpac',
          tags: ['westpac','wbc'],
          url: 'https://banking.westpac.com.au/wbc/banking/handler?TAM_OP=login&segment=personal&logout=false',
          icon: 'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/bank_logos/westpac.png',
          app: 'westpac://'
        },
        'anz':{
          name: 'Australian and New Zealand Banking Group',
          shortname: 'ANZ',
          tags: ['anz','australian','new zealand','banking'],
          url: 'https://login.anz.com/internetbanking',
          icon: 'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/bank_logos/anz.jpg',
          app: 'anz://'
        },
        'nab':{
          name: 'National Australia Bank',
          shortname: 'NAB',
          tags: ['nab','national','australia','bank'],
          url: 'https://ib.nab.com.au/login',
          icon: 'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/bank_logos/nab.jpg',
          app: 'nab://'
        }
      },
      results: [],
      typed: "",
      show_confirm: false,
      selected_bank: null
		};

    this.handle_type_query = this.handle_type_query.bind(this);
    this.handle_key_down = this.handle_key_down.bind(this);
    this.open_app = this.open_app.bind(this);

  }

  componentDidMount(){
    let tag2banks = {};
    let i;
    for (const [key,val] of Object.entries(this.state.banks)){
      val.tags.forEach(
        (tag) => {
          if (tag in tag2banks){
            tag2banks[tag].push(key);
          } else {
            tag2banks[tag] = [key];
          }
      });
    }
    this.setState({
      tag2banks: tag2banks,
      results: Object.keys(this.state.banks),
      tags: Object.keys(tag2banks)
    });
  }

  handle_type_query(e){
    const typed = e.target.value.toLowerCase();
    if (typed.trim().length === 0){
      return this.setState({
        results: Object.keys(this.state.banks)
      });
    }
    const words = typed.split(' ').filter((w) => w.length > 0);
    let results = [];
    let i, j;
    let flag;
    for (const [key,bank] of Object.entries(this.state.banks)){
      flag = false;
      for (i = 0 ; i < words.length ; i++){
        for (j = 0 ; j < bank.tags.length ; j++){
          if (bank.tags[j].slice(0,words[i].length) === words[i]){
            results.push(key);
            flag = true;
          }
          if (flag){
            break;
          }
        }
        if (flag){
          break;
        }
      }
    }
    this.setState({
      results: results
    });
  }

  handle_key_down(e){
    if (e.keyCode === 13 && this.state.results.length === 1){
      window.open(this.state.banks[this.state.results[0]].url,'_blank');
    }
  }

  open_app(){
    window.location = this.state.selected_bank.app;
  }

  render () {
    console.log(this.state);
    return (
      <div>

        {this.state.show_page ? 
          <MbOverlay on_cancel={() => this.setState({show_page: false, show_confirm: false, selected_bank: null, results: Object.keys(this.state.banks)})} title="Select your bank">
            {this.state.show_confirm ? 
              <div>
                <div style={{textAlign: 'center'}}>
                  <img src={this.state.selected_bank.icon} style={{height: '5em', width: '5em', borderRadius: '0.3em'}} />
                  <h3>
                    {this.state.selected_bank.name}
                  </h3>
                  <table style={{width: '100%', marginTop: '2em', marginBottom: '2em'}}><tbody>
                    <tr>
                      <td>
                        <div style={{width: '100%'}} onClick={this.open_app}>
                          <i className="fa-solid fa-mobile" style={{fontSize: '4em', color: '#040dba'}}></i>
                          <h4>
                            Open App
                          </h4>
                        </div>
                      </td>
                      <td>
                        <div style={{width: '100%'}} onClick={() => {
                          window.open(
                            this.state.selected_bank.url, '_blank'
                          );
                        }}>
                          <i className="fa-solid fa-laptop" style={{fontSize: '4em', color: '#040dba'}}></i>
                          <h4>
                            Open Web
                          </h4>
                        </div>
                      </td>
                    </tr>
                  </tbody></table>
                  <button onClick={() => this.setState({show_confirm: false, selected_bank: null, results: Object.keys(this.state.banks)})} className="mb-button">
                    Back
                  </button>
                </div>
              </div> : 
              <div>
                <div className="myinput-wrapper-centre">
                  <MbInput onChange={this.handle_type_query} onKeyDown={this.handle_key_down} status={1} label="Your bank" id="open-bank-input" />
                </div>
                <div style={{margin: 'auto', height: '500px',overflow: 'hidden'}}>
                  {this.state.results.map(
                    (bank, index) =>
                    <div key={index} className="mb-openbank-result" onClick={() => {
                      this.setState({
                        show_confirm: true,
                        selected_bank: this.state.banks[bank]
                      });
                    }}>
                      <table><tbody>
                        <tr>
                          <td>
                            {this.state.banks[bank].icon === null ? null : <img src={this.state.banks[bank].icon} />}
                          </td>
                          <td>
                            {this.state.banks[bank].shortname}
                          </td>
                        </tr>
                      </tbody></table>
                    </div>
                  )}
              </div>
            </div>}
          </MbOverlay> : null}

        <button className="mb-button" onClick={() => this.setState({show_page: true}, () => setTimeout(() => {document.getElementById('open-bank-input').focus()},250))}>
          <i className="fa-solid fa-building-columns"></i> Open Bank Account
        </button>
      </div>
    );
  }
}

export default MbOpenBankAccount
