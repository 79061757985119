import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
import Toggle from "./Toggle.js"
import Checkbox from "./Checkbox.js"
class InvoiceSearch extends React.Component {
  constructor(props){
    super(props);
    this.RESULTS_PER_PAGE = 5;
    this.state = {
      include_amount: false,
      amount_max: 100000,
      displayed_amount_max: '$1000.00',
      amount_min: 500,
      include_code: false,
      code: "",
      include_custom_code: false,
      custom_code: "",
      displayed_amount_min: '$5.00',
      include_created_at: true,
      created_at_max: Math.round((Date.now() - Date.now() % 86400000 + 86400000 * 1) / 1000),
      displayed_created_at_max: new Date(Date.now() - Date.now() % 86400000 + 86400000 * 1).toISOString().slice(0,10),
      created_at_min: Math.round((Date.now() - Date.now() % 86400000 - 86400000 * 7) / 1000),
      displayed_created_at_min: new Date(Date.now() - Date.now() % 86400000 - 86400000 * 7).toISOString().slice(0,10),
      include_billing_email: false,
      billing_email: "",
      include_status: false,
      status: [],
      show_results: false,
      results: [],
      include_due_at: false,
      due_at_min: Math.round((Date.now() - Date.now() % 86400000) / 1000),
      displayed_due_at_min: new Date(Date.now() - Date.now() % 86400000).toISOString().slice(0,10),
      due_at_max: Math.round((Date.now() - Date.now() % 86400000 + 86400000 * 7) / 1000),
      displayed_due_at_max: new Date(Date.now() - Date.now() % 86400000 + 86400000 * 7).toISOString().slice(0,10),
      include_tags: false,
      typed_tag: "",
      tags: [],
      //offset: 0,
      limit: this.RESULTS_PER_PAGE,
      total_count: null,
      results_per_page: this.RESULTS_PER_PAGE, // should be the same as limit
      max_num_pages: 5, // was 10, changed to 5 bc I think it needs to be odd (ow 1.5, 2.5 etc.)
      num_pages: null,
      showing_page: 0
    };

    this.on_change_created_at_max = this.on_change_created_at_max.bind(this);
    this.on_change_created_at_min = this.on_change_created_at_min.bind(this);
    this.on_change_amount_max = this.on_change_amount_max.bind(this);
    this.on_change_amount_min = this.on_change_amount_min.bind(this);
    this.is_valid_amount_input = this.is_valid_amount_input.bind(this);
    this.on_blur_amount_min = this.on_blur_amount_min.bind(this);
    this.on_blur_amount_max = this.on_blur_amount_max.bind(this);
    this.on_focus_amount_min = this.on_focus_amount_min.bind(this);
    this.on_focus_amount_max = this.on_focus_amount_max.bind(this);
    this.on_change_due_at_min = this.on_change_due_at_min.bind(this);
    this.on_change_due_at_max = this.on_change_due_at_max.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.search_to_csv = this.search_to_csv.bind(this);
    this.save_csv_file = this.save_csv_file.bind(this);
    this.as_csv = this.as_csv.bind(this);
    //this.show_page = this.show_page.bind(this);
    this.search = this.search.bind(this);

    this.BILLING_EMAIL_CHAR_LIMIT = 240;
    this.INVOICE_STATUSES = [1,2,3,4,5,6];
    this.INVOICE_STATUS_TO_NAME = {'1':'Unpaid','2':'Partially paid','3':'Suspended','4':'Paid','5':'Excess paid','6':'Paid by other source'};
    this.MIN_TAG_LENGTH = 4;

    this.INVOICE_STATUS_TO_WORD = {'0':'INITIATE','1':'UNPAID','2':'PART PAID','3':'SUSPEND','4':'PAID','5':'PAID','6':'PAID'};
    this.INVOICE_STATUS_TO_TITLE = {'0':'Invoice Initiated','1':'Invoice Unpaid','2':'Invoice Partly Paid','3':'Invoice Suspended by User','4':'Invoice Paid','5':'Invoice Paid in Excess','6':'Invoice Paid by Other Source'};
    this.INVOICE_STATUS_TO_DESCRIPTION = {'0':'Invoice has been initiated but has not been opened yet to payment.',
      '1':'Invoice is unpaid and is awaiting payment.',
      '2':'Invoice has been partly paid, and is awaiting payment of the balance.',
      '3':'Invoice has been suspended by you and is not visible except to you.',
      '4':'Invoice has been paid.',
      '5':'Invoice has been paid in excess of what was owed.',
      '6':'Invoice has been paid by another source, not through PaySolve.'};
    this.INVOICE_STATUS_TO_COLOUR = {'0':'#040dba','1':'#040dba','2':'#e6a330','3':'#e6a330','4':'#32bfb8','5':'#32bfb8','6':'#32bfb8'};
    this.INVOICE_STATUS_TO_ADMIN_ACTION = {'1':'OPEN','2':'OPEN','3':'SUSPEND','6':'SET_AS_COMPLETE'};

  }

  on_change_created_at_max(e){
    this.setState({
      displayed_created_at_max: e.target.value,
      created_at_max: Math.round((new Date(e.target.value)).getTime() / 1000)
    });
  }

  on_change_created_at_min(e){
    this.setState({
      displayed_created_at_min: e.target.value,
      created_at_min: Math.round((new Date(e.target.value)).getTime() / 1000)
    });
  }

  on_change_due_at_min(e){
    this.setState({
      displayed_due_at_min: e.target.value,
      due_at_min: Math.round((new Date(e.target.value)).getTime() / 1000)
    });
  }

  on_change_due_at_max(e){
    this.setState({
      displayed_due_at_max: e.target.value,
      due_at_max: Math.round((new Date(e.target.value)).getTime() / 1000)
    });
  }

  on_change_amount_max(e){
    const typed = e.target.value;
    if (typed === '.' || this.is_valid_amount_input(typed) || typed.length === 0){
      let amount = typed.split('.').map((x,i) => i === 0 ? Number(x) * 100 : Number(x[0])*10+(x[1]===undefined ? 0 : Number(x[1]))).reduce((p,a) => p+a, 0);
      if (typed === '.'){
        amount = 0;
      }
      this.setState({
        amount_max: amount,
        displayed_amount_max: typed
      });
    }
  }

  on_change_amount_min(e){
    const typed = e.target.value;
    if (typed === '.' || this.is_valid_amount_input(typed) || typed.length === 0){
      let amount = typed.split('.').map((x,i) => i === 0 ? Number(x) * 100 : Number(x[0])*10+(x[1]===undefined ? 0 : Number(x[1]))).reduce((p,a) => p+a, 0);
      if (typed === '.'){
        amount = 0;
      }
      this.setState({
        amount_min: amount,
        displayed_amount_min: typed
      });
    }
  }

  is_valid_amount_input(input) {
    console.log("testing "+input);
    if (input === "." || input === "") {
        return true;
    }
    const parts = input.split(".");
    if (parts.length === 1){
      return !isNaN(parts[0]);
    } else if (parts.length > 2){
      return false;
    }
    return !isNaN(parts[0]) && !isNaN(parts[1]) && parts[1].length <= 2;
  }

  on_blur_amount_min(){
    this.setState({
      displayed_amount_min: '$'+this.amount_to_formatted_string(this.state.amount_min)
    });
  }

  on_blur_amount_max(){
    this.setState({
      displayed_amount_max: '$'+this.amount_to_formatted_string(this.state.amount_max)
    });
  }

  on_focus_amount_min(e){
    this.setState({
      displayed_amount_min: this.state.displayed_amount_min.replaceAll('$','').replaceAll(',','')
    }, () => {
      //document.getElementById('amount-min-input').select();
      e.target.select();
    });
    setTimeout(() => {
      e.target.select();
      //document.getElementById('amount-min-input').select();
    }, 50);
  }

  on_focus_amount_max(e){
    this.setState({
      displayed_amount_max: this.state.displayed_amount_max.replaceAll('$','').replaceAll(',','')
    }, () => {
      e.target.select();
    });
    setTimeout(() => {
      e.target.select();
    }, 50);
  }

  search_to_csv(){
    if (this.state.num_pages === 1){
      return this.as_csv(this.state.results);
    }
    let body = {invoice:{},req_count:false};
    ['amount','created_at','due_at'].forEach((k,i) => this.state['include_'+k] ? body['invoice'][k] = {min:this.state[k+'_min'],max:this.state[k+'_max']} : null);
    ['billing_email','status','custom_code'].forEach((k,i) => this.state['include_'+k] ? body['invoice'][k] = this.state[k] : null);
    body['tags'] = this.state.include_tags ? this.state.tags : [];
    fetch("/api/invoices/search", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      this.as_csv(json.invoices);
    });
  }

  as_csv(results){
    let i, j, row;
    let keys = ['code','billing_email','title','amount','amount_paid','paid','paid_at','date_accrued_at','due_at','created_at','bsb','account_number','account_name','main_payid'];
    let output = ['Code,Billing Email,Title,Amount,Amount Paid,Paid?,Paid at,Date accrued at,Due at,Created at','Account BSB','Account number','Account name','Account PayID'];
    for (i = 0 ; i < results.length ; i++){
      row = []
      for (j = 0 ; j < keys.length ; j++){
        if (['title'].includes(keys[j])){
          row.push('"'+String(results[i][keys[j]])+'"');
        } else if (['amount','amount_paid'].includes(keys[j])){
          row.push(String(Number(results[i][keys[j]])/100.0));
        } else if (['paid_at','due_at','created_at'].includes(keys[j])){
          t = new Date(results[i][keys[j]]);
          if (results[i][keys[j]] === null || t === "Invalid Date"){
            row.push('');
          } else {
            row.push(t.toLocaleDateString()+' '+t.toLocaleTimeString());
          }
        } else {
          row.push(String(results[i][keys[j]]));
        }
      }
      /*for (const key in keys){
        console.log(key);
        if (['title','description'].includes(key)){
          row.push('"'+String(result[key])+'"');
        } else if (['amount','amount_paid'].includes(key)){
          row.push(String(Number(result[key])/100.0));
        } else {
          row.push(String(result[key]));
        }
      }*/
      output.push(row.join(','));
    }
    /*let encoded_uri = encodeURI("data:text/csv;charset=utf-8,"+output.join("\n"));
    let link = document.createElement('a');
    link.setAttribute("href", encoded_uri);
    link.setAttribute("download",(new Date()).toISOString().slice(0,10).split('-').join('')+String(Math.round((Math.random(0,1000000)+0.1)*1000000)));
    document.body.appendChild(link);
    link.click();*/
    /*console.log("Navigator");
    console.log(navigator);*/
    this.save_csv_file((new Date()).toISOString().slice(0,10).split('-').join('')+String(Math.round((Math.random(0,1000000)+0.1)*1000000))+'.csv', "data:attachment/text", output.join("\n"));
    //return output.join("\n");
  }

  save_csv_file(name, type, data) {
    if (data !== null && navigator.msSaveBlob)
        return navigator.msSaveBlob(new Blob([data], { type: type }), name);
    let a = document.createElement('a');
    let url = window.URL.createObjectURL(new Blob([data], {type: type}));
    a.setAttribute("href", url);
    a.setAttribute("download", name);
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  search(page,limit,req_count){
    console.log("page = "+String(page));
    let body = {invoice:{},offset:page*this.state.results_per_page,limit:limit,req_count:req_count};
    /*
      For each search term in state, only include it in the body if state indicates its inclusion. Separate by whether it has min/max or exact value.
    */
    // min/max keys:
    ['amount','created_at','due_at'].forEach((k,i) => this.state['include_'+k] ? body['invoice'][k] = {min:this.state[k+'_min'],max:this.state[k+'_max']} : null);
    // exact keys:
    ['billing_email','status','code','custom_code'].forEach((k,i) => this.state['include_'+k] ? body['invoice'][k] = this.state[k] : null);
    /*if (Object.keys(body['invoice']).length === 0){
      body['invoice']['amount'] = {min:100,max:1000000000};
    }*/
    body['tags'] = this.state.include_tags ? this.state.tags : [];
    fetch("/api/invoices/search", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.setState({
        show_results: true,
        results: json.invoices,
        total_count: json.count === null ? this.state.total_count : json.count,
        num_pages: Math.ceil((json.count === null ? this.state.total_count : json.count) / this.state.results_per_page),
        showing_page: page
      });
    });
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }
  
  render () {
    console.log(this.state);
    return (
      <div className="form-section" style={{width: '800px'}}>
        <h2>
          Search Invoices by
        </h2>

        <table id="object-search-table"><tbody>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_created_at: v})} init_value={this.state.include_created_at} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Date created at
              </div>
              {this.state.include_created_at ? 
                <div style={{paddingTop: '15px'}}>
                  <MyInput type="date" onChange={this.on_change_created_at_min} value={this.state.displayed_created_at_min} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="Earliest date" note={null} /><br />
                  <MyInput type="date" onChange={this.on_change_created_at_max} value={this.state.displayed_created_at_max} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="Latest date" note={null} /><br />
                </div> : null}
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_code: v})} init_value={this.state.include_code} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Code
              </div>
              {this.state.include_code ? 
                <div style={{paddingTop: '15px'}}>
                  <MyInput onChange={(e) => this.setState({code: e.target.value})} value={this.state.code} onBlur={null} onFocus={null} status={0} note={null} label="Invoice code" style={{textAlign: 'left'}} />
                </div> : null}
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_custom_code: v})} init_value={this.state.include_custom_code} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Custom Code
              </div>
              {this.state.include_custom_code ? 
                <div style={{paddingTop: '15px'}}>
                  <MyInput onChange={(e) => this.setState({custom_code: e.target.value})} value={this.state.custom_code} onBlur={null} onFocus={null} status={0} note={null} label="Invoice code" style={{textAlign: 'left'}} />
                </div> : null}
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_amount: v})} init_value={this.state.include_amount} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Amount
              </div>
              {this.state.include_amount ? 
                <div style={{paddingTop: '15px'}}>
                  <MyInput onChange={this.on_change_amount_min} value={this.state.displayed_amount_min} onBlur={this.on_blur_amount_min} onFocus={this.on_focus_amount_min} status={0} note={null} label="Minimum amount" style={{textAlign: 'right'}} />
                  <MyInput onChange={this.on_change_amount_max} value={this.state.displayed_amount_max} onBlur={this.on_blur_amount_max} onFocus={this.on_focus_amount_max} status={0} note={null} label="Maximum amount" style={{textAlign: 'right'}} />
                </div> : null}
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_billing_email: v})} init_value={this.state.include_billing_email} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Payor
              </div>
              {this.state.include_billing_email ? 
                <div style={{paddingTop: '15px'}}>
                  <MyInput onChange={(e) => e.target.value.length > this.BILLING_EMAIL_CHAR_LIMIT ? null : this.setState({billing_email: e.target.value})} value={this.state.billing_email} onBlur={() => null} onFocus={() => null} status={0} note={null} label="Payor email" />
                </div> : null}
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_status: v})} init_value={this.state.include_status} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Status
              </div>
              {this.state.include_status ? 
                <div>
                  <table><tbody>
                    {this.INVOICE_STATUSES.map(
                      (s, index) => 
                        <tr key={index}>
                          <td style={{width: '24px'}}>
                            {/*<button onClick={() => this.setState({status: this.state.status.includes(s) ? this.state.status.filter((e) => e != s) : this.state.status.concat([s])})}>
                              {this.state.status.includes(s) ? "Included" : "Excluded"}
                            </button>*/}
                            <Checkbox on_toggle={(v) => this.setState({status: v ? this.state.status.concat([s]) : this.state.status.filter((e) => e != s)})} />
                          </td>
                          <td>
                            {this.INVOICE_STATUS_TO_NAME[String(s)]}
                          </td>
                        </tr>
                    )}
                  </tbody></table>
                </div> : null}
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_due_at: v})} init_value={this.state.include_due_at} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Due at
              </div>
              {this.state.include_due_at ? 
                <div style={{paddingTop: '15px'}}>
                <MyInput type="date" onChange={this.on_change_due_at_min} value={this.state.displayed_due_at_min} status={0} label="Earliest date" note={null} /><br />
                <MyInput type="date" onChange={this.on_change_due_at_max} value={this.state.displayed_due_at_max} status={0} label="Latest date" note={null} /><br />
              </div> : null}
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_tags: v})} init_value={this.state.include_tags} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Tags
              </div>
              {this.state.include_tags ? 
                <div style={{marginTop: '10px'}}>
                  <div>
                    {this.state.tags.map(
                      (tag, index) =>
                      <div key={index} style={{padding: '6px', paddingLeft: '12px', paddingRight: '12px', fontSize: '13px', margin: '5px', backgroundColor: '#040dba', color: '#fff', display: 'inline-block', borderRadius: '14px', fontWeight: 'bold'}}>
                        {tag} <i style={{marginLeft: '4px', cursor: 'pointer'}} className="fa-solid fa-circle-xmark" onClick={() => this.setState({tags: this.state.tags.filter((v) => v != tag)})}></i>
                      </div>
                    )}
                  </div>
                  <MyInput type="text" onChange={(e) => this.setState({typed_tag: e.target.value})} onKeyDown={(e) => e.code === "Enter" && this.state.typed_tag.length >= this.MIN_TAG_LENGTH ? this.setState({tags: this.state.tags.concat([this.state.typed_tag]), typed_tag: ""}) : null} value={this.state.typed_tag} status={0} label="Tag - press Enter to add" note={null} />
                </div> : null}
            </td>
          </tr>
        </tbody></table>

        <button className="std-button" onClick={() => this.search(0,this.state.limit,true)}>
          Search
        </button>

        {this.state.show_results ? 
          <div>
            <table style={{width: '100%'}}><tbody>
              <tr>
                <td>
                  <h3>
                    Results ({this.state.total_count})
                  </h3>
                </td>
                <td style={{textAlign: 'right'}}>
                  <button className="button-blue" onClick={this.search_to_csv}>
                    Download CSV
                  </button>
                </td>
              </tr>
            </tbody></table>

            <table id="invoice-results"><tbody>
              <tr>
                <th>
                  Status
                </th>
                <th>
                  Title
                </th>
                <th>
                  Payor
                </th>
                <th>
                  Due at
                </th>
                <th>
                  Amount
                </th>
              </tr>
              {this.state.results.map(
                (result, index) =>
                <tr key={index} onClick={() => {window.location.href = "/invoice/"+result.code+"/admin";}}>
                  <td>
                    {/*<div style={{backgroundColor: result.amount_paid === 0 ? '#040dba' : (result.amount_paid === result.amount ? '#32bfb8' : '#e6a330'), color: result.amount_paid === 0 ? '#fff' : '#fff', padding: '3px', fontSize: '13px', borderRadius: '4px', width: '80px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer'}}>
                      {result.amount_paid === 0 ? "UNPAID" : (result.amount === result.amount_paid ? "PAID" : "PART PAID")}
                    </div>*/}
                    <div style={{backgroundColor: this.INVOICE_STATUS_TO_COLOUR[result.status], color: '#fff', padding: '3px', fontSize: '13px', borderRadius: '4px', width: '80px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer', margin: 'auto', fontFamily: 'Roboto Mono'}}>
                      {this.INVOICE_STATUS_TO_WORD[result.status]}
                    </div>
                  </td>
                  <td>
                    <b>{result.title}</b>
                  </td>
                  <td>
                    {result.billing_email}
                  </td>
                  {result.due_at === null ? 
                    <td>
                    </td> : 
                    <td style={{color: (new Date(result.due_at)).getTime() - Date.now() < 0 && result.status < 4 ? 'red' : 'black'}}>
                      {result.due_at.slice(0,10)}
                    </td>}
                  <td style={{textAlign: 'right'}}>
                    ${this.amount_to_formatted_string(result.amount)}
                  </td>
                </tr>
              )}
            </tbody></table>
          </div> : null}
        
        {this.state.total_count != null ? 
          <table className="search-pagination-table"><tbody>
            <tr>
              <td>
                {this.state.showing_page > this.state.max_num_pages / 2 ? 
                  <div onClick={() => this.search(this.state.showing_page - ((this.state.max_num_pages-1)/2) - 1, this.state.limit, false)}>
                    ...
                  </div> : null}
              </td>
              {this.state.num_pages <= this.state.max_num_pages || this.state.showing_page < this.state.max_num_pages / 2 ? 
                Array(Math.min(this.state.num_pages,this.state.max_num_pages)).fill(0).map((_,index) => 
                  <td key={index}>
                    <div onClick={() => this.search(index,this.state.limit,false)} style={this.state.showing_page === index ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                      {index+1}
                    </div>
                  </td>) : 
                Array(this.state.max_num_pages).fill(0).map((_,i) => this.state.showing_page - ((this.state.max_num_pages - 1)/2) + i).map((v,index) => 
                  this.state.num_pages - v < ((this.state.max_num_pages - 1) / 2) - 1 ? null : 
                    <td key={index}>
                      <div onClick={() => this.search(v,this.state.limit,false)} style={this.state.showing_page === v ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                        {v + 1}
                      </div>
                    </td>)}
              <td>
                {this.state.num_pages - this.state.showing_page > this.state.max_num_pages / 2 + 1 ? 
                  <div onClick={() => this.search(Math.max(this.state.showing_page + ((this.state.max_num_pages - 1) / 2) + 1,this.state.max_num_pages),this.state.limit,false)}>
                    ...
                  </div> : null}
              </td>
            </tr>
          </tbody></table> : null}

        {/*this.state.show_results ? 
          <div style={{marginTop: '40px'}}>
            <h3>
              Results ({this.state.results.length})
            </h3>
            {this.state.results.map(
              (result, index) =>
              <div key={index} style={{padding: '8px', margin: '3px', border: 'solid', borderWidth: '1px', borderColor: '#c9c9c9', borderRadius: '6px'}}>
                <table style={{width: '100%'}}><tbody>
                  <tr>
                    <td style={{width: '95px'}}>
                      <div style={{backgroundColor: result.amount_paid === 0 ? '#040dba' : (result.amount_paid === result.amount ? '#32bfb8' : '#e6a330'), color: result.amount_paid === 0 ? '#fff' : '#fff', padding: '3px', fontSize: '13px', borderRadius: '4px', width: '80px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer'}}>
                        {result.amount_paid === 0 ? "UNPAID" : (result.amount === result.amount_paid ? "PAID" : "PART PAID")}
                      </div>
                    </td>
                    <td>
                      <h4 style={{padding: '5px', margin: '0px', paddingLeft: '0px'}}>
                        <a href={"/invoice/"+result.code+"/admin"} style={{textDecoration: 'none', color: 'black'}}>{result.title}</a>
                      </h4>
                    </td>
                    <td style={{textAlign: 'right'}}>
                      <h4 style={{padding: '5px', margin: '0px', paddingLeft: '0px', color: 'grey'}}>
                        ${this.amount_to_formatted_string(result.amount)}
                      </h4>
                    </td>
                  </tr>
                </tbody></table>
                <div style={{color: 'grey', fontSize: '13px'}}>
                  <table style={{width: '100%'}}><tbody>
                    <tr>
                      <td>
                        Billed to <b>{result.billing_email}</b>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{width: '50%'}}>
                        Created on <b>{new Date(result.created_at).toLocaleString()}</b>
                      </td>
                      {result.due_at === null ? null : 
                        <td style={{color: (new Date(result.due_at)).getTime() - Date.now() < 0 && result.status < 4 ? 'red' : 'black'}}>
                          Due at <b>{result.due_at.slice(0,10)}</b>
                        </td>}
                      <td style={{textAlign: 'right'}}>
                        <a href={"/invoice/"+result.code+(result.privacy_status > 0 ? "?pk="+result.passkey : '')} style={{color: 'grey'}}>See Invoice</a>
                      </td>
                    </tr>
                  </tbody></table>
                </div>
              </div>
            )}
                      </div> : null*/}
      </div>
    );
  }
}

export default InvoiceSearch
