import React from "react"
import PropTypes from "prop-types"
import MbLeftPanelPayor from "./MbLeftPanelPayor.js"
import MbOverlay from "./MbOverlay.js"
import MbLoginPayor from "./MbLoginPayor.js"
class MbHeader extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      user: Number(this.props.logged_in) === 1 ? JSON.parse(this.props.user) : null,
      //session: Number(this.props.logged_in) === 1 ? JSON.parse(this.props.session) : null,
      //company: Number(this.props.logged_in) === 1 ? JSON.parse(this.props.company) : null,
      notifications: [1,2,3],
      show_user_window: false,
      show_login_panel: false,
      show_left_panel: false, //Number(this.props.logged_in) === 1,
      show_notifications: false
    };

    this.MAIN_COLOUR = '#040dba';
    this.SECONDARY_COLOUR = '#32bfb8';//'#26d463';

    //this.handle_click = this.handle_click.bind(this);

  }

  componentDidMount(){
    //window.addEventListener('click', this.handle_click);
    if (this.state.show_left_panel && false){
      document.getElementById('content').style.marginLeft = '240px';
      let x = document.getElementById('flash-wrapper');
      if (x != null && x != null){
        x.style.marginLeft = '210px';
      }
    }
  }

  /*handle_click_2(event){
    //console.log(e.target.id);
    let el = event.target;
    const Sa = this.state.show_user_window && this.state.show_notifications;
    const Sb = this.state.show_user_window && !this.state.show_notifications;
    const So = !this.state.show_user_window;
    const a = (el.id === 'noti-icon');
    const b = (el.id === 'user-icon');
    const c = (el.id === 'user-window-x');
    const d = (document.getElementById('user-window') !== null && document.getElementById('user-window').contains(el));
    const e = !a && !b && !c && !d;
    //console.log('click! a = '+String(a)+', b = '+String(b)+', c = '+String(c)+', d = '+String(d)+', e = '+String(e));
    if ((So && a) || (Sb && a)){
      // open a
      this.setState({
        show_user_window: true,
        show_notifications: true
      });
    } else if ((So && b) || (Sa && b)){
      // open b
      this.setState({
        show_user_window: true,
        show_notifications: false
      });
    } else if ((Sa && (a || c || e)) || (Sb && (b || c || e))){
      this.setState({
        show_user_window: false
      });
    }
  }*/

  /*log_out(){
    // /sessions/destroy
    fetch("/sessions/destroy", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      if (json.success){
        window.location.href = "/";
      }
    });
  }*/

  render () {
    console.log(this.state);
    return (
      <div>
        <div className="mb-header">
          <table style={{width: "100%"}}><tbody>
            <tr>
              {this.state.user === null ? null : 
                <td style={{width: "2.2em", textAlign: "center"}}>
                  <div style={{fontSize: "1.5em", color: "#040dba"}} onClick={() => this.setState({show_left_panel: !this.state.show_left_panel})}>
                    <i className="fa-solid fa-bars"></i>
                  </div>
                </td>}
              <td>
                <div onClick={() => {window.location.href = "/"}}>
                  <span style={{color: "#040dba"}}>Pay</span><span style={{color: "#32bfb8"}}>Solve</span>
                </div>
              </td>
              <td style={{fontSize: "1.5em", color: "#040dba", textAlign: "right", paddingRight: "0.8em"}}>
                {this.state.user === null ? 
                <div onClick={() => this.setState({show_login_window: !this.state.show_login_window})}>
                  <i className="fa-solid fa-right-to-bracket"></i>
                </div> : 
                <div onClick={() => this.setState({show_user_window: !this.state.show_user_window})}>
                  <i id="user-icon" className="fa-regular fa-user"></i>
                </div>}
              </td>
            </tr>
          </tbody></table>
        </div>

        {this.state.show_user_window ? 
          <MbOverlay on_cancel={() => this.setState({show_user_window: false})} title="Payor">
            <div id="user-window" className="user-window">
              <h3 style={{textAlign: 'center'}}>
                <span style={{color: 'grey'}}>Logged in as</span><br />{this.state.user.name}
              </h3>
              <h4 style={{fontFamily: 'Roboto Mono', color: 'grey', textAlign: 'center'}}>
                {this.state.user.code}<br />{this.state.user.email}
              </h4>
              <button onClick={() => location.href = "/myaccount"} className="mb-button">
                My Account
              </button>
              <form acceptCharset="UTF-8" action="/sessions/destroy" method="post">
                <input name="authenticity_token" type="hidden" value={this.props.auth_token} />
                <button className="mb-button">Log Out</button>
              </form>
            </div>
          </MbOverlay> : null}
        
        {this.state.show_login_window ? 
          <MbOverlay on_cancel={() => this.setState({show_login_window: false})} title="Login">
            <div>
              <MbLoginPayor auth_token={this.props.auth_token} />
            </div>
          </MbOverlay> : null}

        {this.state.show_user_window && false ? 
          <div id="user-window" className="user-window" style={{width: '180px', height: '240px', padding: '15px', backgroundColor: '#fff', border: 'solid', borderWidth: '1px', borderColor: '#c9c9c9', position: 'fixed', top: '70px', right: '30px', borderRadius: '6px'}}>
            {this.state.show_notifications ? 
            <div>
              Notifications
            </div> : 
            <div>
              <div style={{textAlign: 'right'}}>
                <span style={{cursor: 'pointer'}} id="user-window-x">
                  x
                </span>
              </div>
              <div style={{fontSize: '12px'}}>
                Logged in as <b>{this.state.user.name}</b>
              </div>
              <div style={{textAlign: 'center', marginTop: '10px'}}>
                <form acceptCharset="UTF-8" action="/sessions/destroy" method="post">
                  <input name="authenticity_token" type="hidden" value={this.props.auth_token} />
                  <button>Log Out</button>
                </form>
              </div>
              {/*<div className="user-window" style={{fontWeight: 'bold'}}>{this.state.user === undefined || this.state.user === null ? null : this.state.user.name}</div>*/}
            </div>}
          </div> : null}

          {this.state.show_left_panel ? 
            <div style={{position: 'fixed', top: '0px', left: '0px', padding: '0.3em', backgroundColor: this.MAIN_COLOUR/*'#1b1b1b'*/, height: '100%', width: '85%', zIndex: '91'}}>
              <table style={{width: "80%", margin: "1.5em"}}><tbody>
                <tr>
                  <td>
                    <div style={{fontSize: '2em', fontWeight: 'bold', cursor: 'pointer', color: '#fff', textAlign: 'center', marginTop: '0.8em'}} onClick={() => location.href = '/'}>
                      <span style={{color: this.SECONDARY_COLOUR}}>Pay</span><span>Solve</span>
                    </div>
                  </td>
                  <td style={{textAlign: "right", fontSize: "1.6em", color: "#fff", verticalAlign: 'middle'}}>
                    <div onClick={() => this.setState({show_left_panel: false})}>
                      <i className="fa-solid fa-xmark"></i>
                    </div>
                  </td>
                </tr>
              </tbody></table>
              <MbLeftPanelPayor />
            </div> : null}
      </div>
    );
  }
}

export default MbHeader
