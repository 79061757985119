import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
import * as zxcvbn from "./zxcvbn.js"

class RecoverPayorPassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      status: Number(this.props.status),
      messages: [],
      token: this.props.token,
      payor_code: this.props.payor_code,
      loading: false,
      email: "",
      password: "",
      password_status: 0,
      password_message: "",
      password_confirmation: "",
      password_confirmation_status: 0,
      password_confirmation_message: ""
    };

    this.on_type_password = this.on_type_password.bind(this);
    this.on_blur_password = this.on_blur_password.bind(this);
    this.on_blur_password_confirmation = this.on_blur_password_confirmation.bind(this);
    this.reset_password = this.reset_password.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.add_message = this.add_message.bind(this);
    this.request_reset = this.request_reset.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    this.MAX_PASSWORD_LENGTH = 128;
    
  }

  request_reset(){
    this.setState({
      loading: true
    });
    fetch("/api/payors/requestpasswordreset", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        email: this.state.email
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("An error was encountered in your request.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.setState({
          status: 3,
          loading: false
        });
        this.add_message("If the Payor email exists in our system, we have sent it a recovery email.",'success');
      } else {
        this.add_message("Error: "+json.error,'danger');
      }
    });
  }

  on_type_password(e){
    const typed = e.target.value;
    if (typed.length < this.MAX_PASSWORD_LENGTH){
      const pw_eval = zxcvbn(typed);
      console.log(pw_eval);
      this.setState({
        password: typed,
        password_status: 1,
        password_message: "",
        password_score: typed.length < this.MIN_PASSWORD_LENGTH ? -1 : pw_eval.score,
        password_evaluation: pw_eval
      });
    }
  }

  on_blur_password(e){
    if (this.state.password_score === -1 || this.state.password.length > this.MAX_PASSWORD_LENGTH || this.state.password.length < this.MIN_PASSWORD_LENGTH){
      this.setState({
        password_status: 3,
        password_message: "Password must be between "+String(this.MIN_PASSWORD_LENGTH)+" and "+String(this.MAX_PASSWORD_LENGTH)+" characters."
      });
    } else {
      this.setState({
        password_status: 2,
        password_message: ""
      });
      this.on_blur_password_confirmation(null);
    }
  }

  on_blur_password_confirmation(e){
    if (this.state.password !== this.state.password_confirmation){
      this.setState({
        password_confirmation_status: 3,
        password_confirmation_message: "Confirmation doesn't match new password."
      });
    } else {
      this.setState({
        password_confirmation_status: 2,
        password_confirmation_message: ""
      });
    }
  }

  reset_password(){
    if (this.state.password_status != 2 || this.state.password_confirmation_status != 2){
      return false;
    }
    this.setState({
      loading: true
    });
    fetch("/api/payors/passwordreset", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        token: this.props.token,
        email: this.props.email,
        password: this.state.password
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("An error was encountered in processing your request.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.setState({
          status: 2,
          loading: false
        });
        this.add_message("Password successfully set.",'success');
      } else {
        this.setState({
          loading: false
        });
        this.add_message("Error: "+json.error,'danger');
      }
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  render () {
    console.log(this.state);
    return (
      <div className="form-section" style={{width: '400px'}}>

        <h2>
          Reset Password (Payor)
        </h2>

        {this.state.messages.map(
              (message, index) =>
              <div className={"flash flash-"+message.type} key={index}>
                <table><tbody>
                  <tr>
                    <td style={{fontSize: '18px'}}>
                      <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                    </td>
                    <td>
                      {message.content}
                    </td>
                  </tr>
                </tbody></table>
              </div>
            )}

        {[<div>
          <div style={{padding: '10px', fontSize: '14px', textAlign: 'center'}}>
            Enter your email
          </div>
          <div className="myinput-wrapper-centre">
            <MyInput onChange={(e) => this.setState({email: e.target.value})} value={this.state.email} onFocus={() => this.setState({email_status: 1})} label="Email" />
          </div>
          <div style={{textAlign: 'center'}}>
            <button className="std-button" onClick={this.request_reset}>
              {this.state.loading ? "Submitting" : "Submit"}
            </button>
          </div>
        </div>,
        
        <div>
          <div className="myinput-wrapper-centre">
            <MyInput onChange={this.on_type_password} value={this.state.password} onFocus={() => this.setState({confirmation_status: 1})} onBlur={this.on_blur_password} status={this.state.password_status} note={this.state.password_status === 3 ? this.state.password_message : null} label="Password" onKeyDown={this.on_key_down_general} type="password" />
          </div>
          <br />
          <div style={{fontSize: '14px', color: 'grey'}}>Password strength: {this.state.password_status > 0 ? <span style={{color: ["red","#e6a330","#e6a330","#5158e0","#32bfb8","#32bfb8"][this.state.password_score + 1], fontWeight: "bold"}}>{["Too short","Very Weak","Weak","Moderate","Strong","Very Strong"][this.state.password_score + 1]}</span> : null}</div>
          <br />
          <div className="myinput-wrapper-centre">
            <MyInput onChange={(e) => this.setState({password_confirmation: e.target.value})} value={this.state.password_confirmation} onFocus={() => this.setState({password_confirmation_status: 1})} onBlur={this.on_blur_password_confirmation} status={this.state.password_confirmation_status} note={this.state.password_confirmation_status === 3 ? this.state.password_confirmation_message : null} label="Password confirmation" onKeyDown={this.on_key_down_general} type="password" />
          </div>
          <br />
          <div style={{textAlign: 'center'}}>
            <button className="std-button" onClick={this.reset_password}>
              {this.state.loading ? "Changing" : "Change"}
            </button>
          </div>
        </div>, 
        
        <div>
          <div style={{padding: '10px', fontSize: '14px', textAlign: 'center'}}>
            Password successfully reset.
          </div>
          <div style={{textAlign: 'center'}}>
            <button className="std-button" onClick={() => location.href="/login"}>
              Login
            </button>
          </div>
        </div>,
      
        <div>
          <div style={{padding: '10px', fontSize: '14px', textAlign: 'center'}}>
            Please check your email for a link to reset your password.
          </div>
        </div>][this.state.status]}
        
      </div>
    );
  }
}

export default RecoverPayorPassword
