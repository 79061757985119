import React from "react"
import PropTypes from "prop-types"
import MbInput from "./MbInput.js"
import MbOpenBankAccount from "./MbOpenBankAccount.js"
class MbPayment extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      payment_type: 0,
      loaded: false,
      invoice: null,
      error: false,
      identifier: null,
      identifier_email: null,
      show_email_input: false,
      typed_email: "",
      typed_email_status: 0,
      typed_email_error: "",
      loading_email: false,
      num_payment_types: 2
		};

    this.COMPONENT_WIDTH = 480;
    this.NUM_PAYMENT_TYPES = 2;

    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.click_to_copy = this.click_to_copy.bind(this);
    this.on_type_email = this.on_type_email.bind(this);
    this.on_blur_email = this.on_blur_email.bind(this);
    this.open_email_input = this.open_email_input.bind(this);
    this.register_email = this.register_email.bind(this);
    this.on_key_down_email = this.on_key_down_email.bind(this);

  }

  componentDidMount(){
    fetch("/api/invoice/"+this.props.code+"/payinfo"+([null,undefined].includes(this.props.passkey) ? "" : "?pk="+this.props.passkey), {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      if (json.invoice === null){
        this.setState({
          error: true
        });
      } else {
        this.setState({
          invoice: json.invoice,
          identifier: json.invoice.identifier,
          loaded: true,
          num_payment_types: json.invoice.payid === null ? 1 : 2,
          payment_type: json.invoice.payid === null ? 1 : 0
        });
      }
    });
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  click_to_copy(e, text){
    navigator.clipboard.writeText(text);
    e.target.innerHTML = "COPIED!"
    setTimeout(() => {
      e.target.innerHTML = "COPY";
    }, 1000);
  }

  on_type_email(e){
    if (!e.target.value.includes(' ')){
      this.setState({
        typed_email: e.target.value,
        typed_email_status: 1
      });
      //this.on_blur_email();
    }
  }

  on_blur_email(){
    if (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.typed_email)){
      this.setState({
        typed_email_status: 2,
        typed_email_error: ""
      });
      return true;
    } else {
      this.setState({
        typed_email_status: 3,
        typed_email_error: "Not a valid email"
      });
      return false;
    }
  }

  open_email_input(){
    console.log("open_email_input()");
    this.setState({
      show_email_input: true
    });
    setTimeout(() => {
      document.getElementById('email-input').focus();
    }, 200);
  }

  register_email(){
    if (!this.on_blur_email()){
      return false;
    }
    this.setState({
      loading_email: true
    });
    // /api/invoice/:code/registeremail
    fetch("/api/invoice/"+this.props.code+"/registeremail", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({email: this.state.typed_email, pk: this.props.passkey})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.setState({
        loading_email: false,
        typed_email_status: 3,
        typed_email_error: "Failed to register email"
      });
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.setState({
        loading_email: false,
        identifier: json.objectref.code,
        identifier_email: json.objectref.email,
        show_email_input: false
      });
    });
  }

  on_key_down_email(e){
    if (e.keyCode === 13){
      this.register_email();
    }
  }

  render () {
    console.log("Payment state");
    console.log(this.state);
    return (
      <div className="mb-payment">

        {/*<MbInput onChange={this.on_type_email} onKeyDown={this.on_key_down_email} value={this.state.typed_email} status={this.state.typed_email_status} note={this.state.typed_email_status === 3 ? this.state.typed_email_error : null} label="Type your email" id="email-input" onBlur={this.on_blur_email} />*/}
        
        {!this.state.loaded || this.state.error ? 
          <div>
            {this.state.error ? 
            <div>
              Sorry, an error was encountered.
            </div> : 
            <div>
              Loading...
            </div>}
          </div> : null}

        {this.state.loaded && !this.state.error ? <div>
          <div>
            <table className="mb-payment-header"><tbody>
              <tr>
                {this.state.invoice.payid === null ? null : 
                  <td onClick={() => this.setState({payment_type: 0})} style={{color: this.state.payment_type === 0 ? '#040dba' : 'grey'}}>
                    Pay by PayID
                  </td>}
                <td onClick={() => this.setState({payment_type: 1})} style={{color: this.state.payment_type === 1 ? '#040dba' : 'grey'}}>
                  Pay by BSB/Acct #
                </td>
              </tr>
            </tbody></table>
            <div className="payment-slider" style={{borderTop: 'solid', borderColor: '#040dba', width: (100.0 / this.state.num_payment_types)+'%', borderWidth: '3px', marginLeft: Math.min(this.state.payment_type, this.state.num_payment_types - 1) * (100.0 / this.state.num_payment_types) + '%'}} />
          </div>
          {this.state.payment_type === 0 ? 
            <div>

              {this.state.invoice.ref_preference === 2 && this.state.identifier_email === null ? 
                <div className="flash flash-warning">
                  <table><tbody>
                    <tr>
                      <td>
                        <i className="fa-solid fa-triangle-exclamation"></i>
                      </td>
                      <td>
                        Click 'Generate code' to be able to send money and receive a receipt.
                      </td>
                    </tr>
                  </tbody></table>
                </div> : null}
              
              <div style={{marginTop: '1.5em'}}>
                <MbOpenBankAccount />
              </div>

              <p style={{padding: "0.6em", fontSize: "0.95em", textAlign: "center", color: "grey"}}>
                Open up your banking account and start a new transaction. Enter the following:
              </p>
              <table className={this.state.show_email_input ? "mb-payment-instruction-table pit-blurred" : "mb-payment-instruction-table"}><tbody>
                <tr>
                  <td>
                    <p>Pay to (PayID)</p>
                    <p>
                      {[null,undefined].includes(this.state.invoice.account_name) ? null : this.state.invoice.account_name + " should appear"}
                    </p>
                  </td>
                  <td>
                    <p>{this.state.invoice.payid}<br /><span onClick={(e) => this.state.show_email_input ? null : this.click_to_copy(e, this.state.invoice.payid)} className="copy-span">COPY</span></p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Amount
                    </p>
                  </td>
                  <td>
                    <p>
                      ${this.amount_to_formatted_string(this.state.invoice.amount)}<br />
                      <span onClick={(e) => this.state.show_email_input ? null : this.click_to_copy(e, this.amount_to_formatted_string(this.state.invoice.amount))} className="copy-span">COPY</span>
                    </p>
                  </td>
                </tr>
                {this.state.show_email_input ? null : 
                <tr>
                  <td>
                    <p>
                      Description
                    </p>
                    <p>
                      This is important to identify the invoice.
                    </p>
                  </td>
                  <td>
                    {this.state.invoice.ref_preference < 2 ? <p>
                      <span style={{fontFamily: "Roboto Mono"}}>{this.state.identifier}</span>
                      <br /><span onClick={(e) => this.click_to_copy(e, this.state.identifier)} className="copy-span">COPY</span>
                    </p> : null}
                    {this.state.identifier_email === null ? null : 
                      <p>
                        <b>{this.state.identifier_email}</b> will receive a receipt.
                      </p>}
                    
                  </td>
                </tr>}
              </tbody></table>
              {this.state.invoice.ref_preference === 1 && !this.state.show_email_input ? 
                    <div style={{textAlign: 'center'}}>
                      <button className="mb-button" onClick={this.open_email_input}>
                        {this.state.identifier_email === null ? "Get receipt upon payment" : "Change email"}
                      </button>
                    </div> : null}
              
              {this.state.show_email_input ? 
                <div className="mb-myinput-wrapper-centre">
                  <MbInput onChange={this.on_type_email} onKeyDown={this.on_key_down_email} value={this.state.typed_email} status={this.state.typed_email_status} note={this.state.typed_email_status === 3 ? this.state.typed_email_error : null} label="Type your email" id="email-input" onBlur={this.on_blur_email} />
                  {this.state.loading_email ? 
                    <div>
                      Please wait...
                    </div> : 
                    <div style={{marginTop: "2em"}}>
                      <button className="mb-button" onClick={this.register_email}>
                        Submit
                      </button>
                      <button className="mb-button" onClick={() => this.setState({show_email_input: false, typed_email: "", typed_email_status: 0, typed_email_error: ""})}>
                        Cancel
                      </button>
                    </div>}
                </div> : null}
              
            </div> : null}

          {this.state.payment_type === 1 ? 
          <div>

            {this.state.invoice.ref_preference === 2 && this.state.identifier_email === null ? 
              <div className="flash flash-warning">
                <table><tbody>
                  <tr>
                    <td>
                      <i className="fa-solid fa-triangle-exclamation"></i>
                    </td>
                    <td>
                      Click 'Generate code' to be able to send money and receive a receipt.
                    </td>
                  </tr>
                </tbody></table>
              </div> : null}
            
            <div style={{marginTop: '1.5em'}}>
              <MbOpenBankAccount />
            </div>

            <p style={{padding: "0.6em", fontSize: "0.95em", textAlign: "center", color: "grey"}}>
              Open up your banking account and start a new transaction. Enter the following:
            </p>
            <table className={this.state.show_email_input ? "mb-payment-instruction-table pit-blurred" : "mb-payment-instruction-table"}><tbody>
              <tr>
                <td>
                  <p>BSB</p>
                </td>
                <td>
                  <p>{this.state.invoice.bsb}<br /><span onClick={(e) => this.click_to_copy(e, this.state.invoice.bsb)} className="copy-span">COPY</span></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Account Number</p>
                </td>
                <td>
                  <p>{this.state.invoice.account_number}<br /><span onClick={(e) => this.click_to_copy(e, this.state.invoice.account_number)} className="copy-span">COPY</span></p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>Account Name</p>
                  <p>
                    This is not essential.
                  </p>
                </td>
                <td>
                  <p>{this.state.invoice.account_name}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Amount
                  </p>
                </td>
                <td>
                  <p>
                    ${this.amount_to_formatted_string(this.state.invoice.amount)}<br />
                    <span onClick={(e) => this.click_to_copy(e, this.amount_to_formatted_string(this.state.invoice.amount))} className="copy-span">COPY</span>
                  </p>
                </td>
              </tr>
              {this.state.show_email_input ? null : 
                <tr>
                  <td>
                    <p>
                      Description
                    </p>
                    <p>
                      This is important to identify the invoice.
                    </p>
                  </td>
                  <td>
                    {this.state.invoice.ref_preference < 2 ? <p>
                      <span style={{fontFamily: "Roboto Mono"}}>{this.state.identifier}</span>
                      <br /><span onClick={(e) => this.click_to_copy(e, this.state.identifier)} className="copy-span">COPY</span>
                    </p> : null}
                    {this.state.identifier_email === null ? null : 
                      <p>
                        <b>{this.state.identifier_email}</b> will receive a receipt.
                      </p>}
                    
                  </td>
                </tr>}
            </tbody></table>
            {this.state.invoice.ref_preference === 1 && !this.state.show_email_input ? 
                    <div style={{textAlign: 'center'}}>
                      <button className="mb-button" onClick={this.open_email_input}>
                        {this.state.identifier_email === null ? "Get receipt upon payment" : "Change email"}
                      </button>
                    </div> : null}

            {this.state.show_email_input ? 
                <div className="mb-myinput-wrapper-centre">
                  <div style={{paddingTop: '0.9em', paddingBottom: '0.9em'}}>
                    <MbInput onChange={this.on_type_email} onKeyDown={this.on_key_down_email} value={this.state.typed_email} status={this.state.typed_email_status} note={this.state.typed_email_status === 3 ? this.state.typed_email_error : null} label="Type your email" id="email-input" onBlur={this.on_blur_email} />
                  </div>
                  {this.state.loading_email ? 
                    <div>
                      Please wait...
                    </div> : 
                    <div style={{marginTop: "1em"}}>
                      <button className="mb-button" onClick={this.register_email}>
                        Submit
                      </button>
                      <button className="mb-button" onClick={() => this.setState({show_email_input: false, typed_email: "", typed_email_status: 0, typed_email_error: ""})}>
                        Cancel
                      </button>
                    </div>}
                </div> : null}

          </div> : null}

        </div> : 
          null}

      </div>
    );
  }
}

export default MbPayment
