import React from "react"
import PropTypes from "prop-types"
class MbHomePage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    };

    this.IMAGE_ARRAY = [
      'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/Screenshot+2024-11-25+at+5.31.20%E2%80%AFPM.png',
      'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/Screenshot+2024-11-25+at+5.26.50%E2%80%AFPM.png',
      'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/Screenshot+2024-11-25+at+5.26.02%E2%80%AFPM.png'
    ];
    this.USE_CASES = ['utilities','B2B payments','small businesses','hospitals','manufacturers','tradies','delivery drivers'];

  }

  render () {
    return (
      <div style={{color: '#fff', textAlign: 'center'}}>
        <br />
        <h1>
          PaySolve
        </h1>
        <p style={{fontSize: '1.4em', fontFamily: 'Roboto Mono', padding: '1em'}}>
          A secure, easy-to-use, and affordable Australian invoice payments system.
        </p><br />
        {this.IMAGE_ARRAY.map(
          (imgsrc, index) => 
            <div style={{width: '90%', height: '50vw', overflow: 'hidden', borderRadius: '2vw', margin: 'auto', marginTop: '1em', marginBottom: '1em'}} key={index}>
              <img src={imgsrc} style={{width: '100%'}} />
            </div>
        )}
        <br />
        <div style={{color: '#fff', fontSize: '1em'}}>
          See how PaySolve's innovative first-of-its-kind system can solve your payments, saving you money and time.
        </div><br />
        <div onClick={() => location.href = "/company/new"} className="splash-button">
          <i className="fa-solid fa-arrow-right"></i> Show me
        </div>
      </div>
    );
  }
}

export default MbHomePage
