import React from "react"
import PropTypes from "prop-types"
import MbInput from "./MbInput.js"
import MbOverlay from "./MbOverlay.js"
import MbCheckbox from "./MbCheckbox.js"
import ProofOfWorkChain from "../ProofOfWorkChain.js"
class MbApplySignup extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      status: 1,
      messages: [], // name_status, abn_status || !has_abn, email_status, phone_status, company_name_status, intended_use_status, applicant_role_status

      name: "",
      name_status: 0,
      name_message: "",
      has_abn: true,
      abn: "",
      abn_status: 0,
      abn_message: "",
      website: "",
      expected_volume: "",
      email: "",
      email_status: 0,
      email_message: "",
      phone: "+61",
      phone_status: 0,
      phone_message: "",
      company_name: "",
      company_name_status: 0,
      company_name_message: "",
      intended_use: "",
      intended_use_status: 0,
      applicant_role: "",
      applicant_role_status: 0,

      challenge: "",
      challenge_zeros: Number(this.props.challenge_zeros),
      challenge_body: {foo:'bar'},
      challenge_go: false,
      solutions: [],
      num_challenges: Number(this.props.num_challenges),
      loading: false
    };

    this.on_blur_email = this.on_blur_email.bind(this);
    this.on_type_abn = this.on_type_abn.bind(this);
    this.display_abn = this.display_abn.bind(this);
    this.on_blur_abn = this.on_blur_abn.bind(this);
    this.display_phone = this.display_phone.bind(this);
    this.on_type_phone = this.on_type_phone.bind(this);
    this.on_blur_phone = this.on_blur_phone.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.submit_signupapply = this.submit_signupapply.bind(this);
    this.form_ok = this.form_ok.bind(this);

    this.EXPECTED_VOLUME_OPTIONS = ['0-100','100-1K','1K-5K','5K-20K','20K-50K','50K-250K','250K-1M','1M-5M','5M+'];
    this.NAME_MIN_LENGTH = 5;
    this.NAME_MAX_LENGTH = 64;
    this.COMPANY_MIN_LENGTH = 5;
    this.COMPANY_MAX_LENGTH = 64;
    this.INTENDED_USE_MAX_LENGTH = 80;
    this.ROLE_MAX_LENGTH = 32;

    this.MESSAGE_TYPE_TO_COLOUR = {'danger':'red','success':'green','info':'blue'};
    this.MESSAGE_TYPE_TO_ICON = {'danger':'circle-exclamation','warning':'circle-exclamation','info':'circle-info','success':'check'};
  }

  on_blur_email(){
    if (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.email)){
      this.setState({
        email_status: 2,
        email_message: ""
      });
    } else {
      this.setState({
        email_status: 3,
        email_message: "Not a valid email"
      });
    }
  }

  on_type_abn(e){
    let x = e.target.value.replaceAll(' ','');
    if (/^[0-9]*$/.test(x) && x.length <= 11){
      this.setState({
        abn: x //this.display_phone(x)
      });
    }
  }

  on_blur_abn(){
    const typed = this.state.abn.replaceAll(' ','');
    if (typed.length === 11 && /^[0-9]*$/.test(typed)){
      this.setState({
        abn_status: 2
      });
    } else {
      this.setState({
        abn_status: 3,
        abn_message: "Not a valid ABN"
      });
    }
  }

  display_abn(x){
    //if (this.state.abn_type === 0){
      return x.split('').map((a,i) => [1,4,7].includes(i) ? a+' ' : a).join('');
      //return x;//x.slice(0,2)+' '+x.slice(2,5)+' '+x.slice(5,8)+' '+x.slice(8,11);
    //}
  }

  display_phone(x){
    //if (this.state.abn_type === 0){ // 2,5,8
      return x.split('').map((a,i) => [2,5,8].includes(i) ? a+' ' : a).join('');
    //}
  }

  on_type_phone(e){
    console.log("on_type_phone: "+e.target.value);
    let x = e.target.value.replaceAll(' ','');
    //x.length < 3 ? x = "+61" : ['+','6','1'].forEach((c,i) => x[i] = c);
    if (x.slice(0,3) != "+61"){
      x = "+61"+x.slice(3,100);
    }
    if (/^[0-9]*$/.test(x.slice(1,100)) && x.length <= 12 && x.length >= 3){
      this.setState({
        phone: x //this.display_phone(x)
      });
    } else if (x.length < 3){
      this.setState({
        phone: "+61"
      });
    }
  }

  on_blur_phone(){
    const typed = this.state.phone.replaceAll(' ','');
    if (typed.length === 12 && /^\+61[42378]\d{8}$/.test(typed)){
      this.setState({
        phone_status: 2
      });
    } else {
      this.setState({
        phone_status: 3,
        phone_message: "Not a valid Australian phone number"
      });
    }
  }

  submit_signupapply(){
    this.setState({
      loading: true
    });
    let body = {};
    body['signupapply'] = this.state.challenge_body;
    body['proofs'] = this.state.solutions;
    /*body['org_bsb'] = this.state.org_bsb;
    body['org_account_number'] = this.state.org_account_number;*/
    fetch("/api/signupapplies/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      this.setState({
        loading: false,
        status: 0
      });
      return response.json();
      if (response.ok){
        return response.json();
      }
      this.setState({
        loading: false,
        status: 0
      });
      let json = response.json();
      console.log("JSON");
      console.log(json);
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.add_message("Encountered error in creating Redress object. "+json.errors,'danger');
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      if (json.success){
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (json.success){
          this.add_message("Successfully submitted request.",'success');
          this.setState({
            status: 4
          });
          //window.location.href="/redress/"+json.code;
        } else {
          this.add_message("Error in creating Redress object: "+json.errors,'danger');
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.add_message("Encountered error in creating Redress object. "+json.errors,'danger');
        throw new Error('Request fail');
      }
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  generate_body(){
    return {
      name: this.state.name,
      abn: this.state.abn,
      expected_volume: this.state.expected_volume,
      email: this.state.email,
      phone_number: this.state.phone,
      company_name: this.state.company_name,
      intended_use: this.state.intended_use,
      applicant_role: this.state.applicant_role
    };
  }

  form_ok(){ // abn_status || !has_abn,
    return ['name_status', 'email_status', 'phone_status', 'company_name_status', 'intended_use_status', 'applicant_role_status'].filter((c) => this.state[c] === 2).length === 6 && (this.state.abn_status === 2 || !this.state.has_abn);
  }
  
  render () {
    console.log(this.state);
    /*console.log("Phone display: "+this.display_phone(this.state.phone));
    console.log(this.display_phone(this.state.phone));*/
    return (this.state.status === 4 ? 
      <div className="form-section">
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}
        <div style={{textAlign: 'center'}}>
          <h2>
            Thank you!
          </h2>
          <p>
            We will be in contact with you shortly.
          </p>
        </div>
      </div> : 
      <div className="mb-form-section">

        {this.state.show_confirm && this.state.status > 1 ? 
          <MbOverlay on_cancel={() => this.setState({show_confirm: false})} title="Confirm submission">
            {this.state.status === 2 ? 
              <div>
                <div>
                  Details
                </div>
                <table className="apikey-table"><tbody>
                  <tr>
                    <td>
                      Your name
                    </td>
                    <td>
                      {this.state.name}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Your organisation name
                    </td>
                    <td>
                      {this.state.company_name}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      ABN
                    </td>
                    <td>
                      {this.state.has_abn ? this.state.abn : 'None'}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Contact email
                    </td>
                    <td>
                      {this.state.email}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Your role
                    </td>
                    <td>
                      {this.state.applicant_role}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Contact phone number
                    </td>
                    <td>
                      {this.state.phone}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Expected volume
                    </td>
                    <td>
                      {this.state.expected_volume}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Intended use
                    </td>
                    <td>
                      {this.state.intended_use}
                    </td>
                  </tr>
                </tbody></table>
                <button onClick={() => this.setState({challenge_body: this.generate_body()}, () => {this.setState({status: 3, challenge_go: true})})} className="mb-button">
                  Confirm
                </button>
              </div> : null}

            {this.state.status === 3 ? 
              <div>
                <p>
                  Let your computer complete the following challenge as a security measure.
                </p>
                <ProofOfWorkChain on_solve={(ss) => this.setState({solutions: ss})} body={this.state.challenge_body} go={this.state.challenge_go} num_challenges={this.state.num_challenges} auth_token={this.props.auth_token} challenge_zeros={this.state.challenge_zeros} />
                <div style={{height: '60px', paddingTop: '15px'}}>
                  {this.state.solutions.length === this.state.num_challenges ? 
                    <button className="mb-button" onClick={() => this.state.loading ? null : this.submit_signupapply()}>
                      {this.state.loading ? "Submitting..." : "Submit"}
                    </button> : null}
                </div>
              </div> : null}
          </MbOverlay> : null}
        
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <h2>
          Sign up for an account
        </h2>
        <div className="myinput-wrapper-centre">
          <MbInput onChange={(e) => this.setState({name: e.target.value})} value={this.state.name} status={this.state.name_status} onBlur={() => this.setState({name_status: this.state.name.trim().length < this.NAME_MIN_LENGTH || this.state.name.trim().length > this.NAME_MAX_LENGTH ? 3 : 2})} label="Your name" />
          <br />
          <MbInput onChange={(e) => this.setState({company_name: e.target.value})} value={this.state.company_name} status={this.state.company_name_status} onBlur={() => this.setState({company_name_status: this.state.company_name.trim().length < this.COMPANY_MIN_LENGTH || this.state.company_name.trim().length > this.COMPANY_MAX_LENGTH ? 3 : 2})} label="Your organisation name" />
          <br />
          <table><tbody>
            <tr>
              <td>
                <MbCheckbox on_toggle={(v) => this.setState({has_abn: v})} init_value={this.state.has_abn} />
              </td>
              <td style={{paddingLeft: '5px'}}>
                My organisation has an ABN
              </td>
            </tr>
          </tbody></table><br />
          {this.state.has_abn ? <MbInput onChange={this.on_type_abn} value={this.display_abn(this.state.abn)} status={this.state.abn_status} onBlur={this.on_blur_abn} note={this.state.abn_status === 3 ? this.state.abn_message : null} label="Your organisation's ABN" /> : null}
          <br />
          <MbInput onChange={(e) => this.setState({email: e.target.value})} value={this.state.email} status={this.state.email_status} onBlur={this.on_blur_email} note={this.state.email_status === 3 ? this.state.email_message : null} label="Your contact email" />
          <br />
          <MbInput onChange={(e) => this.setState({applicant_role: e.target.value, applicant_role_status: e.target.value > this.ROLE_MAX_LENGTH ? 3 : 2})} value={this.state.applicant_role} status={this.state.applicant_role_status} onBlur={(e) => this.setState({applicant_role_status: this.state.applicant_role.length > 0 ? 2 : 3})} note={this.state.applicant_role_status === 3 ? this.state.applicant_role_message : String(this.ROLE_MAX_LENGTH - this.state.applicant_role.length)} label="Your role" />
          <br />
          {/*<table><tbody>
            <tr>
              <td>
                <img src="https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/ausflag_icon.webp" style={{width: '30px', paddingTop: '15px'}} />
              </td>
              <td>
              </td>
            </tr>
          </tbody></table>*/}
          <MbInput onChange={this.on_type_phone} value={this.display_phone(this.state.phone)} status={this.state.phone_status} onBlur={this.on_blur_phone} note={this.state.phone_status === 3 ? this.state.phone_message : null} label="Your contact phone number" />
          <br />
          <div style={{color: 'grey', fontSize: '13px', marginBottom: '4px', textAlign: 'left'}}>
            Your expected monthly invoices
          </div>
          <select className="general-select" onChange={(e) => this.setState({expected_volume: this.EXPECTED_VOLUME_OPTIONS[e.target.value]})} style={{width: '290px'}}>
            {this.EXPECTED_VOLUME_OPTIONS.map(
              (amount, index) =>
              <option key={index} value={index}>
                {amount} per month
              </option>
            )}
          </select>
          <br /><br />
          <MbInput onChange={(e) => this.setState({intended_use: e.target.value, intended_use_status: e.target.value.length > 0 ? 2 : 1})} value={this.state.intended_use} status={this.state.intended_use_status} onBlur={() => this.setState({intended_use_status: this.state.intended_use.length > 0 ? 2 : 1})} note={String(this.INTENDED_USE_MAX_LENGTH - this.state.intended_use.length)} label="How might you intend to use PaySolve?" />
          <br />
          {this.form_ok() ? <button className="mb-button" onClick={() => this.setState({status: 2, show_confirm: true})}>
            Submit
          </button> : null}
        </div>
      </div>
    );
  }
}

export default MbApplySignup
