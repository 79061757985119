import React from "react"
import PropTypes from "prop-types"
class Test2 extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      statement: ""
    };

    this.execute_stat = this.execute_stat.bind(this);

  }

  execute_stat(){
    fetch("/api/stats", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        queries: [
          {
            objname: 'Invoice',
            name: 'abc123',
            statement: 'amount > 100 AND amount < 500',
            function: 'sum',
            columns: ['amount']
          }
        ]
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
    });
  }
  
  render () {
    return (
      <div>
        <input onChange={(e) => this.setState({statement: e.target.value})} />
        <button onClick={this.execute_stat}>
          Execute
        </button>
      </div>
    );
  }
}

export default Test2
