import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"
import MyInput from "./MyInput.js"
import * as zxcvbn from "./zxcvbn.js"

class PayorAccountSecurity extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      loaded: false,
      name: "",
      name_status: 0,
      name_error: "",
      email: "",
      email_status: 0,
      email_error: "",
      changed: false,
      show_confirmation: false,
      typed_password_confirmation: "",
      password_confirmation_status: 0,
      update_loading: false,
      messages: [],
      show_change_password: false,
      old_password: "",
      old_password_status: 0,
      old_password_message: "",
      new_password: "",
      new_password_status: 0,
      new_password_message: "",
      new_password_confirmation: "",
      new_password_confirmation_status: 0,
      new_password_confirmation_message: "",
      new_password_loading: false,
      password_score: -1
    };

    this.on_type_name = this.on_type_name.bind(this);
    this.on_blur_name = this.on_blur_name.bind(this);
    this.on_type_email = this.on_type_email.bind(this);
    this.on_blur_email = this.on_blur_email.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.update_payor = this.update_payor.bind(this);
    this.update_password = this.update_password.bind(this);
    this.on_type_old_password = this.on_type_old_password.bind(this);
    this.on_type_new_password = this.on_type_new_password.bind(this);
    this.on_blur_new_password = this.on_blur_new_password.bind(this);
    this.on_blur_new_password_confirmation = this.on_blur_new_password_confirmation.bind(this);

    this.MAX_NAME_LENGTH = 48;
    this.MIN_NAME_LENGTH = 5;
    this.USER_NAME_LENGTH_LIMIT = 32;
    this.MIN_USER_NAME_LENGTH = 5;
    this.HANDLE_LENGTH_LIMIT = 16;
    this.MIN_HANDLE_LENGTH = 4;
    this.MAX_PASSWORD_LENGTH = 200;
    this.MIN_PASSWORD_LENGTH = 6;
    this.MAX_PASSWORD_LENGTH = 128;

    this.USER_STATUS_TO_NAME = {'-2':'Suspended by PaySolve','-1':'Suspended by Organisation','1':'Initiated, unactivated','2':'Active','3':'Awaiting reset'};
    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  componentDidMount(){
    console.log("CDM data:");
    console.log(this.props.data);
    if (![null,undefined].includes(this.props.data)){
      this.setState({
        data: this.props.data,
        name: this.props.data.name,
        email: this.props.data.email,
        loaded: true
      });
    } else {
      this.setState({
        loaded: false
      });
    }
  }

  on_type_name(e){
    const typed = e.target.value;
    if (!typed.includes('  ')){
      this.setState({
        name: typed,
        changed: true
      });
    }
  }

  on_blur_name(){
    if (this.state.name.length < this.MIN_NAME_LENGTH || this.state.name.length > this.MAX_NAME_LENGTH){
      this.setState({
        name_status: 3,
        name_error: "Must be between "+String(this.MIN_NAME_LENGTH)+" and "+String(this.MAX_NAME_LENGTH)+" characters."
      });
    } else {
      this.setState({
        name_status: 2,
        name_error: null
      });
    }
  }

  on_type_email(e){
    if (!e.target.value.includes(' ')){
      this.setState({
        email: e.target.value,
        email_status: 1,
        changed: true
      });
      //this.on_blur_email();
    }
  }

  on_blur_email(){
    if (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.email)){
      if (this.state.email === this.state.data.email){
        this.setState({
          email_status: 2
        });
        return null;
      }
      fetch("/api/checkemail?email="+this.state.email, {
        method: 'GET',
        credentials: 'include'
      }).then( (response) => {
        if (response.ok){
          return response.json();
        }
        throw new Error('Request fail');
      }).then(json => {
        console.log("checked email");
        console.log(json);
        if (json.available){
          this.setState({
            email_status: 2
          });
        } else {
          this.setState({
            email_status: 3,
            email_error: "That email is not available to use"
          });
        }
      });
    } else {
      this.setState({
        email_status: 3,
        email_error: "Please type a valid email."
      });
    }
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  update_password(params){
    this.setState({
      new_password_loading: true
    });
    fetch("/api/payor", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({payor: params, password: this.state.old_password})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      if (response.status === 401){
        this.setState({
          old_password_status: 3,
          old_password_message: "Password was incorrect",
          new_password_loading: false
        });
      } else {
        this.add_message("Could not successfully update (response not OK).",'danger');
        this.setState({
          show_change_password: false,
          new_password_loading: false,
          old_password: "",
          old_password_status: 0,
          old_password_message: "",
          new_password: "",
          new_password_status: 0,
          new_password_message: "",
          new_password_confirmation: "",
          new_password_confirmation_status: 0,
          new_password_confirmation_message: ""
        });
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.setState({
        show_change_password: false,
        new_password_loading: false,
        old_password: "",
        old_password_status: 0,
        old_password_message: "",
        new_password: "",
        new_password_status: 0,
        new_password_message: "",
        new_password_confirmation: "",
        new_password_confirmation_status: 0,
        new_password_confirmation_message: ""
      });
      this.add_message("Successfully updated password.",'success');
    });
  }

  update_payor(params){
    this.setState({
      update_loading: true
    });
    fetch("/api/payor", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({payor: params, password: this.state.typed_password_confirmation})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.setState({
        update_loading: false,
        changed: !json.success,
        typed_password_confirmation: "",
        show_confirmation: false
      });
      if (response.status === 401){
        this.add_message("Password was incorrect.",'danger');
      } else {
        this.add_message("Could not successfully update (response not OK).",'danger');
      }
    }).then(json => {
      this.setState({
        update_loading: false,
        changed: !json.success,
        typed_password_confirmation: "",
        show_confirmation: false,
        show_change_password: false,
        name_status: 0,
        email_status: 0
      }, () => {
        if (json.success){
          this.props.on_successful_update('info');
          this.add_message("Successfully updated.",'success');
        } else {
          this.add_message("Could not successfully update.",'danger');
        }
      });
    });
  }

  on_type_old_password(e){
    // Handle if 'Enter' is pushed
    const typed = e.target.value;
    if (typed.length < this.MAX_PASSWORD_LENGTH){
      /*const pw_eval = zxcvbn(typed);
      console.log(pw_eval);
      this.setState({
        typed_password: typed,
        password_score: typed.length < this.MIN_PASSWORD_LENGTH ? -1 : pw_eval.score,
        password_error: false,
        password_error_message: "",
        password_evaluation: pw_eval
      });*/
      this.setState({
        old_password: typed
      });
    }
  }

  on_type_new_password(e){
    const typed = e.target.value;
    if (typed.length < this.MAX_PASSWORD_LENGTH){
      const pw_eval = zxcvbn(typed);
      console.log(pw_eval);
      this.setState({
        new_password: typed,
        new_password_status: 1,
        new_password_message: "",
        password_score: typed.length < this.MIN_PASSWORD_LENGTH ? -1 : pw_eval.score,
        password_evaluation: pw_eval
      });
    }
  }

  on_blur_new_password(e){
    if (this.state.password_score === -1 || this.state.new_password.length > this.MAX_PASSWORD_LENGTH || this.state.new_password.length < this.MIN_PASSWORD_LENGTH){
      this.setState({
        new_password_status: 3,
        new_password_message: "Password must be between "+String(this.MIN_PASSWORD_LENGTH)+" and "+String(this.MAX_PASSWORD_LENGTH)+" characters."
      });
    } else {
      this.setState({
        new_password_status: 2,
        new_password_message: ""
      });
      this.on_blur_new_password_confirmation(null);
    }
  }

  on_blur_new_password_confirmation(e){
    if (this.state.new_password !== this.state.new_password_confirmation){
      this.setState({
        new_password_confirmation_status: 3,
        new_password_confirmation_message: "Confirmation doesn't match new password."
      });
    } else {
      this.setState({
        new_password_confirmation_status: 2,
        new_password_confirmation_message: ""
      });
    }
  }

  componentDidUpdate(prev_props){
    if (prev_props.data !== this.props.data){
      this.componentDidMount();
    }
  }

  render () {
    return (
      <div>
        {this.state.show_confirmation ? 
          <Overlay on_cancel={() => this.setState({show_confirmation: false})} title={this.state.update_loading ? "Confirming changes..." : "Confirm changes"} width="460px">
            <div>
              <p>
                You need to type your password to confirm these changes.
              </p>
              <MyInput onKeyDown={(e) => e.code === 'Enter' ? this.update_payor({email: this.state.email, name: this.state.name}) : null} onChange={(e) => this.setState({typed_password_confirmation: e.target.value.length < this.MAX_PASSWORD_LENGTH ? e.target.value : this.state.typed_password_confirmation})} value={this.state.typed_password_confirmation} onFocus={() => this.setState({password_confirmation_status: 1})} onBlur={() => console.log("Password confirmation blurred")} status={this.state.password_confirmation_status} note={this.state.name_status === 3 ? this.state.name_error : null} label="Password confirmation" type="password" />
              <button className="std-button" onClick={() => this.update_payor({email: this.state.email, name: this.state.name})}>
                Confirm
              </button>
            </div>
          </Overlay> : null}
        
        {this.state.show_change_password ? 
          <Overlay on_cancel={() => this.setState({show_change_password: false})} title="Change password" width="460px">
            <div>
              <h4>
                Old password
              </h4>
              <div className="myinput-wrapper-centre">
                <MyInput onChange={this.on_type_old_password} value={this.state.old_password} onFocus={() => this.setState({old_password_status: 1})} onBlur={null} status={this.state.old_password_status} note={this.state.old_password_status === 3 ? this.state.old_password_message : null} label="Old password" type="password" />
              </div>
              <h4>
                New password
              </h4>
              <div className="myinput-wrapper-centre">
                <MyInput onChange={this.on_type_new_password} value={this.state.new_password} onFocus={() => this.setState({new_password_status: 1})} onBlur={this.on_blur_new_password} status={this.state.new_password_status} note={this.state.new_password_status === 3 ? this.state.new_password_message : null} label="New password" type="password" />
              </div>
              <br />
              <div style={{fontSize: '14px', color: 'grey'}}>Password strength: {this.state.new_password_status > 0 ? <span style={{color: ["red","#e6a330","#e6a330","#5158e0","#32bfb8","#32bfb8"][this.state.password_score + 1], fontWeight: "bold"}}>{["Too short","Very Weak","Weak","Moderate","Strong","Very Strong"][this.state.password_score + 1]}</span> : null}</div>
              {this.state.new_password_status > 0 && this.state.password_evaluation != null ? 
                <div style={{fontSize: '13px', color: 'grey'}}>
                  Time to crack: {this.state.password_evaluation.crack_times_display.online_no_throttling_10_per_second}, {this.state.password_evaluation.crack_times_display.offline_slow_hashing_1e4_per_second} in case of breach
                </div> : null}
              <br />
              <div className="myinput-wrapper-centre">
                <MyInput onChange={(e) => this.setState({new_password_confirmation: e.target.value})} value={this.state.new_password_confirmation} onFocus={() => this.setState({new_password_confirmation_status: 1})} onBlur={this.on_blur_new_password_confirmation} status={this.state.new_password_confirmation_status} note={this.state.new_password_confirmation_status === 3 ? this.state.new_password_confirmation_message : null} label="Confirm new password" type="password" />
              </div>
              {this.state.new_password_status === 2 && this.state.new_password_confirmation_status === 2 ? 
                <button className="std-button" onClick={() => this.update_password({password: this.state.new_password})}>
                  {this.state.new_password_loading ? "Changing..." : "Change"}
                </button> : null}
            </div>
          </Overlay> : null}
        
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <h3>
          Mutable information
        </h3>
        {/*<table className="account-table"><tbody>
          <tr>
            <td>
              <div>Name</div>
            </td>
            <td>
              <MyInput onChange={this.on_type_name} value={this.state.name} onFocus={() => this.setState({name_status: 1})} onBlur={this.on_blur_name} status={this.state.name_status} note={this.state.name_status === 3 ? this.state.name_error : null} label="Name" id="amount-input" />
            </td>
          </tr>
        </tbody></table>*/}
        <MyInput onChange={this.on_type_name} value={this.state.name} onFocus={() => this.setState({name_status: 1})} onBlur={this.on_blur_name} status={this.state.name_status} note={this.state.name_status === 3 ? this.state.name_error : null} label="Name" id="amount-input" />
        <MyInput onChange={this.on_type_email} value={this.state.email} onFocus={() => this.setState({email_status: 1})} onBlur={this.on_blur_email} status={this.state.email_status} note={this.state.email_status === 3 ? this.state.email_error : null} label="Email" />
        {[this.state.name_status, this.state.email_status].every((v) => [0,2].includes(v)) && this.state.changed ? 
          <button onClick={() => this.setState({show_confirmation: true})} className="std-button">
            Change
          </button> : null}
        <h4>
          Password
        </h4>
        <button className="std-button" onClick={() => this.setState({show_change_password: true})}>
          Change password
        </button>
      </div>
    );
  }
}

export default PayorAccountSecurity
