import React from "react"
import PropTypes from "prop-types"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement, // doughtnut
} from 'chart.js';
import { Line } from 'react-chartjs-2';

class DashboardInvoicesGraph extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data_issued: [],
      num_days: Number(this.props.num_days),
      labels: [],
      invoice_amounts: null,
      invoice_paid: null,
      show_chart: false,
      chart_options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
            text: 'Payments',
          }
        },
        scales: {
          y: {
            type: 'linear',
            position: 'left',
            title: {
              display: true,
              text: 'Amount ($)',
            },
            ticks: {
              callback: (value) => `$${value}`, // Formatting y-axis as money
            },
          },
          x: {
            title: {
              display: true,
              text: 'Months',
            },
          }
        }
      }
    };

    this.get_invoice_time_data = this.get_invoice_time_data.bind(this);

    this.GMT_DIFF = String(new Date()).split('GMT')[1].split(' ')[0];

  }

  componentDidMount(){
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend,
      ArcElement
    );
    this.get_invoice_time_data('sum',['amount'],0.01,'invoice_amounts','Issued amount','#040dba');
    this.get_invoice_time_data('sum',['amount_paid'],0.01,'invoice_paid','Paid amount','#32bfb8');
  }

  get_invoice_time_data(fn, cols, multiplier, state_key, label, line_colour){
    const unix_dates = Array.from({length: this.state.num_days}, (_,j) => j).map(
      (k,i) => Number(new Date([new Date()].map((z) => [z.getFullYear(),(z.getMonth() < 9 ? '0' : '') + (z.getMonth()+1),(z.getDate() < 10 ? '0' : '') + z.getDate()].join('-'))[0]+'T00:00:00'+this.GMT_DIFF)) - 86400000*k
    );
    let queries = unix_dates.map(
      (d,i) => ({
        objname: 'Invoice',
        name: 'date:'+(new Date(d)).toISOString().split('T')[0],
        statement: "created_at < '"+(new Date(d + 86400000)).toISOString().split('T').join(' ').split('.')[0]+"' AND created_at > '"+(new Date(d)).toISOString().split('T').join(' ').split('.')[0]+"'",
        function: fn,
        columns: cols
      })
    );
    console.log('queries');
    console.log(queries);
    fetch("/api/stats", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({queries: queries})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log('req complete');
      console.log(json);
      let new_state = {
        labels: unix_dates.map((d) => String(new Date(d)).slice(4,10)).reverse(),
        show_chart: true
        //invoice_amounts: {type:'line',label:'Amts',data:unix_dates.map((d,i) => json.queries['date:'+(new Date(d)).toISOString().split('T')[0]] * multiplier).reverse(),borderColor: '#040dba'}
      };
      new_state[state_key] = {type:'line',label:label,data:unix_dates.map((d,i) => json.queries['date:'+(new Date(d)).toISOString().split('T')[0]] * multiplier).reverse(),borderColor: line_colour}
      this.setState(new_state);
    });
  }

  get_data_3(){
    const dates = Array.from({length: this.state.num_days}, (_,j) => j).map(
      (d,i) => (new Date().toISOString().split('T')[0] + 'T00:00:00'+this.GMT_DIFF)
    );
    let queries = dates.map(
      (d,i) => ({
        objname: 'Invoice',
        name: 'date:'+d.split('T')[0],
        statement: "created_at < '" + new Date(Number(new Date(d)) + 86400000).toISOString().split('T')[0]+'T00:00:00' + this.GMT_DIFF + "' AND created_at > '" + d + "'",
        function: 'sum',
        columns: ['amount']
      })
    );
    fetch("/api/stats", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({queries: queries.reverse()})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log('req complete');
      console.log(json);
      this.setState({
        labels: dates.map((d) => String(new Date(d)).slice(4,10)).reverse(),
        show_chart: true,
        invoice_amounts: {type:'line',label:'Amts',data:dates.map((d,i) => json.queries['date:'+String(d.split('T')[0])] / 100.0),borderColor: '#040dba'}
      });
    });
  }

  get_data_2(){
    const dates = Array.from({ length: this.state.num_days + 1 }, (_, j) => j).map(
      (d,i) => (new Date(Date.now() + 86400000 - 86400000*d)).toISOString().split('T')[0]+'T00:00:00' + this.GMT_DIFF
    );
    console.log('dates');
    console.log(dates);
    const labels = Array.from({ length: this.state.num_days + 1 }, (_, j) => j).map(
      (d,i) => String(new Date(Date.now() - 86400000*d)).slice(4,10)
    );
    let queries = Array.from({ length: this.state.num_days }, (_, j) => j).map((x,i) => ({
      objname: 'Invoice',
      name: 'date:'+String(dates[i].split('T')[0]),
      statement: "created_at < '" + dates[i] + "' AND created_at > '" + dates[i+1]+"'",
      function: 'sum',
      columns: ['amount']
    }));
    console.log('queries:');
    console.log(queries);
    fetch("/api/stats", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({queries: queries})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log('req complete');
      console.log(json);
      this.setState({
        labels: labels,
        show_chart: true,
        invoice_amounts: {type:'line',label:'Amts',data:dates.map((d,i) => json.queries['date:'+String(d.split('T')[0])] / 100.0),borderColor: '#040dba'}
      });
    });
  }

  componentDidUpdate(prev_props){
    if (prev_props.num_days !== this.props.num_days){
      console.log("componentDidUpdate!");
      this.setState({
        num_days: Number(this.props.num_days)
      }, () => {
        this.componentDidMount();
      });
    }
  }

  render () {
    console.log('invoice graph state');
    console.log(this.state);
    return (
      <div>
        {this.state.show_chart ? 
          <Line options={this.state.chart_options} data={{datasets: ['invoice_amounts','invoice_paid'].map((n) => this.state[n]).filter((v) => ![null,undefined].includes(v)), labels: this.state.labels}} />
          : null}
      </div>
    );
  }
}

export default DashboardInvoicesGraph
