import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"
import MyInput from "./MyInput.js"
class OpenBankAccount extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      show_page: false,
      tags: [],
      tag2banks: {},
      banks: {
        'cba':{
          name: 'Commonwealth Bank of Australia',
          tags: ['commonwealth','bank','australia','commbank','cba'],
          url: 'https://www.my.commbank.com.au/netbank/Logon/Logon.aspx',
          icon: 'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/bank_logos/cba.jpg'
        },
        'westpac':{
          name: 'Westpac Banking Corporation',
          tags: ['westpac','wbc'],
          url: 'https://banking.westpac.com.au/wbc/banking/handler?TAM_OP=login&segment=personal&logout=false',
          icon: 'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/bank_logos/westpac.png'
        },
        'anz':{
          name: 'Australian and New Zealand Banking Group',
          tags: ['anz','australian','new zealand','banking'],
          url: 'https://login.anz.com/internetbanking',
          icon: 'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/bank_logos/anz.jpg'
        },
        'nab':{
          name: 'National Australia Bank',
          tags: ['nab','national','australia','bank'],
          url: 'https://ib.nab.com.au/login',
          icon: 'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/bank_logos/nab.jpg'
        },
        'banksa':{
          name: 'Bank SA',
          tags: ['sa','bank','banksa'],
          url: 'https://ibanking.banksa.com.au/ibank/loginPage.action',
          icon: 'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/bank_logos/banksa.png'
        },
        'bankwest':{
          name: 'Bankwest',
          tags: ['west','bank'],
          url: 'https://ibs.bankwest.com.au/Session/PersonalLogin',
          icon: 'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/bank_logos/bankwest.jpg'
        },
        'bendigobank':{
          name: 'Bendigo Bank',
          tags: ['bendigo','tags'],
          url: 'https://banking.bendigobank.com.au/Logon/login.page',
          icon: 'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/bank_logos/bendigobank.jpeg'
        },
        'bom':{
          name: 'Bank of Melbourne',
          tags: ['melbourne','bank','of'],
          url: 'https://ibanking.bankofmelbourne.com.au/ibank/loginPage.action',
          icon: 'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/bank_logos/bom.png'
        },
        'ing':{
          name: 'ING Direct',
          tags: ['ing','direct'],
          url: 'https://www.ing.com.au/securebanking',
          icon: 'https://paysolvepublic.s3.ap-southeast-2.amazonaws.com/bank_logos/ing.png'
        }
      },
      results: [],
      typed: ""
		};

    this.handle_type_query = this.handle_type_query.bind(this);
    this.handle_key_down = this.handle_key_down.bind(this);

  }

  componentDidMount(){
    let tag2banks = {};
    let i;
    for (const [key,val] of Object.entries(this.state.banks)){
      val.tags.forEach(
        (tag) => {
          if (tag in tag2banks){
            tag2banks[tag].push(key);
          } else {
            tag2banks[tag] = [key];
          }
      });
    }
    this.setState({
      tag2banks: tag2banks,
      results: Object.keys(this.state.banks),
      tags: Object.keys(tag2banks)
    });
  }

  handle_type_query(e){
    const typed = e.target.value.toLowerCase();
    if (typed.trim().length === 0){
      return this.setState({
        results: Object.keys(this.state.banks)
      });
    }
    const words = typed.split(' ').filter((w) => w.length > 0);
    let results = [];
    let i, j;
    let flag;
    for (const [key,bank] of Object.entries(this.state.banks)){
      flag = false;
      for (i = 0 ; i < words.length ; i++){
        for (j = 0 ; j < bank.tags.length ; j++){
          if (bank.tags[j].slice(0,words[i].length) === words[i]){
            results.push(key);
            flag = true;
          }
          if (flag){
            break;
          }
        }
        if (flag){
          break;
        }
      }
    }
    this.setState({
      results: results
    });
  }

  handle_key_down(e){
    if (e.keyCode === 13 && this.state.results.length === 1){
      window.open(this.state.banks[this.state.results[0]].url,'_blank');
    }
  }

  render () {
    console.log(this.state);
    return (
      <div>

        {this.state.show_page ? 
          <Overlay on_cancel={() => this.setState({show_page: false})} title="Open your bank account" width="540px">
            <div>
              <div className="myinput-wrapper-centre">
                <MyInput onChange={this.handle_type_query} onKeyDown={this.handle_key_down} value={this.state.typed} status={1} label="Your bank" id="open-bank-input" />
              </div>
              <div style={{width: '460px', margin: 'auto', height: '500px', border: 'solid', borderWidth: '1px', borderColor: 'grey', overflowY: 'scroll'}}>
                {this.state.results.map(
                  (bank, index) =>
                  <div key={index} className="openbank-result">
                    <table><tbody>
                      <tr>
                        <td>
                          {this.state.banks[bank].icon === null ? null : <img src={this.state.banks[bank].icon} />}
                        </td>
                        <td>
                          {this.state.banks[bank].name}
                        </td>
                        <td>
                          <button className="button-blue" onClick={() => {
                            window.open(
                              this.state.banks[bank].url, '_blank'
                            )
                          }}>
                            <i className="fa-solid fa-arrow-up-right-from-square"></i> Open
                          </button>
                        </td>
                      </tr>
                    </tbody></table>
                  </div>
                )}
              </div>
            </div>
          </Overlay> : null}

        <button className="std-button" onClick={() => this.setState({show_page: true}, () => setTimeout(() => {document.getElementById('open-bank-input').focus()},250))}>
          <i className="fa-solid fa-building-columns"></i> Open Bank Account
        </button>
      </div>
    );
  }
}

export default OpenBankAccount
