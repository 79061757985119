import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"
import Toggle from "./Toggle.js"
import MyInput from "./MyInput.js"

class AccountUsers extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      users: [],
      loaded: false,
      selected_index: -1,
      user: null,
      user_window_status: 0,
      update_loading: false,
      messages: [],
      show_add_user: false,
      user_name: "",
      user_name_status: 0,
      user_name_error: "",
      email: "",
      email_status: 0,
      email_error: "",
      loading_resend: false
    };

    this.toggle_admin = this.toggle_admin.bind(this);
    this.update_user = this.update_user.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.on_type_user_name = this.on_type_user_name.bind(this);
    this.on_blur_user_name = this.on_blur_user_name.bind(this);
    this.on_type_email = this.on_type_email.bind(this);
    this.on_blur_email = this.on_blur_email.bind(this);
    this.on_focus_email = this.on_focus_email.bind(this);
    this.create_user = this.create_user.bind(this);
    this.resend_invitation = this.resend_invitation.bind(this);

    this.USER_STATUS_TO_NAME = {'-2':'Suspended by PaySolve','-1':'Suspended by Organisation','1':'Initiated','2':'Active','3':'Awaiting reset'};
    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    this.USER_NAME_LENGTH_LIMIT = 32;
    this.MIN_USER_NAME_LENGTH = 5;

  }

  componentDidMount(){
    if (![null,undefined].includes(this.props.data)){
      this.setState({
        data: this.props.data,
        users: this.props.data.users.toReversed(),
        loaded: true
      });
    } else {
      this.setState({
        loaded: false
      });
    }
  }

  toggle_admin(v){
    let users = this.state.users;
    users[this.state.selected_index].admin = v;
    this.setState({
      users: users
    });
  }

  update_user(action){
    this.setState({
      update_loading: true
    });
    fetch("/api/user/admin", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        user_code: this.state.user.code,
        action_code: action
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to update user.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      let users = this.state.users;
      users[this.state.selected_index] = json.user;
      this.setState({
        update_loading: false,
        users: users,
        user: json.user,
        user_window_status: 0
      });
      this.add_message("Successfully updated user.",'success');
    });
  }

  create_user(){
    this.setState({
      update_loading: true
    });
    fetch("/api/users/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        user: {
          email: this.state.email,
          name: this.state.user_name
        }
      })
    }).then( (response) => {
      return response.json();
      if (response.ok){
        return response.json();
      }
      this.setState({
        update_loading: false
      });
      console.log(response.json());
      this.add_message("Failed to create user." + (response.json()['errors'] === undefined ? "" : "Errors: "+response.json()['errors']),'danger');
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        let users = this.state.users;
        users.push(json.user);
        this.setState({
          update_loading: false,
          users: users,
          user: json.user,
          selected_index: users.length - 1,
          user_window_status: 0,
          show_add_user: false
        });
        this.props.on_successful_update('company');
        this.add_message("Successfully created user.",'success');
      } else {
        this.add_message("Failed to create user. Errors: "+json.error,'danger');
        this.setState({
          update_loading: false
        });
      }
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  on_type_user_name(e){
    console.log(e);
    if ("1234567890QWERTYUIOPASDFGHJKLZXCVBNM-qwertyuiopasdfghjklzxcvbnm ".includes(e.target.value.slice(-1)) && e.target.value.length < this.USER_NAME_LENGTH_LIMIT){
      this.setState({
        user_name: e.target.value
      });
    }
  }

  on_blur_user_name(){
    if (this.state.user_name.length < this.MIN_USER_NAME_LENGTH || this.state.user_name.length > this.USER_NAME_LENGTH_LIMIT){
      this.setState({
        user_name_status: 3,
        user_name_error: "Must be between "+String(this.MIN_USER_NAME_LENGTH) + " and " + String(this.USER_NAME_LENGTH_LIMIT) + " characters."
      });
    } else {
      this.setState({
        user_name_status: 2
      });
    }
  }

  on_type_email(e){
    const typed = e.target.value;
    if (/^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~@.]*$/.test(typed)){
      this.setState({
        email: typed
      });
    }
  }

  on_blur_email(){
    console.log(this.state);
    if (!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.email)){
      this.setState({
        email_status: 3,
        email_error: "You must enter a valid email."
      });
    } else if (this.state.data.users.map((u) => u.email.toLowerCase()).includes(this.state.email.toLowerCase())){
      this.setState({
        email_status: 3,
        email_error: "Email cannot already be used."
      });
    } else {
      this.setState({
        email_status: 2,
        email_error: ""
      });
    }
  }

  on_focus_email(){
    this.setState({
      email_status: 1,
      email_error: null
    });
  }

  resend_invitation(){
    this.setState({
      loading_resend: true
    });
    ///api/user/:code/resend_confirmation
    fetch("/api/user/"+this.state.user.code+"/resend_confirmation", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to update user.",'danger');
      this.setState({
        loading_resend: false,
        selected_index: -1,
        user: null
      });
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.add_message("Successfully regenerated and sent User confirmation.",'success');
      } else {
        this.add_message("Failed to resend confirmation.",'danger');
      }
      this.setState({
        loading_resend: false,
        selected_index: -1,
        user: null
      });
    });
  }

  componentDidUpdate(prev_props){
    if (prev_props.data !== this.props.data){
      this.componentDidMount();
    }
  }

  render () {
    let messages = (this.state.messages.map(
      (message, index) =>
      <div className={"flash flash-"+message.type} key={index}>
        <table><tbody>
          <tr>
            <td style={{fontSize: '18px'}}>
              <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
            </td>
            <td>
              {message.content}
            </td>
          </tr>
        </tbody></table>
      </div>
    ));
    return (this.state.loaded ? 
      <div style={{marginLeft: '25px'}}>

        {messages}

        {this.state.show_add_user ? 
          <Overlay on_cancel={() => this.setState({show_add_user: false, email: "", user_name: "", email_status: 0, email_error: "", user_name_status: 0, user_name_error: ""})} title="Add User" width="500px">
            <div>
              {this.state.messages.map(
                (message, index) =>
                <div className={"flash flash-"+message.type} key={index}>
                  <table><tbody>
                    <tr>
                      <td style={{fontSize: '18px'}}>
                        <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                      </td>
                      <td>
                        {message.content}
                      </td>
                    </tr>
                  </tbody></table>
                </div>
              )}
              <div className="myinput-wrapper-centre">
                <MyInput onChange={this.on_type_user_name} value={this.state.user_name} status={this.state.user_name_status} note={this.state.user_name_status === 3 ? this.state.user_name_error : null} onBlur={this.on_blur_user_name} label="New User name" /><br />
                <MyInput onChange={this.on_type_email} value={this.state.email} status={this.state.email_status} onFocus={this.on_focus_email} note={this.state.email_status === 3 ? this.state.email_error : null} onBlur={this.on_blur_email} label="New User's email" />
                {this.state.user_name_status === 2 && this.state.email_status === 2 ? 
                  <div style={{textAlign: 'center'}}>
                    <button className="std-button" onClick={this.create_user}>
                      {this.state.update_loading ? "Creating User..." : "Create User"}
                    </button>
                  </div> : null}
              </div>
            </div>
          </Overlay> : null}

        {this.state.selected_index >= 0 ? 
          <Overlay on_cancel={() => this.setState({selected_index: -1, user_window_status: 0})} title={"User: "+this.state.user.name} width="500px">
            <div style={{minHeight: '320px', position: 'relative'}}>
              {[<div>
                {messages}
                <table className="account-user-table"><tbody>
                  {[["name","Name"],["email","Email"],["code","User code"]/*,["admin","Administrator?"],["status","Status"]*/].map(
                    (attr, index) =>
                    <tr key={index}>
                      <td>
                        {attr[1]}
                      </td>
                      <td>
                        {this.state.user[attr[0]]}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      Administrator?
                    </td>
                    <td>
                      {/*<Toggle on_toggle={this.toggle_admin} init_value={this.state.users[this.state.selected_index].admin} />*/}
                      {this.state.user.admin ? "Yes" : "No"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Status
                    </td>
                    <td>
                      {this.USER_STATUS_TO_NAME[String(this.state.user.status)]}
                    </td>
                  </tr>
                </tbody></table>
                <div style={{position: 'absolute', bottom: '0px', left: '0px', width: '500px'}}>
                  <table style={{width: '100%', textAlign: 'center'}}><tbody>
                    <tr>
                      <td>
                        {[-2,0,1].includes(this.state.user.status) ? null : 
                          <button className="std-button" onClick={() => this.setState({user_window_status: 1})}>
                            {this.state.user.status === -1 ? "Reactivate" : "Suspend"}
                          </button>}
                      </td>
                      <td>
                        {this.state.user.status > 1 ? 
                          <button className="std-button" onClick={() => this.setState({user_window_status: 2})}>
                            {this.state.user.admin ? "Remove Admin" : "Make Admin"}
                          </button> : 
                          <button className="std-button" onClick={this.resend_invitation}>
                            {this.state.loading_resend ? "Resending..." : "Resend Invitation"}
                          </button>}
                      </td>
                      <td>
                        <button className="std-button" style={{backgroundColor: 'red'}} onClick={() => this.setState({user_window_status: 3})}>
                          Delete User
                        </button>
                      </td>
                    </tr>
                  </tbody></table>
                </div>
              </div>,
              
              <div style={{position: 'absolute', height: '100%'}}>
                {messages}
                <p className="general-p" style={{paddingLeft: '20px', paddingRight: '20px', textAlign: 'center'}}>
                  Are you sure you wish to {this.state.user.status > 0 ? "suspend" : "reactivate"} <b>{this.state.user.name}</b>?
                </p>
                <div style={{position: 'absolute', bottom: '0px', left: '0px'}}>
                  <button className="std-button" onClick={() => this.update_user(this.state.user.status > 0 ? "SUSPEND" : "REACTIVATE")}>
                    Yes
                  </button>
                  <button className="std-button" onClick={() => this.setState({user_window_status: 0})}>
                    No
                  </button>
                </div>
              </div>,
            
              <div style={{position: 'absolute', height: '100%'}}>
                {messages}
                <p className="general-p" style={{paddingLeft: '20px', paddingRight: '20px', textAlign: 'center'}}>
                  Are you sure you wish to <b>{this.state.user.admin ? "grant" : "remove"} administrative privileges</b> for <b>{this.state.user.name}</b>?
                </p>
                <div style={{position: 'absolute', bottom: '0px', left: '0px'}}>
                  <button className="std-button" onClick={() => this.update_user(this.state.user.admin ? "MAKE_ADMIN" : "REMOVE_ADMIN")}>
                    Yes
                  </button>
                  <button className="std-button" onClick={() => this.setState({user_window_status: 0})}>
                    No
                  </button>
                </div>
              </div>,
            
              <div style={{position: 'absolute', height: '100%'}}>
                {messages}
                <p className="general-p" style={{paddingLeft: '20px', paddingRight: '20px', textAlign: 'center'}}>
                  Are you sure you wish to <b>permanently delete</b> the user <b>{this.state.user.name}</b>?
                </p>
                <div style={{position: 'absolute', bottom: '0px', left: '0px'}}>
                  <button className="std-button" onClick={() => this.update_user("DELETE")}>
                    Yes
                  </button>
                  <button className="std-button" onClick={() => this.setState({user_window_status: 0})}>
                    No
                  </button>
                </div>
              </div>][this.state.user_window_status]}
            </div>
          </Overlay> : null}

        {this.state.users.map(
          (user, index) =>
          <div key={index} className="account-user-card" onClick={() => this.setState({selected_index: this.state.data.admin ? index : -1, user: this.state.users[index]})}>
            <div style={{fontSize: '18px', fontWeight: 'bold'}}>
              {user.name}
            </div>
            <table><tbody>
              <tr>
                <td>
                  Email
                </td>
                <td>
                  {user.email}
                </td>
              </tr>
              <tr>
                <td>
                  User code
                </td>
                <td>
                  {user.code}
                </td>
              </tr>
              <tr>
                <td>
                  Status
                </td>
                <td>
                  {this.USER_STATUS_TO_NAME[user.status]}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}
        {this.state.data.admin ? 
          <div className="account-user-card" style={{textAlign: 'center', fontSize: '13px', color: 'grey'}} onClick={() => this.setState({show_add_user: true})}>
            <i className="fa-solid fa-plus"></i> Add user
          </div> : null}

        {this.state.data.user_count > this.state.users.length ? 
          <div className="account-user-card" style={{textAlign: 'center'}}>
            <span className="subtle-p">Load more</span>
          </div> : null}
      </div>
      : <div>
        Loading...
      </div>);
  }
}

export default AccountUsers
