import React from "react"
import PropTypes from "prop-types"
import MbPayorAccountSecurity from "./MbPayorAccountSecurity.js"
class MbPayorAccount extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      tabs: [
        {
          title: 'General',
          code: 'general',
          endpoint: 'info',
          icon: 'gear',
          data: null
        },{
          title: 'Security',
          code: 'security',
          endpoint: 'info',
          icon: 'shield',
          data: null
        },{
          title: 'Bank Account',
          code: 'bank',
          endpoint: 'info',
          icon: 'building-columns',
          data: null
        }/*,{
          title: 'Organisation',
          code: 'company',
          endpoint: 'company',
          icon: 'briefcase',
          data: null
        },{
          title: 'Users',
          code: 'users',
          endpoint: 'company',
          icon: 'users',
          data: null
        },{
          title: 'Security',
          code: 'security',
          endpoint: 'general',
          icon: 'shield',
          data: null
        },{
          title: 'Sessions',
          code: 'sessions',
          endpoint: 'sessions',
          icon: 'key',
          data: null
        },{
          title: 'Verification',
          code: 'verification',
          endpoint: 'verification',
          icon: 'circle-check',
          data: null
        },{
          title: 'Billing',
          code: 'billing',
          endpoint: 'billing',
          icon: 'file-invoice-dollar',
          data: null
        },{
          title: 'Cards',
          code: 'cards',
          endpoint: 'company',
          icon: 'credit-card',
          data: null
        }*/
      ],
      selected_index: 0,
      selected_code: 'general',
      //data: null,
      loading: true,
      messages: [],
      locked: false,
      loaded_endpoints: []
		};

    this.select_index = this.select_index.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.retrieve_details = this.retrieve_details.bind(this);
    this.on_successful_update = this.on_successful_update.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  componentDidMount(){
    this.select_index(0);
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  on_successful_update(endpoint){
    if (typeof endpoint === 'string'){
      this.retrieve_details(endpoint);
    } else if (typeof endpoint === 'object'){
      endpoint.forEach((e) => this.retrieve_details(e));
    }
  }

  select_index(index){
    console.log('a');
    if (!this.state.locked){
      console.log('b');
      this.setState({
        selected_index: index,
        selected_code: this.state.tabs[index].code,
        loading: true,
        locked: true
      });
      if (!this.state.loaded_endpoints.includes(this.state.tabs[index].endpoint)){
        console.log('c');
        // call to get data here
        this.retrieve_details(this.state.tabs[index].endpoint);
      } else {
        this.setState({
          selected_index: index,
          selected_code: this.state.tabs[index].code,
          locked: false,
          loading: false
        });
      }
    }
  }

  retrieve_details(endpoint){
    console.log("Endpoint:");
    console.log(endpoint);
    fetch("/api/payor/"+endpoint, {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to obtain data.",'danger');
      this.setState({
        loading: false,
        locked: false
      });
      throw new Error('Request fail');
    }).then(json => {
      console.log("Returned JSON:");
      console.log(json);
      let tabs = this.state.tabs;
      //tabs[index].data = json.data;
      let i;
      for (i = 0 ; i < tabs.length ; i++){
        tabs[i].data = tabs[i].endpoint === endpoint ? json.data : tabs[i].data;
      }
      let loaded_endpoints = this.state.loaded_endpoints;
      loaded_endpoints.push(endpoint);
      console.log("tabs");
      console.log(tabs);
      this.setState({
        tabs: tabs,
        loading: false,
        locked: false,
        loaded_endpoints: loaded_endpoints
      });
    });
  }


  render () {
    console.log(this.state);
    return (
      <div>

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}


        <div className="mb-form-section">
          <h2>
            My Payor Account
          </h2>
          <h4>
            Name
          </h4>
          <div style={{fontFamily: 'Roboto Mono'}}>
            {this.props.name}
          </div><br />
          <h4>
            Email
          </h4>
          <div style={{fontFamily: 'Roboto Mono'}}>
            {this.props.email}
          </div><br />
          <h4>
            Existing since
          </h4>
          <div style={{fontFamily: 'Roboto Mono'}}>
            {(new Date(this.props.created_at)).toLocaleString()}
          </div><br />
        </div>

        <div className="mb-form-section">
          <MbPayorAccountSecurity data={this.state.tabs[this.state.selected_index].data} on_successful_update={this.on_successful_update} auth_token={this.props.auth_token} />
        </div>
      </div>
    );
  }
}

export default MbPayorAccount
