import React from "react"
import PropTypes from "prop-types"
class MbCheckbox extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      checked: this.props.init_value
    };

    this.on_toggle = this.on_toggle.bind(this);

  }

  on_toggle(){
    if (this.props.on_toggle != undefined){
      this.props.on_toggle(!this.state.checked);
    }
    this.setState({
      checked: !this.state.checked
    });
  }

  componentDidUpdate(prev_props){
    if (prev_props.init_value !== this.props.init_value){
      this.setState({
        checked: this.props.init_value
      });
    }
  }

  render () {
    return (
      <div style={{width: '1.2em', height: '1.2em', border: 'solid', borderWidth: '1px', borderColor: '#c9c9c9', backgroundColor: this.state.checked ? '#32bfb8' : '#fff', color: '#fff', fontSize: '1.5em', textAlign: 'center'}} onClick={this.on_toggle}>
        {this.state.checked ? <i className="fa-solid fa-check"></i> : null}
      </div>
    );
  }
}

export default MbCheckbox
