import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"

class AdminAccount extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      init_loaded: false,
      account: null,
      messages: [],
      changed: false,
      loading: false,
      payid_status: 0//,
      //typed_payid: ""
    };

    this.retrieve_details = this.retrieve_details.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.on_type_payid = this.on_type_payid.bind(this);
    this.update_account = this.update_account.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  componentDidMount(){
    this.retrieve_details();
  }

  retrieve_details(){
    // /api/invoice/:code
    fetch("/api/account/"+this.props.account_code+"/admin", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        account: json.account,
        init_loaded: true//,
        //typed_payid: json.account.main_payid
      });
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  on_type_payid(e){
    let account = this.state.account;
    account.main_payid = e.target.value;
    this.setState({
      account: account,
      changed: true
    });
  }

  update_account(){
    // /api/account/:code/update
    this.setState({
      loading: true
    });
    fetch("/api/account/"+this.state.account.internal_code+"/update", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        account: {
          main_payid: [null,undefined].includes(this.state.account.main_payid) || this.state.account.main_payid.length.length === 0 ? null : this.state.account.main_payid
        }
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to update.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.setState({
          account: json.account,
          loading: false
        });
        this.add_message("Successfully updated Account.",'success');
      } else {
        this.add_message("Failed to update Account.",'danger');
      }
    });
  }

  render () {
    console.log(this.state);
    return (this.state.init_loaded ? 
      <div className="form-section">

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <h2>
          <span style={{color: 'grey'}}>
            Bank Account
          </span>{" "+this.state.account.name}
        </h2>

        <table className="account-general-table" style={{width: '90%'}}><tbody>
          <tr>
            <td>
              Internal Code
            </td>
            <td>
              {this.state.account.internal_code}
            </td>
          </tr>
          <tr>
            <td>
              BSB
            </td>
            <td>
              {this.state.account.bsb.slice(0,3)+'-'+this.state.account.bsb.slice(3,6)}
            </td>
          </tr>
          <tr>
            <td>
              Account number
            </td>
            <td>
              {this.state.account.account_number}
            </td>
          </tr>
          <tr>
            <td>
              Account name
            </td>
            <td>
              {this.state.account.account_name}
            </td>
          </tr>
          <tr>
            <td>
              PayID
            </td>
            <td>
              <MyInput onChange={this.on_type_payid} value={this.state.account.main_payid} onBlur={() => null} status={this.state.payid_status} onFocus={() => this.setState({payid_status: 1})} note={null} label="PayID" />
            </td>
          </tr>
          <tr>
            <td>
              Currency
            </td>
            <td>
              {this.state.account.currency}
            </td>
          </tr>
          <tr>
            <td>
              Financial Institution
            </td>
            <td>
              {this.state.account.institution}
            </td>
          </tr>
          <tr>
            <td>
              Basiq Institution Code
            </td>
            <td>
              {this.state.account.institution_identifier}
            </td>
          </tr>
          <tr>
            <td>
              Total income counted
            </td>
            <td>
              ${this.amount_to_formatted_string(this.state.account.total_transaction_value)}
            </td>
          </tr>
          <tr>
            <td>
              Total transactions counted
            </td>
            <td>
              {this.state.account.total_transactions}
            </td>
          </tr>
        </tbody></table>

        {this.state.changed ? 
          <button className="std-button" onClick={this.update_account}>
            {this.state.loading ? "Saving..." : "Save"}
          </button> : null}

      </div> : 
      <div>Loading...</div>
    );
  }
}

export default AdminAccount
