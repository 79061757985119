import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"
import LoginPayor from "./LoginPayor.js"
import PayorCreate from "./PayorCreate.js"
class ShowRedress extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      public: !!this.props.public,
      redress: this.props.redress,
      redress_payorlink_id: null,
      payor_code: this.props.payor_code,
      show_payor_status: 0,
      messages: [],
      show_remove_payorlink: false
    };

    this.STATUSES_TO_PUBLIC_WORD = {
      '0':'NONE',
      '1':'SUBMITTED',
      '2':'PROCESS',
      '3':'PROCESS',
      '4':'PROCESS',
      '5':'ASSIGNED',
      '6':'ASSIGNED',
      '7':'PAID',
      '8':'FLAGGED',
      '9':'DISMISSED'
    }
    //NO_ACCOUNT, SUBMITTED, SINGLE_MATCH, NO_MATCH, MULTIPLE_MATCHES, ASSIGNED, GENERATED, PAID, FLAGGED, DISMISSED = [0,1,2,3,4,5,6,7,8,9]
    this.STATUSES_TO_COLOUR = { // 040dba e6a330 32bfb8
      '0':'#040dba',
      '1':'#040dba',
      '2':'#040dba',
      '3':'#040dba',
      '4':'#040dba',
      '5':'#32bfb8',
      '6':'#32bfb8',
      '7':'#32bfb8',
      '8':'#e6a330',
      '9':'#e6a330'
    };
    this.PURPOSE_TO_TITLE = {
      '1':'Refund',
      '2':'Paid too much (Invoice)',
      '3':'Wrong code'
    };

    this.save_redress_payor = this.save_redress_payor.bind(this);
    this.remove_payorlink = this.remove_payorlink.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);

    this.MESSAGE_TYPE_TO_COLOUR = {'danger':'red','success':'green','info':'blue'};
    this.MESSAGE_TYPE_TO_ICON = {'danger':'circle-exclamation','warning':'circle-exclamation','info':'circle-info','success':'check'};

  }

  componentDidMount(){
    if (typeof this.props.redress_payorlink_id === 'number'){
      this.setState({
        redress_payorlink_id: Number(this.props.redress_payorlink_id)
      });
    }
  }

  save_redress_payor(){
    fetch("/payorlink?objname=RDS&redress_id="+String(this.state.redress.id), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }/*,
      body: JSON.stringify({})*/
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.add_message("Saved Redress to your Payor account.",'success');
      this.setState({
        redress_payorlink_id: json.payorlink_id
      });
    });
  }

  remove_payorlink(payorlink_id){
    fetch("/payorlink?objname=RDS&payorlink_id="+String(payorlink_id), {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }/*,
      body: JSON.stringify({})*/
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Erro in deleting Payor link.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      this.add_message("Successfully removed link to your Payor account.",'success');
      this.setState({
        redress_payorlink_id: null,
        show_remove_payorlink: false
      });
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  render () {
    console.log(this.state);
    return this.state.redress === null ? null : (!this.state.public && false ? 
      <div>
      </div> : 
      <div className="form-section">

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        {this.state.show_payor_status > 0 ? 
          <Overlay on_cancel={() => this.setState({show_payor_status: 0})} title="Payor Account">
            {this.state.show_payor_status === 1 ? 
              <div>
                <LoginPayor auth_token={this.props.auth_token} />
                <div style={{textAlign: 'center'}}>
                  <p style={{color: 'grey', fontSize: '15px', paddingLeft: '20px', paddingRight: '20px'}}>
                    Don't have a Payor account? It takes 30 seconds to sign up, and you can save and manage all your bills in one place!
                  </p>
                  <button onClick={() => this.setState({show_payor_status: 2})} className="std-button">
                    Create Account
                  </button>
                </div>
              </div> : null}
            {[2,3].includes(this.state.show_payor_status) ? 
              <div>
                <PayorCreate auth_token={this.props.auth_token} on_complete={() => this.setState({show_payor_status: 3})} />
              </div> : null}
            {this.state.show_payor_status === 3 ?
              <div>
                <LoginPayor auth_token={this.props.auth_token} />
              </div> : null}
          </Overlay> : null}
        
          {this.state.show_remove_payorlink ? 
          <Overlay on_cancel={() => this.setState({show_remove_payorlink: false})} title="Remove Link">
            <div>
              <p style={{color: 'grey'}}>
                Are you sure you want to remove the link between this Invoice and your Payor account?
              </p>
              <table><tbody>
                <tr>
                  <td>
                    <button onClick={() => this.remove_payorlink(this.state.redress_payorlink_id)} className="std-button">
                      Remove
                    </button>
                  </td>
                  <td>
                    <button onClick={() => this.setState({show_remove_payorlink: false})} className="std-button">
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody></table>
            </div>
          </Overlay> : null}

        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <div style={{width: '100px'}}>
                <div style={{backgroundColor: this.STATUSES_TO_COLOUR[this.state.redress.status]}} className="status-box">
                  {this.STATUSES_TO_PUBLIC_WORD[this.state.redress.status]}
                </div>
              </div>
            </td>
            <td>
              <div style={{fontSize: '16px', color: 'grey', textAlign: 'right', margin: '5px', fontFamily: 'Roboto Mono'}}>
                {this.state.redress.code}
              </div>
            </td>
          </tr>
        </tbody></table>
        
        <h2>
          <span style={{color: 'grey'}}>Redress</span> {this.state.redress.code}
        </h2>
        <table className="invoice-table" style={{margin: 'auto'}}><tbody>
          <tr>
            <td>
              Purpose
            </td>
            <td>
              {this.PURPOSE_TO_TITLE[this.state.redress.request_type]}
            </td>
          </tr>
          <tr>
            <td>
              Created at
            </td>
            <td>
              {String(new Date(this.state.redress.created_at)).slice(0,33)}
            </td>
          </tr>
          <tr>
            <td>
              Merchant BSB
            </td>
            <td>
              {this.state.redress.merchant_bsb}
            </td>
          </tr>
          <tr>
            <td>
              Merchant Account Number
            </td>
            <td>
              {this.state.redress.merchant_account_number}
            </td>
          </tr>
          <tr>
            <td>
              Merchant Account Name
            </td>
            <td>
              {this.state.redress.merchant_account_name}
            </td>
          </tr>
          <tr>
            <td>
              Claimant BSB
            </td>
            <td>
              {this.state.redress.bsb}
            </td>
          </tr>
          <tr>
            <td>
              Claimant Account Number
            </td>
            <td>
              {this.state.redress.account_number}
            </td>
          </tr>
          <tr>
            <td>
              Claimant Account Name
            </td>
            <td>
              {this.state.redress.account_name}
            </td>
          </tr>
        </tbody></table>
        {this.state.redress_payorlink_id === null ? 
          <button onClick={() => this.state.payor_code === null ? this.setState({show_payor_status: 1}) : this.save_redress_payor()} className="std-button">
            <i className="fa-solid fa-cloud-arrow-down"></i> Save Redress
          </button> : 
          <button className="std-button" onClick={() => this.setState({show_remove_payorlink: true})}>
            <i className="fa-solid fa-check"></i> Saved
          </button>}
      </div>
    );
  }
}

export default ShowRedress
