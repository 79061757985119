import React from "react"
import PropTypes from "prop-types"
import MbInput from "./MbInput";
import MbCheckbox from "./MbCheckbox";

class MbSeriesSearchPayor extends React.Component {
  constructor(props){
    super(props);
    this.RESULTS_PER_PAGE = 5;
    this.state = {
      include_title: false,
      title: "",
      show_results: true,
      results: [],
      limit: this.RESULTS_PER_PAGE,
      total_count: null,
      results_per_page: this.RESULTS_PER_PAGE, // should be the same as limit
      max_num_pages: 5,
      num_pages: null,
      showing_page: 0
    };

    this.search = this.search.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);

  }

  search(page,limit,req_count){
    let body = {series:{created_at:{min:"1970-01-01T10:00:00+10:00",max:"2038-01-19T14:14:08+11:00"}},offset:page*this.state.results_per_page,limit:limit,req_count:req_count};
    /*
      For each search term in state, only include it in the body if state indicates its inclusion. Separate by whether it has min/max or exact value.
    */
    // min/max keys:
    //['created_at'].forEach((k,i) => this.state['include_'+k] ? body['series'][k] = {min:this.state[k+'_min'],max:this.state[k+'_max']} : null);
    // exact keys:
    ['title'].forEach((k,i) => this.state['include_'+k] ? body['series'][k] = this.state[k] : null);
    body['tags'] = this.state.include_tags ? this.state.tags : [];
    fetch("/api/payors/searchseries", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.setState({
        show_results: true,
        results: json.series,
        total_count: json.count === null ? this.state.total_count : json.count,
        num_pages: Math.ceil((json.count === null ? this.state.total_count : json.count) / this.state.results_per_page),
        showing_page: page
      });
    });
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  render () {
    return (
      <div className="mb-form-section">
        <h2>
          My Bill Series
        </h2>

        <table><tbody>
          <tr>
            <td style={{padding: '5px'}}>
              <MbCheckbox on_toggle={(v) => this.setState({include_title: v})} init_value={this.state.include_title} />
            </td>
            <td style={{padding: '5px'}}>
              Search by title
            </td>
          </tr>
        </tbody></table>
        {this.state.include_title || true ? 
          <div style={{padding: '10px'}}>
            <MbInput onChange={(e) => this.setState({title: e.target.value})} value={this.state.title} status={0} label="Title" note={null} />
          </div> : null}

        <button className="std-button" onClick={() => this.search(0,this.state.limit,true)}>
          Search
        </button>

        {this.state.show_results ? 
        <div>
          <table style={{width: '100%'}}><tbody>
            <tr>
              <td>
                <h3>
                  Results ({this.state.total_count})
                </h3>
              </td>
              <td style={{textAlign: 'right'}}>
                {/*<button className="button-blue" onClick={this.search_to_csv}>
                  Download CSV
                </button>*/}
              </td>
            </tr>
          </tbody></table>

          <table id="series-results"><tbody>
            <tr>
              <th>
                Title
              </th>
              <th>
                Billing email
              </th>
              <th>
                Total paid
              </th>
            </tr>
            {this.state.results.map(
              (result, index) =>
              <tr key={index} onClick={() => {window.location.href = "/ser/"+result.code;}}>
                <td>
                  {result.title}
                </td>
                <td>
                  {result.billing_email}
                </td>
                <td>
                  ${this.amount_to_formatted_string(result.total_paid)}
                </td>
              </tr>
            )}
          </tbody></table>
        </div> : null}
      </div>
    );
  }
}

export default MbSeriesSearchPayor
