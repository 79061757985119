import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"
import TextBox from "./TextBox.js"
import MyInput from "./MyInput.js"

class ApikeySandboxManager extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      messages: [],
      api_created: false,
      apikeys: [],
      show_create_api: false,
      show_create_key: false,
      show_new_apikey_result: null,
      loading: false,
      show_delete: false,
      selected_index: -1,
      delete_typed: ""
    };

    this.create_sandbox = this.create_sandbox.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.create_key = this.create_key.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  componentDidMount(){
    fetch("/api/admin/proxy/apikeys", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log('ApikeySandboxManager json:');
      console.log(json);
      if (json.success){
        this.setState({
          api_created: json.api_created,
          apikeys: json.apikeys
        });
      } else {
        this.add_message("Failed to obtain Sandbox API information",'danger');
      }
    });
  }

  create_key(){
    this.setState({
      loading: true
    });
    fetch("/api/admin/proxy/apikeys", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }/*,
      body: JSON.stringify({
        company_code: 
      })*/
    }).then( (response) => {
      if (response.ok || true){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log("JSON success");
      console.log(json);
      if (json.success){
        this.add_message("Successfully added API Key in Sandbox mode",'success');
        this.setState({
          loading: false,
          show_create_key: false,
          show_new_apikey_result: true,
          new_apikey: json.apikey,
          show_new_apikey: false
        });
      } else {
        this.add_message("Failed to add API Key.",'danger');
        this.setState({
          loading: false,
          show_new_apikey_result: false,
          show_create_key: false
        });
      }
    });
  }

  create_sandbox(){
    this.setState({
      loading: true
    });
    fetch("/api/admin/proxy", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }/*,
      body: JSON.stringify({
        apikey: {
          name: this.state.typed_name
        }
      })*/
    }).then( (response) => {
      if (response.ok || true){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log("create_sandbox json:");
      console.log(json);
      if (json.success){
        this.add_message("Successfully created the Sandbox API",'success');
        this.setState({
          api_created: true
        });
        this.componentDidMount();
      } else {
        this.add_message("Failed to create the Sandbox API",'danger');
      }
      this.setState({
        loading: false,
        show_create_api: false
      });
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  render () {
    console.log("General State:");
    console.log(this.state);
    return (
      <div className="form-section">

        {this.state.show_new_apikey_result ? 
          <Overlay on_cancel={() => this.setState({show_new_apikey: false})} title="New API Key" width="550px">
            <div>
              <div className={"flash flash-warning"}>
                <table><tbody>
                  <tr>
                    <td style={{fontSize: '18px'}}>
                      <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON['warning']}></i>
                    </td>
                    <td>
                      Copy this key immediately in a secure location. For security reasons, we cannot show you this key again.
                    </td>
                  </tr>
                </tbody></table>
              </div>
              <TextBox title="Name" content={this.state.new_apikey.name} style={{minHeight: '40px'}} />
              <TextBox title="Key" content={this.state.new_apikey.key} />
              <br />
              <button onClick={() => {
                this.setState({
                  show_new_apikey_result: false
                });
                this.componentDidMount();
              }} className="std-button">
                I have copied it
              </button>
            </div>
          </Overlay> : null}

        {this.state.show_create_key ? 
          <Overlay on_cancel={() => this.setState({show_create_key: false})} title="Create Sandbox API Key">
            <div>
              <p style={{fontSize: '14px'}}>
                Confirm create Key
              </p>
              <table><tbody>
                <tr>
                  <td>
                    <button className="std-button" onClick={this.create_key}>
                      {this.state.loading ? "Creating..." : "Create Key"}
                    </button>
                  </td>
                  <td>
                    <button className="std-button" onClick={() => this.setState({show_create_key: false})}>
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody></table>
            </div>
          </Overlay> : null}
        
        {this.state.show_create_api ? 
          <Overlay on_cancel={() => this.setState({show_create_api: false})} title="Create Sandbox Account">
            <div>
              <p style={{fontSize: '14px'}}>
                Confirm create Sandbox Account?
              </p>
              <table><tbody>
                <tr>
                  <td>
                    <button className="std-button" onClick={this.create_sandbox}>
                      {this.state.loading ? "Creating..." : "Create Account"}
                    </button>
                  </td>
                  <td>
                    <button className="std-button" onClick={() => this.setState({show_create_api: false})}>
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody></table>
            </div>
          </Overlay> : null}

          {this.state.show_delete ? 
            <Overlay on_cancel={() => this.setState({show_delete: false})} title="Delete API key">
              <div>
                <div>
                  Are you sure you want to delete this key? To do so, type the key name {this.state.apikeys[this.state.selected_index].name} in the box below.
                </div>
                <br />
                <MyInput onChange={(e) => this.setState({delete_typed: e.target.value})} value={this.state.delete_typed} status={this.state.delete_typed_status} onBlur={() => this.setState({delete_typed_status: this.state.delete_typed === this.state.apikeys[this.state.selected_index].name ? 2 : 3})} style={{fontFamily: 'Roboto Mono'}} label="API Key Name" />
                <br />
                {this.state.delete_typed_status === 2 ? 
                  <button className="std-button" style={{backgroundColor: 'red'}} onClick={() => this.on_delete_apikey(this.state.apikeys[this.state.selected_index].client_code)}>
                    Confirm Delete
                  </button> : null}
                <button onClick={() => this.setState({show_delete: false})} className="std-button">
                  Cancel
                </button>
              </div>
            </Overlay> : null}
        
          {this.state.messages.map(
            (message, index) =>
            <div className={"flash flash-"+message.type} key={index}>
              <table><tbody>
                <tr>
                  <td style={{fontSize: '18px'}}>
                    <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                  </td>
                  <td>
                    {message.content}
                  </td>
                </tr>
              </tbody></table>
            </div>
          )}

        <h2>
          Sandbox API
        </h2>
        {this.state.api_created ? 
          <div>

            {this.state.apikeys.length > 0 ? 
            this.state.apikeys.map(
              (apikey, index) => 
                <div key={index} style={{border: 'solid', borderRadius: '6px', borderWidth: '1px', borderColor: '#c9c9c9', margin: '4px', padding: '20px', cursor: 'pointer'}}>
                  <div style={{fontSize: '20px', fontWeight: 'bold'}}>
                    {apikey.name}
                  </div>
                  <table className="apikey-table"><tbody>
                    <tr>
                      <td>
                        Client code
                      </td>
                      <td>
                        {apikey.client_code}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Name
                      </td>
                      <td>
                        {apikey.name}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Active?
                      </td>
                      <td>
                        {apikey.active ? "Active" : "Not active"}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Key starts with
                      </td>
                      <td>
                        {apikey.starts_with}...
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Created at
                      </td>
                      <td>
                        {(new Date(apikey.created_at)).toLocaleString()}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Expires at
                      </td>
                      <td>
                        {(new Date(apikey.expires_at)).toLocaleString()}
                      </td>
                    </tr>
                  </tbody></table>
                  {false ? <button onClick={() => this.setState({selected_index: index, show_delete: true, delete_typed: ""})} className="std-button" style={{backgroundColor: 'red'}}>
                    Delete
                  </button> : null}
                </div>
            ) : 
            <div>
              <div style={{fontSize: '14px', padding: '10px', color: 'grey'}}>
                There are currently no API keys.
              </div>
            </div>}
            <button className="std-button" onClick={() => this.setState({show_create_key:true})}>
              Create Key
            </button>
            
          </div> : 
          <div>
            <p style={{color: 'grey', fontSize: '14px'}}>
              Your Sandbox account has not been created.
            </p>
            <button className="std-button" onClick={() => this.setState({show_create_api: true})}>
              Create
            </button>
          </div>}
      </div>
    );
  }
}

export default ApikeySandboxManager
