import React from "react"
import PropTypes from "prop-types"
class MbCalendarDate extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      month: "",
      day: ""
    };
  }

  componentDidMount(){
    if ([null,undefined].includes(this.props.value)){
      return this.setState({
        month: '-',
        day: '-'
      });
    }
    let d = new Date(this.props.value);
    this.setState({
      month: ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'][d.getMonth()],
      day: (d.getDate() < 10 ? '0' : '') + d.getDate()
    });
  }
  /*
  Certificate is saved at: /etc/letsencrypt/live/docs.paysolve.com.au/fullchain.pem
Key is saved at:         /etc/letsencrypt/live/docs.paysolve.com.au/privkey.pem
  */

  render () {
    return (
      <div style={{width: '3.2em', height: '3.2em', margin: 'auto', borderRadius: '0.4em', border: 'solid', borderWidth: '1px', borderColor: '#c9c9c9', overflow: 'hidden'}}>
        <div style={{backgroundColor: 'red', textAlign: 'center', padding: '0.15em', fontSize: '0.7em', fontWeight: 'bold', color: '#fff'}}>
          {this.state.month}
        </div>
        <div style={{padding: '0.2em', fontSize: '1.2em', fontWeight: 'bold', textAlign: 'center'}}>
          {this.state.day}
        </div>
      </div>
    );
  }
}

export default MbCalendarDate
