import React from "react"
import PropTypes from "prop-types"
import Checkbox from "./Checkbox.js"
import MyInput from "./MyInput.js"
import Overlay from "./Overlay.js"
class RedressFileIndex extends React.Component {
  constructor(props){
    super(props);
    this.RESULTS_PER_PAGE = 5;
    this.state = {
      messages:[],
      loading: false,
      accounts: this.props.accounts,
      account_index: 0,

      include_code: false,
      code: "",
      include_status: false,
      status: [],
      include_created_at: false,
      created_at_max: new Date(Date.now() - Date.now() % 86400000).toISOString(),
      created_at_min: new Date(Date.now() - Date.now() % 86400000 - 7 * 86400000).toISOString(),

      results: [],
      limit: this.RESULTS_PER_PAGE,
      total_count: null,
      results_per_page: this.RESULTS_PER_PAGE, // should be the same as limit
      max_num_pages: 5,
      num_pages: null,
      showing_page: 0,

      show_new_redressfile: false,
      typed_title: "",
      typed_title_status: 0,
      typed_title_message: "",

      show_redressfile: false,
      redressfile: null
    };

    this.on_change_created_at_min = this.on_change_created_at_min.bind(this);
    this.on_change_created_at_max = this.on_change_created_at_max.bind(this);
    this.on_type_title = this.on_type_title.bind(this);
    this.on_blur_title = this.on_blur_title.bind(this);
    this.create_redressfile = this.create_redressfile.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.search = this.search.bind(this);
    this.download_string_as_file = this.download_string_as_file.bind(this);
    this.download_aba_file = this.download_aba_file.bind(this);
    
    this.REDRESSFILE_STATUSES = [1,2,3];
    this.REDRESSFILE_STATUS_TO_NAME = {'1':'Initiated','2':'Finalised','3':'Completed'};

    this.MIN_TITLE_LENGTH = 5
    this.MAX_TITLE_LENGTH = 32

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  on_change_created_at_min(e){
    this.setState({
      created_at_min: (new Date(e.target.value)).toISOString().slice(0,16)
    });
  }

  on_change_created_at_max(e){
    this.setState({
      created_at_max: (new Date(e.target.value)).toISOString().slice(0,16)
    });
  }

  on_type_title(e){
    const typed = e.target.value;
    if (/^[a-zA-Z0-9 -]*$/.test(typed)){
      this.setState({
        typed_title: typed,
        typed_title_status: 1
      });
    }
  }

  on_blur_title(){
    if (this.state.typed_title.length < this.MIN_TITLE_LENGTH || this.state.typed_title.length > this.MAX_TITLE_LENGTH){
      this.setState({
        typed_title_status: 3,
        typed_title_message: "Title must be between "+String(this.MIN_TITLE_LENGTH)+" and "+String(this.MAX_TITLE_LENGTH)+" characters."
      });
    } else {
      this.setState({
        typed_title_status: 2,
        typed_title_message: ""
      });
    }
  }

  search(page,limit,req_count){
    //this.setState({selection: []});
    console.log("page = "+String(page));
    let body = {redressfile:{company_id:0},offset:page*this.state.results_per_page,limit:limit,req_count:req_count}; // need to select account_id ***
    /*
      For each search term in state, only include it in the body if state indicates its inclusion. Separate by whether it has min/max or exact value.
    */
    // min/max keys:
    ['created_at'].forEach((k,i) => this.state['include_'+k] ? body['redressfile'][k] = {min:this.state[k+'_min'],max:this.state[k+'_max']} : null);
    // exact keys:
    ['code','status','title'].forEach((k,i) => this.state['include_'+k] ? body['redressfile'][k] = this.state[k] : null);
    /*if (Object.keys(body['invoice']).length === 0){
      body['invoice']['amount'] = {min:100,max:1000000000};
    }*/
    //body['tags'] = this.state.include_tags ? this.state.tags : [];
    console.log('body');
    console.log(body);
    fetch("/api/redressfiles/search", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.setState({
        show_results: true,
        results: json.redressfiles,
        total_count: json.count === null ? this.state.total_count : json.count,
        num_pages: Math.ceil((json.count === null ? this.state.total_count : json.count) / this.state.results_per_page),
        showing_page: page,
        search_clicked: true
      });
    });
  }

  create_redressfile(){
    this.setState({
      loading: true
    });
    fetch("/api/redressfiles/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({redressfile:{title:this.state.typed_title, account_id:this.state.accounts[this.state.account_index].id}})
    }).then( (response) => {
      if (response.ok || true){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.add_message("Successfully created ABA file object with code "+json.redressfile.code,'success');
      } else {
        this.add_message("Failed to create ABA file object.",'danger');
      }
      this.setState({
        loading: false,
        show_new_redressfile: false
      });
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  download_aba_file(){
    fetch("/api/redressfile/"+this.state.redressfile.code+"/abafile", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      if ([null,undefined].includes(json.aba_file)){
        this.add_message("Failed to generate file.",'danger');
      } else {
        this.download_string_as_file(this.state.redressfile.code+".aba",json.aba_file);
        this.add_message("Successfully generated and downloaded file.",'success');
      }
    });
  }

  download_string_as_file(filename, content) {
    // Create a Blob from the string
    const blob = new Blob([content], { type: 'text/plain' });

    // Create a link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;

    // Append to the document
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href); // Release memory
  }

  render () {
    console.log(this.state);
    return (
      <div className="form-section" style={{width: '720px'}}>
        {this.state.show_new_redressfile ? 
          <Overlay on_cancel={() => this.setState({show_new_redressfile: false})} title="New ABA File Object" width="500px">
            <h4>
              Select your origin Account
            </h4>
            <select className="general-select" onChange={(e) => this.setState({account_index: Number(e.target.value)})}>
              {this.state.accounts.map(
                (account, index) =>
                <option key={index} value={index}>
                  {account.bsb} {account.account_number} - {account.account_name}
                </option>
              )}
            </select>
            <h4>
              Select a File Object title
            </h4>
            <div className="myinput-wrapper-centre">
              <MyInput onChange={this.on_type_title} value={this.state.typed_title} onBlur={this.on_blur_title} status={this.state.typed_title_status} label="File object title" note={![null,undefined].includes(this.state.typed_title_message) && this.state.typed_title_message.length > 0 ? this.state.typed_title_message : null} />
              {this.state.typed_title_status === 2 ? 
                <button className="std-button" onClick={this.create_redressfile}>
                  {this.state.loading ? "Creating..." : "Create"}
                </button> : null}
            </div>
          </Overlay> : null}
        
        {this.state.show_redressfile ? 
          <Overlay on_cancel={() => this.setState({show_redressfile: false})} title={"ABA File Object "+this.state.redressfile.code} width="500px">
            <div>
              <button className="std-button" onClick={this.download_aba_file}>
                Download ABA File
              </button>
            </div>
          </Overlay> : null}
        
          {this.state.messages.map(
            (message, index) =>
            <div className={"flash flash-"+message.type} key={index}>
              <table><tbody>
                <tr>
                  <td style={{fontSize: '18px'}}>
                    <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                  </td>
                  <td>
                    {message.content}
                  </td>
                </tr>
              </tbody></table>
            </div>
          )}

        <h2>
          ABA File Console
        </h2>

        <h3 style={{color: 'grey'}}>
          Selection Criteria
        </h3>
        <table id="object-search-table"><tbody>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_status: v})} init_value={this.state.include_status} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Status
              </div>
              {this.state.include_status ? 
                <div>
                  <table><tbody>
                    {this.REDRESSFILE_STATUSES.map(
                      (s, index) => 
                        <tr key={index}>
                          <td style={{width: '24px'}}>
                            {/*<button onClick={() => this.setState({status: this.state.status.includes(s) ? this.state.status.filter((e) => e != s) : this.state.status.concat([s])})}>
                              {this.state.status.includes(s) ? "Included" : "Excluded"}
                            </button>*/}
                            <Checkbox on_toggle={(v) => this.setState({status: v ? this.state.status.concat([s]) : this.state.status.filter((e) => e != s)})} />
                          </td>
                          <td>
                            {this.REDRESSFILE_STATUS_TO_NAME[String(s)]}
                          </td>
                        </tr>
                    )}
                  </tbody></table>
                </div> : null}
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_created_at: v})} init_value={this.state.include_created_at} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Date created at
              </div>
              {this.state.include_created_at ? 
                <div style={{paddingTop: '15px'}}>
                  <MyInput type="datetime-local" onChange={this.on_change_created_at_min} value={this.state.created_at_min} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="Earliest date" note={null} /><br />
                  <MyInput type="datetime-local" onChange={this.on_change_created_at_max} value={this.state.created_at_max} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="Latest date" note={null} /><br />
                </div> : null}
            </td>
          </tr>
        </tbody></table>

        {this.state.search_clicked ? 
          <div>
            <h3 style={{color: 'grey'}}>
              Results
            </h3>
            <table className="redress-result-table"><tbody>
              {this.state.results.length > 0 ? <tr>
                <th>
                  File code
                </th>
                <th>
                  File status
                </th>
                <th>
                  Filename
                </th>
                <th>
                  Redresses
                </th>
                <th>
                  Redress Value
                </th>
              </tr> : null}
              {this.state.results.length > 0 ? this.state.results.map(
                (result, index) => 
                <tr key={index}>
                  <td>
                    <div style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => this.setState({show_redressfile: true, redressfile: result})}>{result.code}</div>
                  </td>
                  <td>
                    {this.REDRESSFILE_STATUS_TO_NAME[String(result.status)]}
                  </td>
                  <td>
                    {result.filename}
                  </td>
                  <td style={{textAlign: 'right'}}>
                    {result.num_redresses}
                  </td>
                  <td style={{textAlign: 'right'}}>
                    ${this.amount_to_formatted_string(result.total_amount)}
                  </td>
                </tr>
              ) : null}
            </tbody></table>
            {this.state.results.length === 0 ? <div style={{color: 'grey', fontSize: '14px'}}>
              No results.
            </div> : null}
          </div> : null}

        <button className="std-button" onClick={() => this.search(0,this.state.limit,true)}>
          Search
        </button>

        {this.state.total_count != null ? 
          <table className="search-pagination-table"><tbody>
            <tr>
              <td>
                {this.state.showing_page > this.state.max_num_pages / 2 ? 
                  <div onClick={() => this.search(this.state.showing_page - ((this.state.max_num_pages-1)/2) - 1, this.state.limit, false)}>
                    ...
                  </div> : null}
              </td>
              {this.state.num_pages <= this.state.max_num_pages || this.state.showing_page < this.state.max_num_pages / 2 ? 
                Array(Math.min(this.state.num_pages,this.state.max_num_pages)).fill(0).map((_,index) => 
                  <td key={index}>
                    <div onClick={() => this.search(index,this.state.limit,false)} style={this.state.showing_page === index ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                      {index+1}
                    </div>
                  </td>) : 
                Array(this.state.max_num_pages).fill(0).map((_,i) => this.state.showing_page - ((this.state.max_num_pages - 1)/2) + i).map((v,index) => 
                  this.state.num_pages - v < ((this.state.max_num_pages - 1) / 2) - 1 ? null : 
                    <td key={index}>
                      <div onClick={() => this.search(v,this.state.limit,false)} style={this.state.showing_page === v ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                        {v + 1}
                      </div>
                    </td>)}
              <td>
                {this.state.num_pages - this.state.showing_page > this.state.max_num_pages / 2 + 1 ? 
                  <div onClick={() => this.search(Math.max(this.state.showing_page + ((this.state.max_num_pages - 1) / 2) + 1,this.state.max_num_pages),this.state.limit,false)}>
                    ...
                  </div> : null}
              </td>
            </tr>
          </tbody></table> : null}
        

        <br /><br />
        <h3 style={{color: 'grey'}}>
          New ABA File Object
        </h3>
        <button className="std-button" onClick={() => this.setState({show_new_redressfile: true})}>
          Create new
        </button>
      </div>
    );
  }
}

export default RedressFileIndex
