import React from "react"
import PropTypes from "prop-types"
/*import Payment from "./Payment.js"
import LoginPayor from "./LoginPayor.js"
import PayorCreate from "./PayorCreate.js"*/
import MbOverlay from "./MbOverlay.js"
import MbPayment from "./MbPayment.js"
import MbLoginPayor from "./MbLoginPayor.js"
import MbPayorCreate from "./MbPayorCreate.js"
import MbInput from "./MbInput.js"
class MbShowInvoice extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      invoice: null,
      loaded: false,
      messages: [],
      show_payor_status: 0,
      payor_code: null,
      invoice_payorlink_id: null,
      series_payorlink_id: null,
      show_remove_payorlink: false,
      show_payorlink_option: false,
      show_email: false,
      typed_email: "",
      typed_email_status: 0,
      typed_email_message: "",
      sending_pdf: false
		};

    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.click_to_copy = this.click_to_copy.bind(this);
    this.toggle_url_passkey_inclusion = this.toggle_url_passkey_inclusion.bind(this);
    this.display_abn = this.display_abn.bind(this);
    this.save_invoice_payor = this.save_invoice_payor.bind(this);
    this.save_series_payor = this.save_series_payor.bind(this);
    this.remove_payorlink = this.remove_payorlink.bind(this);
    this.email_pdf = this.email_pdf.bind(this);
    this.on_type_email = this.on_type_email.bind(this);
    this.on_blur_email = this.on_blur_email.bind(this);

    this.INVOICE_STATUS_TO_WORD = {'0':'INITIATE','1':'UNPAID','2':'PART PAID','3':'SUSPEND','4':'PAID','5':'PAID','6':'PAID'};
    this.INVOICE_STATUS_TO_TITLE = {'0':'Invoice Initiated','1':'Invoice Unpaid','2':'Invoice Partly Paid','3':'Invoice Suspended by User','4':'Invoice Paid','5':'Invoice Paid in Excess','6':'Invoice Paid by Other Source'};
    this.INVOICE_STATUS_TO_DESCRIPTION = {'0':'Invoice has been initiated but has not been opened yet to payment.',
      '1':'Invoice is unpaid and is awaiting payment.',
      '2':'Invoice has been partly paid, and is awaiting payment of the balance.',
      '3':'Invoice has been suspended by you and is not visible except to you.',
      '4':'Invoice has been paid.',
      '5':'Invoice has been paid in excess of what was owed.',
      '6':'Invoice has been paid by another source, not through PaySolve.'};
    this.INVOICE_STATUS_TO_COLOUR = {'0':'#040dba','1':'#040dba','2':'#e6a330','3':'#e6a330','4':'#32bfb8','5':'#32bfb8','6':'#32bfb8'};
    this.INVOICE_STATUS_TO_ADMIN_ACTION = {'1':'OPEN','2':'OPEN','3':'SUSPEND','6':'SET_AS_COMPLETE'};

  }

  componentDidMount(){
    fetch("/api/invoice/"+this.props.code+(this.props.passkey === null || this.props.passkey === undefined ? "" : "?pk="+this.props.passkey), {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Error in obtaining Invoice data.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      if ([null,undefined].includes(json.invoice)){
        this.add_message("Encountered error obtaining information.",'danger');
        this.setState({
          loaded: true
        });
      } else {
        this.setState({
          invoice: json.invoice,
          loaded: true,
          payor_code: json.payor_code,
          invoice_payorlink_id: json.invoice_payorlink_id,
          series_payorlink_id: json.series_payorlink_id
        });
      }
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  click_to_copy(e, text){
    navigator.clipboard.writeText(text);
    console.log(e);
    e.target.innerHTML = "COPIED!"
    setTimeout(() => {
      e.target.innerHTML = "COPY";
    }, 1000);
  }

  toggle_url_passkey_inclusion(){
    let invoice = this.state.invoice;
    if (invoice.short_url.includes('?pk')){
      invoice.short_url = invoice.short_url.split('?pk')[0];
    } else {
      invoice.short_url += ('?pk='+this.props.passkey);
    }
    this.setState({
      invoice: invoice
    });
  }

  display_abn(x){
    return x.split('').map((a,i) => [1,4,7].includes(i) ? a+' ' : a).join('');
  }

  save_invoice_payor(){
    fetch("/payorlink?objname=INV&invoice_id="+this.state.invoice.id, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }/*,
      body: JSON.stringify({})*/
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.add_message("Saved Invoice to your Payor account.",'success');
      this.setState({
        invoice_payorlink_id: json.payorlink_id,
        show_payorlink_option: false
      });
    });
  }

  save_series_payor(){
    fetch("/payorlink?objname=SER&series_id="+this.state.invoice.series_id, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }/*,
      body: JSON.stringify({})*/
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.add_message("Saved Series to your Payor account.",'success');
      this.setState({
        series_payorlink_id: json.payorlink_id,
        show_payorlink_option: false
      });
    });
  }

  remove_payorlink(object_name, payorlink_id){
    fetch("/payorlink?objname="+object_name+"&payorlink_id="+payorlink_id, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }/*,
      body: JSON.stringify({})*/
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Erro in deleting Payor link.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      this.add_message("Successfully removed link to your Payor account.",'success');
      if (object_name === "INV"){
        this.setState({
          invoice_payorlink_id: null,
          show_remove_payorlink: false
        });
      } else if (object_name === "SER"){
        this.setState({
          series_payorlink_id: null,
          invoice_payorlink_id: null,
          show_remove_payorlink: false
        });
      }
    });
  }

  email_pdf(){
    if (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.typed_email)){
      this.setState({
        sending_pdf: true
      });
      fetch("/api/invoice/"+this.state.invoice.code+"/email", {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-csrf-token': this.props.auth_token
        },
        body: JSON.stringify({email: this.state.typed_email})
      }).then( (response) => {
        if (response.ok){
          return response.json();
        }
        this.add_message("Error in server.",'danger');
        throw new Error('Request fail');
      }).then(json => {
        if (json.success){
          this.setState({
            show_email: false,
            sending_pdf: false
          });
          this.add_message("Email request sent.",'success');
        } else {
          this.add_message("Failed to send email.",'danger');
          this.setState({
            sending_pdf: false
          });
        }
      });
    } else {
      this.setState({
        typed_email_status: 3,
        typed_email_message: "Invalid email format"
      });
    }
  }

  on_type_email(e){
    if (true || /^[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]*$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]*$/.test(e.target.value)){
      this.setState({
        typed_email: e.target.value
      });
    }
  }

  on_blur_email(){
    if (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.typed_email)){
      this.setState({
        typed_email_status: 2
      });
    } else {
      this.setState({
        typed_email_status: 3,
        typed_email_message: "Invalid email format"
      });
    }
  }

  render () {
    console.log(this.state);
    return ( this.state.invoice === null ? 
      <div className="mb-form-section">

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td>

                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}
        {this.state.loaded ? null : 
          <div>
            Loading...
          </div>}
      </div> : 
      <div className="mb-form-section">

        {this.state.show_payor_status > 0 ? 
          <MbOverlay on_cancel={() => this.setState({show_payor_status: 0})} title="Payor Account">
            {this.state.show_payor_status === 1 ? 
              <div>
                <MbLoginPayor auth_token={this.props.auth_token} />
                <div style={{textAlign: 'center'}}>
                  <p style={{color: 'grey', fontSize: '15px', paddingLeft: '20px', paddingRight: '20px'}}>
                    Don't have a Payor account? It takes 30 seconds to sign up, and you can save and manage all your bills in one place!
                  </p>
                  <button onClick={() => this.setState({show_payor_status: 2})} className="mb-button">
                    Create Account
                  </button>
                </div>
              </div> : null}
            {[2,3].includes(this.state.show_payor_status) ? 
              <div>
                <MbPayorCreate auth_token={this.props.auth_token} on_complete={() => this.setState({show_payor_status: 3})} />
              </div> : null}
            {this.state.show_payor_status === 3 ?
              <div>
                <MbLoginPayor auth_token={this.props.auth_token} />
              </div> : null}
          </MbOverlay> : null}
        
        {this.state.show_payorlink_option ? 
          <MbOverlay on_cancel={() => this.setState({show_payorlink_option: false})} width="540px" title="Link to Payor Account">
            <div>
              <p style={{color: 'grey'}}>
                Do you want to link just this Invoice, or the whole Series, to your Payor account?
              </p>
              <button onClick={() => this.save_invoice_payor()} className="mb-button">
                Save Invoice
              </button>
              <button onClick={() => this.save_series_payor()} className="mb-button">
                Save Series
              </button>
              <button onClick={() => this.setState({show_payorlink_option: false})} className="mb-button">
                Cancel
              </button>
            </div>
          </MbOverlay> : null}

        {this.state.show_remove_payorlink ? 
          <MbOverlay on_cancel={() => this.setState({show_remove_payorlink: false})} title="Remove Link">
            <div>
              <p style={{color: 'grey'}}>
                Are you sure you want to remove the link between this Invoice and your Payor account?
              </p>
              <button onClick={() => this.state.series_payorlink_id === null ? this.remove_payorlink("INV", this.state.invoice_payorlink_id) : this.remove_payorlink("SER", this.state.series_payorlink_id)} className="mb-button">
                Remove
              </button>
              <button onClick={() => this.setState({show_remove_payorlink: false})} className="mb-button">
                Cancel
              </button>
            </div>
          </MbOverlay> : null}
        
        {this.state.show_email ? 
          <MbOverlay on_cancel={() => this.setState({show_email: false})} title="Email PDF">
            <MbInput type="email" onChange={this.on_type_email} value={this.state.typed_email} status={this.state.typed_email_status} label="Email address" note={this.state.typed_email_status === 3 ? this.state.typed_email_message : null} />
            <button className="mb-button" onClick={this.email_pdf}>
              {this.state.sending_pdf ? "Sending..." : "Send"}
            </button>
          </MbOverlay> : null}
        
        <table style={{width: '100%'}}><tbody>
          <tr>
            <td style={{width: '20%'}}>
              <div className="mb-status-box" style={{backgroundColor: this.INVOICE_STATUS_TO_COLOUR[String(this.state.invoice.status)]}}>
                {this.INVOICE_STATUS_TO_WORD[String(this.state.invoice.status)]}
              </div>
            </td>
            <td style={{width: '70%', textAlign: 'right'}}>
              <div style={{fontFamily: "Roboto Mono", color: 'grey'}}>
                {this.state.invoice.code}
              </div>
            </td>
          </tr>
        </tbody></table>

        <h2 style={{color: 'grey', textAlign: 'center'}}>
          ${this.amount_to_formatted_string(this.state.invoice.amount)}
        </h2>
        <h2 style={{textAlign: 'center'}}>
          {this.state.invoice.title}
        </h2>
        <table className="mb-invoice-table"><tbody>
          <tr>
            <td>
              Organisation
            </td>
            <td>
              {this.state.invoice.company_name}
            </td>
          </tr>
          {this.state.invoice.company_abn === null ? null : 
          <tr>
            <td>
              ABN
            </td>
            <td>
              {this.display_abn(this.state.invoice.company_abn)}
            </td>
          </tr>}
          <tr>
              <td>
                Website
              </td>
              <td>
                <a href={this.state.invoice.company_website} style={{color: "grey", textDecoration: "underline"}} target="_blank">{this.state.invoice.company_website}</a>
              </td>
            </tr>
          <tr>
            <td>
              Amount
            </td>
            <td>
              <b>${this.amount_to_formatted_string(this.state.invoice.amount)}</b>
            </td>
          </tr>
          {this.state.invoice.has_due_date ? 
          <tr>
            <td>
              Due by
            </td>
            <td>
              {this.state.invoice.due_at.slice(5,16)}
            </td>
          </tr> : null}
          <tr>
            <td>
              Short URL
            </td>
            <td>
              <span style={{fontFamily: 'Roboto Mono'}}>invc.me/{this.state.invoice.short_url}</span>
              <br /><span onClick={(e) => this.click_to_copy(e, 'invc.me/'+this.state.invoice.short_url)} className="copy-span">COPY</span>
              {this.props.passkey === null ? null : <span onClick={this.toggle_url_passkey_inclusion} className="copy-span">W/{this.state.invoice.short_url.includes('?pk') ? 'O' : null} PASSKEY</span>}
            </td>
          </tr>
          {this.state.invoice.series_id === null ? null : 
            <tr>
              <td>
                Series
              </td>
              <td style={{color: "#040dba", fontWeight: 'bold', cursor: 'pointer'}} onClick={() => {
                window.open(this.state.invoice.series_url,'_blank');
              }}>
                {this.state.invoice.series_title} <i className="fa-solid fa-arrow-up-right-from-square"></i>
              </td>
            </tr>}
          <tr>
            <td>
              Description
            </td>
            <td>
              {this.state.invoice.description}
            </td>
          </tr>
          <tr>
            <td>
              Date accrued
            </td>
            <td>
              {this.state.invoice.date_accrued_at.slice(5,16)}
            </td>
          </tr>
          <tr>
            <td>
              Date due
            </td>
            <td style={{color: this.state.invoice.is_late ? "red" : "normal"}}>
              {this.state.invoice.due_at === null ? null : this.state.invoice.due_at.slice(5,16)}
            </td>
          </tr>
        </tbody></table>

        {this.state.invoice_payorlink_id === null && this.state.series_payorlink_id === null ? 
          <button onClick={() => this.state.payor_code === null ? this.setState({show_payor_status: 1}) : (this.state.invoice.series_id === null ? this.save_invoice_payor() : this.setState({show_payorlink_option: true}))} className="mb-button">
            <i className="fa-solid fa-cloud-arrow-down"></i> Save Invoice
          </button> : 
          <button className="mb-button" onClick={() => this.setState({show_remove_payorlink: true})}>
            <i className="fa-solid fa-check"></i> Saved
          </button>}
        
        <div>
          <button className="mb-button" onClick={() => this.setState({show_email: true})}>
            <i className="fa-regular fa-file-pdf"></i> Email PDF
          </button>
        </div>
        
        <div className="mb-invoice-tally">
          <table><tbody>
            <tr>
              <td>
                Charged
              </td>
              <td>
                {this.amount_to_formatted_string(this.state.invoice.amount)}
              </td>
            </tr>
            <tr>
              <td>
                Paid
              </td>
              <td>
                -{this.amount_to_formatted_string(this.state.invoice.amount_paid)}
              </td>
            </tr>
            <tr>
              <td>
                Paid ext.
              </td>
              <td>
                -{this.amount_to_formatted_string(this.state.invoice.amount_paid_by_other_source)}
              </td>
            </tr>
            <tr>
              <td>
                Owing
              </td>
              <td style={{borderTop: 'solid', borderTopWidth: '1px', borderColor: '#c9c9c9'}}>
                {this.amount_to_formatted_string(this.state.invoice.amount - this.state.invoice.amount_paid)}
              </td>
            </tr>
          </tbody></table>
        </div>


        {this.state.invoice.amount === this.state.invoice.amount_paid ? 
          <div style={{marginTop: '3em', textAlign: 'center'}}>
            <h3>
              This Invoice has been paid.
            </h3>
          </div> : 
          <div style={{marginTop: '3em'}}>
            <h3>
              Pay this Invoice
            </h3>
            <div className="flash flash-info">
              <table><tbody>
                <tr>
                  <td>
                    <i className="fa-solid fa-circle-info"></i>
                  </td>
                  <td>
                    For a first-time transaction to this account, your payment may take up to 24 hours to process.
                  </td>
                </tr>
              </tbody></table>
            </div>
            <MbPayment code={this.props.code} passkey={this.props.passkey} auth_token={this.props.auth_token} />
          </div>}
      </div>
    );
  }
}

export default MbShowInvoice
